import PeopleImg from "@assets/about-us/group-people.svg";
import { breakpoints, Button, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const Commitment = ({ hideModal }) => {
  return (
    <CommitmentWrapper>
      <Top>
        <Header>Commitment to openness, transparency, and growth together</Header>
        <Paragraph>
          We take your partnership and trust seriously. We will be open with you every step of the way, and together
          evolve a protocol and infrastructure that we believe can be enduring and meaningful for our communities and
          for many other communities around the world!
        </Paragraph>

        <StyledButton borderRadius="40px" bgColor="primary700" kind="solid" heightSize="56px" onClick={hideModal}>
          Return to ThriveCoin
        </StyledButton>
      </Top>
      <StyledImage src={PeopleImg} />
      <Bottom></Bottom>
    </CommitmentWrapper>
  );
};

const CommitmentWrapper = styled.div`
  background: ${({ theme }) => theme.colors["01Primary0"]};
  margin-top: 34px;
  position: relative;
`;

const Top = styled.div`
  height: 650px;
  background: linear-gradient(0deg, rgba(126, 192, 206, 0) 0%, #7ec0ce 100%);
  border-radius: 25px 25px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  @media ${breakpoints.tablet} {
    padding: 76px 78px 0 65px;
    height: 650px;
  }
  @media ${breakpoints.mobile} {
    max-height: 726px;
    padding-top: 44px;
    height: auto;
  }
`;

const Bottom = styled.div`
  margin-top: 37px;
  height: 152px;
  background: ${({ theme }) => theme.colors["03Primary400"]};
  border-radius: 0px 0px 25px 25px;
`;

const Header = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 34px;
  line-height: 42px;
  color: ${({ theme }) => theme.colors["01Primary0"]};
  margin-bottom: 40px;
  text-align: center;
  @media ${breakpoints.mobile} {
    font-size: 28px;
    line-height: 36px;
    padding: 0 22px;
    margin-bottom: 33px;
  }
`;

const Paragraph = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: ${({ theme }) => theme.colors["02Primary700"]};
  max-width: 998px;
  margin-bottom: 46px;
  @media ${breakpoints.mobile} {
    padding: 0 15px;
    margin-bottom: 43px;
  }
`;

const StyledButton = styled(Button)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 32px;
  width: 212px;
  @media ${breakpoints.mobile} {
    margin-bottom: 72px;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  @media ${breakpoints.tablet} {
    max-width: 543px;
  }
  @media ${breakpoints.mobile} {
    max-width: 319px;
  }
`;

export default Commitment;
