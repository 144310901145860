import { Box, Button, IconSvg, Text } from "@thrivecoin/ui-components";
import { useContext } from "react";
import styled from "styled-components";
import FileInput from "../../common/FileInput";
import { DEFAULT_RAFFLE_PRIZE } from "../constants";
import { SeasonEditorContext } from "../SeasonEditorContext";
import { StyledInput, StyledTextArea } from "./StyledInputs";

const Prize = ({
  index,
  name,
  image,
  image_type,
  image_url,
  description,
  external_url,
  removePrize,
  updatePrize,
  setMedia,
  removeMedia,
  errors,
}) => {
  const media = {};
  const mediaName = `image_${index}_url`;
  media[mediaName] = image || image_url;
  media[mediaName + "_type"] = image_type;

  const onRemovePrize = () => removePrize(index);
  const onUpdatePrize = (e) => updatePrize(index, e.target.id, e.target.value);
  const updateMedia = (val) => {
    const updatedMedia = { ...val };
    if (val[`_destroy_image_${index}`]) {
      removeMedia(index);
    } else {
      setMedia(index, updatedMedia);
    }
  };

  return (
    <PrizeWrapper>
      <Box flex alignItems="center" justifyContent="space-between">
        <Text typography="heading/small">Prize number {index + 1}</Text>
        <Box pointer onClick={onRemovePrize}>
          <IconSvg icon="x" width="20px" color="01Primary700" />
        </Box>
      </Box>
      <Separator />
      <FileInput
        label="Prize Image (Optional)"
        subLabel="Our recommended size is 321x348px"
        name={mediaName}
        media={media}
        setMedia={updateMedia}
      />
      <Separator />
      <StyledInput
        id="name"
        label="Raffle Prize Name (Required)"
        value={name}
        onChange={onUpdatePrize}
        invalid={!!errors?.raffle?.name}
        alwaysShowValidation={!!errors?.raffle?.name}
        validationText={errors?.raffle?.name}
      />
      <Separator />
      <StyledTextArea
        id="description"
        label="Prize Description (Optional)"
        value={description}
        onChange={onUpdatePrize}
      />
      <StyledInput
        id="external_url"
        label="Raffle Prize Link (Optional)"
        value={external_url}
        onChange={onUpdatePrize}
      />
    </PrizeWrapper>
  );
};

const SeasonPrizes = () => {
  const { errorValues, season, updateSeason } = useContext(SeasonEditorContext);

  const addPrize = () => {
    updateSeason("raffle_prizes", [...season.raffle_prizes, DEFAULT_RAFFLE_PRIZE]);
  };

  const removePrize = (index) => {
    updateSeason(
      "raffle_prizes",
      season.raffle_prizes.filter((_, i) => i !== index)
    );
  };

  const updatePrize = (index, key, value) => {
    const prizes = [...season.raffle_prizes];
    const prize = { ...prizes[index] };
    prize[key] = value;
    prizes[index] = prize;
    updateSeason("raffle_prizes", prizes);
  };

  const updateMedia = (index, val) => {
    const media = { ...val };
    const prizes = [...season.raffle_prizes];
    const prize = { ...prizes[index] };

    for (const [key] of Object.entries(val)) {
      const updatedKey = key.replace(`_${index}`, "");
      media[updatedKey] = media[key];
      delete media[key];
    }

    const updatedPrize = { ...prize, ...media };
    prizes[index] = updatedPrize;
    updateSeason("raffle_prizes", prizes);
  };

  const removeMedia = (index) => {
    const prizes = [...season.raffle_prizes];
    const prize = { ...prizes[index] };
    prize["_destroy_image"] = true;
    prize.image_url = "";
    delete prize.image;

    prizes[index] = prize;
    updateSeason("raffle_prizes", prizes);
  };

  return (
    <Wrapper>
      <Content>
        <Text spacing="mb-4" typography="heading/medium">
          Raffle Prizes
        </Text>
        <Text spacing="mb-6">Details of each prize that will be raffled off.</Text>
      </Content>
      {season?.raffle_prizes?.map((item, index) => (
        <Prize
          key={index}
          index={index}
          removePrize={removePrize}
          updatePrize={updatePrize}
          setMedia={updateMedia}
          removeMedia={removeMedia}
          errors={errorValues}
          {...item}
        />
      ))}
      <Content>
        <Button
          bold
          borderRadius="40px"
          width="100%"
          kind="outline"
          color="01Primary700"
          rightIcon="plus"
          onClick={addPrize}
          spacing="mb-6"
        >
          Add Raffle Prize(s)
        </Button>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 555px;
`;

const Content = styled(Box)`
  margin: 0 1rem;
`;

const PrizeWrapper = styled(Box)`
  padding: 24px 1rem 20px;
  border-radius: 12px;
  margin-bottom: 32px;
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
`;

const Separator = styled(Box)`
  height: 1px;
  margin: 1.5rem 0;
  background: ${({ theme }) => theme.colors["01Primary300"]};
`;

export default SeasonPrizes;
