import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RAFFLE_SORT_OPTIONS } from "../../SortOptions";
import { TabWrapper } from "../common";
import TableTab from "./../../CommunityPage/Challenges/Contributions/TableTab";
import useSort from "./../../hooks/useSort";
import TableEntries from "./TableEntries";

const SORT_OPTIONS = RAFFLE_SORT_OPTIONS.reverse();

const modalProps = {
  sortOptions: SORT_OPTIONS,
  backLabel: "Back to Raffle Entries Page",
  filterLabel: "Entries",
};

const MyRaffleEntriesTab = () => {
  const navigate = useNavigate();
  const { getRaffleEntries } = useStoreActions((actions) => actions.raffle);
  const { raffleEntries, raffleEntriesPagination } = useStoreState((state) => state.raffle);
  const sortProps = useSort(getRaffleEntries, "serial_number", "serializer", "profile");

  const onRaffleClick = (e) => navigate(`/raffles/${e.target.dataset.id}`);
  const onSeasonClick = (e) => navigate(`/seasons/${e.target.dataset.id}`);

  const rowProps = {
    onRaffleClick,
    onSeasonClick,
  };

  return (
    <Wrapper>
      <TableTab
        WrapperKlass={TabWrapper}
        title="My Raffle Entries"
        titleSearch={true}
        sortOptions={SORT_OPTIONS}
        TableComponent={TableEntries}
        data={raffleEntries}
        pagination={raffleEntriesPagination}
        sortProps={sortProps}
        modalProps={modalProps}
        rowProps={rowProps}
        isContributions={true}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & > div {
    border-radius: 12px;
    background: ${({ theme }) => theme.colors["surfacesThrive"]};
    & > div:first-child {
      width: 100%;

      & > div:first-child {
        table {
          margin-top: 0;
          th:first-child {
            & > div > div {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
`;

export default MyRaffleEntriesTab;
