import { Box, breakpoints, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import styled from "styled-components";
import useVirtualPageView from "../hooks/useVirtualPageView";

const AbandonChallengeModal = ({ hideModal }) => {
  const { currentID } = useStoreState((state) => state.challenges);
  const { community } = useStoreState((state) => state.community);
  const { isMobile } = useStoreState((state) => state.media);
  const { abandonChallenge, getCurrentChallengeRequest, getPreviousChallenges } = useStoreActions(
    (actions) => actions.challenges
  );

  const onClick = () => {
    abandonChallenge(currentID).then(() => {
      getCurrentChallengeRequest(community.id);
      getPreviousChallenges(community.id);
      hideModal();
    });
  };

  useVirtualPageView({ pathname: "/abandon" });

  return (
    <Modal
      open
      onClose={hideModal}
      leaf={true}
      maxWidth="684px"
      spacing={isMobile && "px-0 pt-7"}
      borderRadius={isMobile && "24px"}
    >
      <Wrapper>
        <Text fontFamily="Outfit" bold type="header" size="28px" lineHeight="36px">
          Are you sure you want to leave this <br /> Challenge?
        </Text>
        <Text color="02Primary700" spacing="mb-6 mt-3">
          You will not be allowed to re-take the Challenge, complete any remaining Challenge Steps, or receive the
          Challenge completion bonus.
        </Text>
      </Wrapper>
      <ButtonsWrapper flex>
        <StyledButton
          size="lg"
          heightSize="56px"
          borderRadius="56px"
          semiBold
          className="cancel-btn"
          onClick={hideModal}
          grow="1"
          kind="outline"
          borderColor="secondary200"
          variant="secondary200"
          color="02Primary700"
          modal={true}
        >
          Nevermind! I want to continue
        </StyledButton>
        <StyledButton
          size="lg"
          heightSize="56px"
          semiBold
          className="continue-btn"
          kind={isMobile ? "outline" : "solid"}
          bgColor={isMobile && "destructive500"}
          borderRadius="56px"
          onClick={onClick}
          borderColor="destructive500"
          borderWidth="2px"
          grow="1"
          modal={true}
        >
          Yes, abandon this challenge
        </StyledButton>
      </ButtonsWrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  text-align: center;
  margin-top: 37px;

  @media ${breakpoints.mobile} {
    margin: 0 1rem;
  }
`;

const ButtonsWrapper = styled(Box)`
  margin-bottom: 10px;
  margin-top: 8px;

  @media ${breakpoints.mobile} {
    margin: 0 1rem;
    flex-direction: column;
    gap: 1rem;
  }
`;

const StyledButton = styled(Button)`
  &.cancel-btn {
    margin-right: 1rem;

    @media ${breakpoints.mobile} {
      margin-right: 0;
    }
  }

  &.continue-btn {
    color: ${({ theme }) => theme.colors.error};

    @media ${breakpoints.mobile} {
      color: #fff;
    }
  }
`;

export default AbandonChallengeModal;
