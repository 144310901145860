import { MainContainer } from "@common/MainContainer";
import SharePageButton from "@common/SharePageButton";
import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CommunityContext } from "../../CommunityContext";
import useColors from "../useColors";

const TopPart = ({ season }) => {
  const navigate = useNavigate();
  const isPreview = season?.isPreview;
  const { isMobile } = useStoreState((state) => state.media);
  const { isCustomDomain, redirectionPath, setCommunityId } = useContext(CommunityContext);
  const { theme } = useColors(season);
  const showImage = !!season.image_url;

  const label = season?.label || `SEASON ${season.order_number}`;

  const onBack = () => {
    navigate(redirectionPath);
  };

  useEffect(() => {
    if (!isCustomDomain && !!season) {
      setCommunityId(season.community_slug);
    }
  }, [season]);

  return (
    <Wrapper bgColor={theme.topBackground} withBackButton={!!season.community_id} flex direction="column">
      <MainContainer>
        <Container>
          <Box color={theme.color} flex direction="column">
            {!isPreview && (
              <Box pointer flex alignItems="center" gap="8px" spacing="mb-4" onClick={onBack}>
                <IconSvg icon="left-long" size="14px" />
                <Text size="15px" lHeight="20px" bold>
                  Back to Community Page
                </Text>
              </Box>
            )}
            <SeasonNumber bgColor={theme.accentColor} spacing="mb-4 mr-a">
              <Text typography="heading/small" color="#F5F5F5">
                {label}
              </Text>
            </SeasonNumber>
            <Title typography="heading/x-large" color="02Primary0" spacing="mb-4">
              {season.title}
            </Title>
            <SeasonGoal size="18px" lHeight="30px" bold color={theme.color} fontFamily="Outfit">
              {season.goal}
            </SeasonGoal>
          </Box>
          <ImageWrapper>
            {!isPreview && !isMobile && <SharePageButton />}
            {showImage && <ImageStyled src={season.image_url} alt="animals" />}
          </ImageWrapper>
          {isMobile && !isPreview && <SharePageButton />}
        </Container>
      </MainContainer>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  ${"" /* background: url(${BACKGROUND_IMAGE}); */}
  background-size: cover;
  padding: ${({ withBackButton }) => (withBackButton ? "32px" : "72px")} 0 32px 0;

  @media ${breakpoints.tablet} {
    padding: ${({ withBackButton }) => (withBackButton ? "32px" : "40px")} 0 30px 0;
  }

  @media ${breakpoints.mobile} {
    padding: 24px 0;
  }
`;

const Container = styled(Box)`
  margin: 0 auto;
  display: flex;
  gap: 32px;
  @media (max-width: 1003px) {
    grid-template-columns: auto;
  }
  @media ${breakpoints.tablet} {
    padding-left: 16px;
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: unset;
  }

  ${SharePageButton}{
    position: absolute;
    right: 0;
    top 32px;
    transform: translateY(-50%);
  }
`;

const Title = styled(Text)`
  max-width: 587px;
`;

const ImageWrapper = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 80px;
  position: relative;
`;

const ImageStyled = styled.img`
  position: absolute;
  height: 244px;
  max-width: calc(100% - 600px);
  z-index: 1;
  bottom: -32px;
  max-width: 510px;
  max-height: 244px;
  object-fit: contain;

  @media ${breakpoints.tablet} {
    display: none;
    // bottom: -281px;
    // max-width: unset;
    // right: unset;
    // left: 630px;
    // height: calc(100% + 310px);
  }

  @media ${breakpoints.mobile} {
    display: none;
    // height: 400px;
    // max-width: unset;
    // left: -24px;
    // right: 0;
    // bottom: -460px;
  }
`;

const SeasonNumber = styled(Box)`
  border-radius: 5px;
  padding: 8px 10px;
`;

const SeasonGoal = styled(Text)`
  width: 494px;

  @media ${breakpoints.mobile} {
    width: unset;
  }
`;

export default TopPart;
