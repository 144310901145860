import { Box, Modal } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import AcceptStep from "./AcceptStep";
import RejectStep from "./RejectStep";
const ManagePendingContributionModal = ({
  hideModal,
  isAccepting: _isAccepting,
  id,
  contribution_claim_id,
  message,
  username,
  afterClose,
  verification_code,
  activity_name,
  conditions,
  reviewer_instructions,
  submission_urls,
}) => {
  const [isAccepting, setisAccepting] = useState(_isAccepting);
  const StepKlass = isAccepting ? AcceptStep : RejectStep;
  const goToReject = () => setisAccepting(false);
  const { isMobile, isTablet } = useStoreState((state) => state.media);
  const paddingBody = isMobile ? "18px" : isTablet ? "24px" : "40px 80px";
  return (
    <Modal paddingBody={paddingBody} open onClose={hideModal} maxWidth="684px" marginTop={"77px"}>
      <StepKlass
        id={id}
        message={message}
        username={username}
        contribution_claim_id={contribution_claim_id}
        goToReject={goToReject}
        afterClose={afterClose}
        hideModal={hideModal}
        verification_code={verification_code}
        activity_name={activity_name}
        conditions={conditions}
        reviewer_instructions={reviewer_instructions}
        submission_urls={submission_urls}
        isMobile={isMobile}
      ></StepKlass>
    </Modal>
  );
};

ManagePendingContributionModal.defaultProps = {
  isAccepting: true,
};

export default ManagePendingContributionModal;
