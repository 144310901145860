import { Modal } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CongratsBody from "./CongratsBody";
import ConnectBody from "./ConnectBody";

const STEPS = [ConnectBody, CongratsBody];

const mobileConfig = {
  fullScreen: true,
  hideX: true,
  overflowY: "auto",
  borderRadius: "0px !important",
  backText: "Back to community",
  contentHeight: "100%",
};

const nonMobileConfig = { leaf: true };

const DiscourseConnectModal = (props) => {
  const { closeOthers } = useStoreActions((state) => state.modals);
  const { isMobile } = useStoreState((state) => state.media);
  const [username, setUsername] = useState();
  const [step, setStep] = useState(0);
  const onChange = (event) => setUsername(event.target.value);
  const Body = STEPS[step];
  const config = isMobile ? mobileConfig : nonMobileConfig;

  useEffect(() => {
    closeOthers("DiscourseConnectModal");
  }, []);

  return (
    <StyledModal
      open
      onClose={props.hideModal}
      leaf={!isMobile}
      spacing="px-6+6 pb-4+1"
      sm-spacing="px-4 pt-4"
      maxWidth="684px"
      maxHeight="751px"
      {...config}
    >
      <Body {...props} onChange={onChange} username={username} setStep={setStep} />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  & > div {
    @media only screen and (max-height: 990px) {
      transform: unset;
    }
  }
  & > div > div {
    padding-top: 63px;
    & > div:first-child {
      button {
        right: 16px;
        top: 16px;
      }
    }
  }
`;

export default DiscourseConnectModal;
