import { breakpoints } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../Loader";
import { CommunityContext } from "../CommunityContext";
import { saveRecentCommunityID } from "../Constants";
import useGitcoinRequirementCheck from "../hooks/useGitcoinRequirementCheck";
import { useModalRoutes } from "../modals/SignInFlowModal";
import Challenges from "./Challenges";
import Hero from "./Hero/index";
import useChallengeStatus from "./useChallengeStatus";

const CommunityPage = () => {
  const navigate = useNavigate();
  const [querys] = useSearchParams();
  const tabindex = parseInt(querys.get("tabindex"));
  const containerRef = useRef(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { community, role, isAdmin } = useStoreState((state) => state.community);
  const { id } = useStoreState((state) => state.user);
  const { find, getMyContributionsRequest, reset } = useStoreActions((actions) => actions.community);
  const { getPendingContributions } = useStoreActions((actions) => actions.pendingContributions);
  const [loading, setLoading] = useState(true);
  const { communityId, isCustomDomain } = useContext(CommunityContext);
  useGitcoinRequirementCheck(community?.required_gitcoin_score);

  const onChangeTab = (index) => setCurrentTabIndex(index);

  useEffect(() => {
    communityId &&
      find(communityId)
        .then(() => setLoading(false))
        .catch(() => navigate("/"));

    return () => {
      if (!isCustomDomain) {
        reset();
      }
    };
  }, [communityId]);

  useEffect(() => {
    if (community) {
      saveRecentCommunityID(community.id);
    }
  }, [community]);

  useEffect(() => {
    if (role == null) return;

    if (isAdmin) {
      getPendingContributions({ community_id: communityId });
    } else {
      setCurrentTabIndex(0);
    }
  }, [role]);

  useEffect(() => {
    if (id) {
      getMyContributionsRequest(communityId).finally(() => setLoading(false));
    }
  }, [id]);

  useEffect(() => {
    if (tabindex) {
      setCurrentTabIndex(tabindex);
      navigate(window.location.pathname);
    }
  }, [tabindex]);

  useModalRoutes();
  useChallengeStatus();

  if (loading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <Hero community={community} loading={loading} />
      <MainGrid>
        <Container ref={containerRef} currentTabIndex={currentTabIndex}>
          <Challenges currentTabIndex={currentTabIndex} community={community} role={role} onChangeTab={onChangeTab} />
        </Container>
      </MainGrid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors["01Primary0"]};
`;

const MainGrid = styled.div`
  background: ${({ theme }) => theme.colors.background};
  border-top: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  position: relative;
  @media ${breakpoints.tablet}, @media ${breakpoints.mobile} {
    display: flex;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  // display: grid;
  grid-template-columns: ${({ currentTabIndex }) =>
    currentTabIndex === 3 || currentTabIndex === 2 ? "auto" : "auto 326px"};
  gap: 25px;
  padding-bottom: 63px;

  @media (max-width: 1003px) {
    grid-template-columns: auto;
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0 0 24px;
  }
`;

export default CommunityPage;
