import { Box, Button, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";

import preview_image from "@assets/preview-discourse.gif";
import { makeErrorHandler } from "../../ConnectSocialProfilesPage";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import useGetDiscourseUrl from "./useGetDiscourseUrl";

const ConnectBody = ({ name, provider, setStep, onChange, username }) => {
  const { verification_code } = useStoreState((state) => state.user.user);
  const { connectSocialProfile, getProfileRequest } = useStoreActions((actions) => actions.authentication);
  const { dangerToast } = useStoreActions((actions) => actions.toasts);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(0);
  let errorConfig = {};
  const url = useGetDiscourseUrl(provider);
  const onError = makeErrorHandler(dangerToast, () => setError(true));

  const doCopy = useCopyToClipboard(verification_code, "Your verification code has been copied.");

  if (error) {
    errorConfig = { invalid: true, validationText: "We were unable to verify that username" };
  }

  const onSubmit = (event) => {
    setLoading(true);
    connectSocialProfile({ provider, params: { username } })
      .then(() => {
        // TODO: there might be a better place to put this.
        getProfileRequest();
        setStep(1);
      })
      .catch(onError)
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Text textAlign="center" typography="heading/medium">
        Connect to {name}
      </Text>
      <Line />
      <CounterSection spacing="" count="1">
        <StyledList>
          <li>
            <LinkWrapper typography="body/medium">
              Open{" "}
              <a href={url} target="_blank" rel="noreferrer">
                {name}
              </a>
            </LinkWrapper>
          </li>
          <li>
            <Text typography="body/medium">
              Open your
              <IconSvg icon="circle-avatar" inline spacing="mx-1" />
              Profile drop-down choose
              <IconSvg icon="gear" inline spacing="mx-1" /> Preferences
            </Text>
          </li>
          <li>
            <Text typography="body/medium">In the left navigation choose “Profile”</Text>
          </li>
          <li>
            <Text typography="body/medium">
              Copy the code below into the “About me” section, and{" "}
              <StyledText inline bold>
                Save Changes
              </StyledText>
            </Text>
          </li>
        </StyledList>
      </CounterSection>
      <Box spacing="mb-4" flex>
        <StyledInput code value={verification_code} width="309px" spacing="mr-3" disabled bold />
        <CopyButton rightIcon="copy" onClick={doCopy} />
      </Box>
      <img src={preview_image} />
      <Line />
      <CounterSection count="2">
        <Text typography="body/medium">
          After adding the code to your profile, let us know your profile username and click to Verify Account below
        </Text>
      </CounterSection>
      <StyledInput onChange={onChange} placeholder="Forum username" width="378px" {...errorConfig} showValidation />
      <Line />
      <Button
        size="lg"
        borderRadius="50px"
        color="02Primary0"
        disabled={!username}
        onClick={onSubmit}
        loading={loading}
        bold
        modal={true}
      >
        Verify Account
      </Button>
    </>
  );
};

const StyledList = styled.ol`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 14.5px;
  padding-inline-start: 24px;
  ${IconSvg} {
    vertical-align: bottom;
  }

  li + li {
    margin-top: 6px;
  }
`;

const StyledText = styled(Text)`
  border: 1px solid ${({ theme }) => theme.colors["02Primary500"]};
  padding: 4px;
`;

const StyledInput = styled(Input)`
  & input {
    font-family: Outfit;
    color: ${({ theme }) => theme.colors["02Primary500"]};
    opacity: 1;
    ${({ code }) => code && "font-weight: 700;"}
    ${({ code }) => code && "text-align: center;"}
    font-size: 20px;
    line-height: 24px;
    background: ${({ theme }) => theme.colors["02Primary0"]};
    border-color: ${({ theme }) => theme.colors.grey300};
  }
`;

const CopyButton = styled(Button)`
  width: 56px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #e4e4ed;
  border-radius: 12px;
  color: #5fb974;

  svg {
    & {
      margin-left: 0px !important;
      color: ${({ theme }) => theme.colors["hover"]};
      min-width: 21px;
    }
  }
`;

const CounterSection = ({ count, children, spacing = "mb-5" }) => (
  <Box Box flex alignItems="center" spacing={spacing} relative>
    <Counter spacing="mr-5">{count}</Counter>
    {children}
  </Box>
);

const _Line = ({ className }) => (
  <Box className={className} spacing="py-6">
    <Box />
  </Box>
);

const Counter = styled(Text)`
  background: ${({ theme }) => theme.colors.tertiary100};
  width: 38px;
  height: 38px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  left: -52px;
  top: 0;
`;

const Line = styled(_Line)`
  ${Box} {
    position: absolute;
    background-color: ${({ theme }) => theme.colors["01Primary200"]};
    height: 1px;
    width: 100%;
    left: 0;
  }
`;

const LinkWrapper = styled(Text)`
  & a {
    color: ${({ theme }) => theme.colors["01Link"]};
    font-weight: 700;
  }
`;

export default ConnectBody;
