import DebouncedSearchInput from "@common/DebouncedSearchInput";
import TabWrapper from "@common/TabWrapper";
import { Box, ListingFilters } from "@thrivecoin/ui-components";
import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Stats from "../common/Stats";
import TableTab from "../CommunityPage/Challenges/Contributions/TableTab";
import useSort from "../hooks/useSort";
import FilterMobile from "./Mobile/FilterMobile";
import MobileContributors from "./Mobile/MobileContributors";
import TableContributors from "./TableContributors";
import useTagFilters, { TAGS } from "./useTagFilters";

const modalProps = {
  filterLabel: "Contributors",
  backLabel: "Back to Season Page",
  hideSort: true,
};

const CONFIG = {
  season: {
    storeKey: "season",
    dataKey: "season",
    statsKey: "seasonStats",
  },
  community: {
    storeKey: "community",
    dataKey: "community",
    statsKey: "community",
  },
};

const CONTRIBUTORS_CONFIG = {
  season: {
    details: "contributorDetails",
    mainKey: "seasonContributors",
    paginationKey: "seasonContributorsPagination",
  },
  community: {
    details: "contributorDetails",
    mainKey: "communityContributors",
    paginationKey: "communityContributorsPagination",
  },
};

const SeasonContributors = ({ group, id }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const isSeason = group === "season";
  const navigate = useNavigate();
  const { user } = useStoreState((state) => state.user);
  const { confirmed } = useStoreState((state) => state.user.user);
  const { getActiveSeasonRequest, getSeasonStats } = useStoreActions((actions) => actions.season);
  const { getContributors, getPersonalStatus } = useStoreActions((actions) => actions.contributors);
  const _getContributors = (params) => getContributors({ ...params, type: group, id });
  const [sortValue, setSortValue] = useState(TAGS[0].label);
  const sortProps = useSort(_getContributors);
  const tagProps = useTagFilters(sortProps, confirmed);

  const { storeKey, dataKey, statsKey } = CONFIG[group];
  const store = useStoreState((state) => state[storeKey]);
  const _group = store[dataKey];
  const _stats = store[statsKey];

  const { details, mainKey, paginationKey } = CONTRIBUTORS_CONFIG[group];

  const storeContributors = useStoreState((state) => state.contributors);

  const contributorDetails = storeContributors[details];
  const _contributors = storeContributors[mainKey];
  const _contributorsPagination = storeContributors[paginationKey];

  const isCompleted = _group?.status === "completed" || _group?.status === "raffle_completed";
  const nameGroup = isSeason ? _group?.title : _group?.name;
  const titleGroup = capitalizeFirstLetter(group);
  const title = `Contributors to the ${isCompleted ? "completed " : ""}${nameGroup} ${titleGroup}`;
  const rewards_token = _group.rewards_token?.toUpperCase();
  const isCustomToken = _group.reward_system == "custom_token";
  const hide_amounts = _group.hide_amounts;
  const selectedItem = !isMobile ? tagProps?.tagIds : sortValue;

  const applyFilters = () => {
    return {
      ...(selectedItem.includes("filter_top_ten") && { filter_top_ten: true }),
      ...(selectedItem.includes("filter_top_hundred") && { filter_top_hundred: true }),
      ...(selectedItem.includes("user_id") && { user_id: user.id }),
    };
  };
  const onLoadMore = (page) => {
    const filters = applyFilters();
    return Promise.resolve(
      sortProps
        .sortMethod({
          id,
          type: group,
          page,
          ...filters,
        })
        .finally(() => sortProps.setLoading(false))
    );
  };

  const onProfileClick = (e) => {
    const user_id = e.target.dataset.userid;
    navigate(`/${user_id}/profile`);
  };

  const rowProps = {
    onProfileClick,
    _group,
    user,
    sortProps,
    rewards_token,
    isCustomToken,
    contributorDetails,
    titleGroup,
    selectedItem,
  };

  useEffect(() => {
    if (_group?.id != id) {
      getActiveSeasonRequest(id);
    }

    if (_stats?.id != id) {
      getSeasonStats(id);
    }
  }, [id, user.id]);

  useEffect(() => {
    if (confirmed) {
      getPersonalStatus({ id: id, type: "community" });
    }
  }, [confirmed]);

  useEffect(() => {
    if (selectedItem.length > 0) {
      const filters = applyFilters();
      getContributors({ type: group, id, ...filters });
    }
  }, [selectedItem]);

  return (
    <TableTab
      title={title}
      showFilter={false}
      TableComponent={TableContributors}
      MobileTableComponent={MobileContributors}
      data={_contributors}
      pagination={_contributorsPagination}
      sortProps={{ ...sortProps, onLoadMore }}
      rowProps={rowProps}
      modalProps={modalProps}
      isContributors={true}
      WrapperKlass={TabWrapper}
    >
      <Box relative>
        <Stats type={group} stats={isSeason ? _stats : _group?.contributions_stats} hide_amounts={hide_amounts} rewards_token={rewards_token} />
      </Box>
      {isMobile && (
        <BoxGrid justifyContent="space-between" alignItems="center" gap="16px">
          <WrapperFilter>
            <FilterMobile sortValue={sortValue} setSortValue={setSortValue} />
          </WrapperFilter>
          <Box spacing="ml-a">
            <DebouncedSearchInput onChange={sortProps.onChange} />
          </Box>
        </BoxGrid>
      )}
      {!isMobile && <ListingFilters showFilter={false} {...tagProps} />}
    </TableTab>
  );
};

const BoxGrid = styled(Box)`
  display: grid;
  grid-template-columns: auto 1fr;
`;
const WrapperFilter = styled(Box)`
  width: 100%;
  position: relative;
  overflow: hidden;
`;
export default SeasonContributors;
