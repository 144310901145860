import { Box, Button, IconSvg, Input, Modal, Text, TextArea } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { makeConditionElements } from "./Condition";

const ConfirmContributionModal = ({ hideModal, activity, callback, code }) => {
  const { isMobile, isTablet } = useStoreState((state) => state.media);
  const [input, setInput] = useState("");
  const isRequired = activity.submission_urls;

  const { makeContributionClaimRequest } = useStoreActions((action) => action.listing);
  const { successToast, dangerToast } = useStoreActions((action) => action.toasts);
  const { user } = useStoreState((state) => state.user);
  const conditionElements = makeConditionElements(activity.conditions, false);
  const [submissionUrls, setSubmissionUrls] = useState(activity.submission_urls);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const onChange = (e) => {
    e.preventDefault();
    setInput(e.target.value);
  };
  const handleSubmissionUrlChange = (index, value) => {
    const updatedUrls = [...submissionUrls];
    updatedUrls[index].value = value;
    setSubmissionUrls(updatedUrls);
  };

  const onConfirm = () => {
    makeContributionClaimRequest({ message: input, id: activity.id, submission_urls: submissionUrls })
      .then(({ message }) => {
        successToast({ content: message });
        hideModal();
        callback && callback();
      })
      .catch((e) => {
        dangerToast({ content: e.response.data.message });
      });
  };

  const onCopyCode = useCopyToClipboard(user.verification_code, "Your code has been copied.");
  const paddingBody = isMobile ? "18px" : isTablet ? "24px" : "40px 80px";

useEffect(() => {
  const hasNonEmptyUrl = isRequired && submissionUrls.some(url => url.value && url.value !== "");
  setIsButtonDisabled(!hasNonEmptyUrl);
}, [submissionUrls, isRequired]);



  // console.log(isRequired, submissionUrls, isButtonDisabled)

  return (
    <Modal open onClose={hideModal} paddingBody={paddingBody} maxWidth="684px">
      <Box flex direction="column" spacing="my-5">
        <Text typography="heading/medium" color="02Primary500" spacing="mb-4" bold>
          Manual Submission for
        </Text>
        <Text typography="body/large" color="02Primary500" extraBold>
          {activity.name}
        </Text>
        <Line bgColor="02PrimaryGrey300" spacing="my-5" />
        <Box>
          <Text typography="body/medium" color="02Primary500" spacing="mb-3" extraBold>
            Conditions:
          </Text>
          <WrapperConditions>{conditionElements}</WrapperConditions>
        </Box>
        <Line bgColor="02PrimaryGrey300" spacing="my-5" />
        <Text typography="body/medium" color="02Primary700" spacing="mb-3" bold>
          Submission Instructions
        </Text>
        <Box spacing="mb-2">
          <InstructionsText
            typography="body/medium"
            color="02Primary700"
            dangerouslySetInnerHTML={{ __html: activity.submission_instructions }}
          />
        </Box>
        <CopyWrapper pointer onClick={onCopyCode} spacing="mb-5" color="02Primary500">
          <Text>Personal Code:</Text>
          <Text bold>{user.verification_code}</Text>
          <IconSvg width="14px" icon="copy" />
        </CopyWrapper>

        {submissionUrls?.map((url, index) => {
          return (
            <StyledInput
              key={`submissionUrl-${index}`}
              placeholder={url.placeholder}
              color="02Primary500"
              bgColor="02PrimaryGrey50"
              spacing="mb-5"
              onChange={(e) => handleSubmissionUrlChange(index, e.target.value)}
              underlineText={url.value && "underline"}
            />
          );
        })}
        <StyledTextArea
          value={input}
          onChange={onChange}
          color="02Primary500"
          bgColor="02PrimaryGrey50"
          borderColor="02PrimaryGrey300"
          placeholder="Comment if needed (Optional)"
          rows="3"
          spacing="mb-5"
        />
        <Box direction={isMobile && "column-reverse"} flex gap="1rem">
          <Button
            modal
            size="lg"
            bold
            height="56px"
            width={isMobile ? "100%" : "161px"}
            kind="outline"
            borderRadius="40px"
            variant="white"
            onClick={hideModal}
            modal={true}
          >
            Cancel
          </Button>
          <Button
            modal
            bold
            height="56px"
            grow={1}
            borderRadius="40px"
            onClick={onConfirm}
            disabled={isButtonDisabled}
            modal={true}
          >
            Confirm Contribution
          </Button>
        </Box>
        {isMobile && <Box bgColor="02PrimaryGrey300" spacing="mt-5" height="1px" width="100%" />}
      </Box>
    </Modal>
  );
};

const CopyWrapper = styled(Box)`
  display: flex;
  height: 44px;
  width: 306px;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors["02PrimaryGrey300"]};
  background: ${({ theme }) => theme.colors["02PrimaryGrey50"]};
`;

const Line = styled(Box)`
  height: 1px;
  width: 100%;
`;

const StyledTextArea = styled(TextArea)`
  text-area {
    max-height: 89px;
    &::placeholder {
      color: ${({ theme }) => theme.colors["02Primary500"]};
    }
  }
`;

const StyledInput = styled(Input)`
  input {
    border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
    color: ${({ theme }) => theme.colors["01Link"]};
    ${({ underlineText }) => underlineText && `text-decoration: ${underlineText}`};
    font-weight: 600;
  }
`;
const WrapperConditions = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;
  gap: 12px;
`;

const InstructionsText = styled(Text)`
  white-space: break-spaces;
`;
export default ConfirmContributionModal;
