import ConfettiIcon from "@assets/confettis.svg";
import preview_image from "@assets/preview-discourse2.gif";
import { Button, Text } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import styled from "styled-components";
import useGetDiscourseUrl from "./useGetDiscourseUrl";

const CongratsBody = ({ hideModal, username, provider }) => {
  const { showModal } = useStoreActions((state) => state.modals);
  const forum = useGetDiscourseUrl(provider);
  const profileLink = `${forum}/u/${username}/preferences/profile`;
  const shareMore = () => {
    hideModal();
    showModal({ modalName: "ConnectSocialMediasModal", provider });
  };

  const close = () => {
    hideModal();
    showModal({ modalName: "UpdateProfileModal", provider });
  };

  return (
    <>
      <StyledImage src={ConfettiIcon} />
      <Text typography="heading/medium" align="center" spacing="mb-5">
        Congratulations, your username was successfully verified.
      </Text>
      <Text align="center" spacing="mb-6">
        <b>
          You can now delete the code from{" "}
          <StyledLink href={profileLink} target="_blank" rel="noreferrer">
            your profile
          </StyledLink>
        </b>
        . Your account on this forum will remain verified even if you change your username.
      </Text>
      <img src={preview_image} />
      <Button size="lg" borderRadius="50px" spacing="mb-6 mt-6+2" bold onClick={shareMore} modal={true}>
        Connect More Networks For More Rewards
      </Button>
      <Button size="lg" borderRadius="50px" kind="outline" color="02Primary500" variant="grey300" bold onClick={close} modal={true}>
        Keep Contributing
      </Button>
    </>
  );
};

const StyledLink = styled.a`
  font-weight: bold;
  text-decoration: none;
  color: ${({ theme }) => theme.colors["01Link"]};
`;

const StyledImage = styled.img`
  position: absolute;
  left: 4%;
  top: 1%;
`;

export default CongratsBody;
