import { EVENT_NAME } from "@utils/customEvent";
import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";

const useCustomDomEvents = () => {
  const { dangerToast, clear } = useStoreActions((actions) => actions.toasts);
  useEffect(() => {
    const listener = (event) => {
      if (event.detail.code === "request-timeout") {
        clear();
        dangerToast({ content: "We are still waiting on our servers to respond. Please retry this request later." });
      }
    };

    document.addEventListener(EVENT_NAME, listener);
    return () => document.removeEventListener(EVENT_NAME, listener);
  }, []);
};

export default useCustomDomEvents;
