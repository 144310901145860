import DefaultFeatured from "@assets/dashboard/SeasonFeaturedBlockchain.svg";
import TabWrapper from "@common/TabWrapper";
import { Box, breakpoints, Loader, SeasonCard } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CommunityContext } from "../../../CommunityContext";
import EmptyContent from "../../../EmptyContent";
import StatusesTabs from "./StatusesTabs";
import UnclaimedSeasonCard from "./UnclaimedSeasonCard";

const Announcements = ({ setTabIndex }) => {
  const [loading, setLoading] = useState(false);
  const { confirmed } = useStoreState((state) => state.user.user);
  const navigate = useNavigate();
  const { communityId } = useContext(CommunityContext);
  const { community, unclaimedSeasons, publicSeasons, seasonsCount } = useStoreState((state) => state.community);
  const { getUnclaimedSeasons, getPublicSeasons, getSeasonsCountByStatus } = useStoreActions(
    (actions) => actions.community
  );
  const { isMobile } = useStoreState((state) => state.media);
  const { hide_amounts } = community;
  const noSeasons = !seasonsCount || seasonsCount.total === 0;
  const accentColor = community.theme === "custom" && community.accent_color;

  const goToSeason = (id) => {
    const url = `/seasons/${id}`;
    navigate(url);
  };

  const goToWays = () => setTabIndex(1);

  const _renderUnclaimedSeasons = () =>
    unclaimedSeasons.length > 0 && (
      <UnclaimedWrapper>
        {unclaimedSeasons.map((item) => (
          <UnclaimedSeasonCard key={item.id} {...item} />
        ))}
      </UnclaimedWrapper>
    );

  const onTabChange = (status) => {
    setLoading(true);
    getPublicSeasons({ id: communityId, status }).finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getPublicSeasons({ id: communityId }).finally(() => setLoading(false));
    getSeasonsCountByStatus(communityId);
  }, []);

  useEffect(() => {
    if (confirmed) {
      getUnclaimedSeasons(communityId);
    }
  }, [confirmed]);

  return (
    <TabWrapper color="01Primary700">
      <Wrapper spacing="mx-a" flex direction="column">
        {!loading && noSeasons ? (
          <EmptyContent
            title="All clear!"
            text="Don’t worry -- there may not be announcements, but you can still contribute!"
            imageWidth="326.57px"
            marginTopImage={isMobile ? "76px" : "114px"}
            maxWidth={"900px"}
            btnText={"View Ways to Contribute"}
            hideBtnIcon
            onClick={goToWays}
          />
        ) : (
          <>
            {_renderUnclaimedSeasons()}
            <StatusesTabs counts={seasonsCount} onTabChange={onTabChange} />
            {!loading &&
              publicSeasons.map((item) => (
                <Box key={item.id} spacing="mb-6">
                  <SeasonCard
                    accentColor={accentColor}
                    season_number={item.order_number}
                    hide_amounts={hide_amounts}
                    {...item}
                    end_date={moment(item.end_date).format("MMM Do, YYYY")}
                    onStart={() => goToSeason(item.slug || item.id)}
                    featured_image_url={item.featured_image_url || DefaultFeatured}
                  />
                </Box>
              ))}
          </>
        )}
        {loading && (
          <Box flex justifyContent="center" spacing="m-6">
            <Loader />
          </Box>
        )}
      </Wrapper>
    </TabWrapper>
  );
};

const Wrapper = styled(Box)`
  @media ${breakpoints.tablet} {
    padding: 0px 24px;
  }

  @media ${breakpoints.mobile} {
    padding: 0px 16px;
  }
`;

const UnclaimedWrapper = styled(Box)`
  margin-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
`;

export default Announcements;
