import { Modal } from "@thrivecoin/ui-components";
import CommunityEditor from "../CommunityEditor";

const CommunityEditorModal = ({ hideModal, communityID }) => {
  console.log(communityID);

  return (
    <Modal
      open
      hideX
      width="100%"
      paddingBody="0"
      transform="unset"
      onClose={hideModal}
      maxWidth="calc(100vw - 64px)"
      contentHeight="calc(100vh - 64px)"
      borderRadius="12px"
    >
      <CommunityEditor communityID={communityID} />
    </Modal>
  );
};

export default CommunityEditorModal;
