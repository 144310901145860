import Christy from "@assets/about-us/christy.svg";
import Daniel from "@assets/about-us/daniel.svg";
import Emily from "@assets/about-us/emily.svg";
import Rilind from "@assets/about-us/rilind.svg";
import Robert from "@assets/about-us/robert.svg";
import Vigan from "@assets/about-us/vigan.svg";
import { breakpoints, Text } from "@thrivecoin/ui-components";
import { LinkedinIcon } from "react-share";
import styled from "styled-components";

const WhoWeAre = ({ elRef }) => {
  return (
    <WhoWeAreWrapper ref={elRef}>
      <Header>Who are we</Header>

      <Paragraph>
        We are a team of nearly 20 social entrepreneurs, engineers, designers, and community leaders who’ve devoted our
        lives to addressing the complex problems of humanity. Here are the LinkedIn profiles of a few of our full-time
        leaders (big team page coming soon!):
      </Paragraph>

      <WrapperTeam>
        <Member>
          <WrapperMemberInside>
            <TeamImg src={Daniel} />
            <Name>Daniel Jacobs</Name>
            <JobDesc>Chief Executive Officer</JobDesc>
          </WrapperMemberInside>
          <WrapperSocial>
            <Link target="_blank" href="https://www.linkedin.com/in/whoisdaniel/">
              <LinkedinIcon round className={"social-icons-about"} />
            </Link>
          </WrapperSocial>
        </Member>

        <Member>
          <WrapperMemberInside>
            <TeamImg src={Christy} />
            <Name>Christy Lin</Name>
            <JobDesc>Crypto & Community Adviser</JobDesc>
          </WrapperMemberInside>
          <WrapperSocial>
            <Link target="_blank" href="https://www.linkedin.com/in/christy314159/">
              {" "}
              <LinkedinIcon round className={"social-icons-about"} />
            </Link>
          </WrapperSocial>
        </Member>

        <Member>
          <WrapperMemberInside>
            <TeamImg src={Robert} />
            <Name>Robert Rome</Name>
            <JobDesc>Chief Product Officer</JobDesc>
          </WrapperMemberInside>
          <WrapperSocial>
            <Link target="_blank" href="https://www.linkedin.com/in/robertdrome/">
              <LinkedinIcon round className={"social-icons-about"} />
            </Link>
          </WrapperSocial>
        </Member>

        <Member>
          <WrapperMemberInside>
            <TeamImg src={Rilind} />
            <Name>Rilind Mehmeti</Name>
            <JobDesc>Chief Technology Officer</JobDesc>
          </WrapperMemberInside>
          <WrapperSocial>
            <Link target="_blank" href="https://www.linkedin.com/in/rilind-mehmeti-65b463132/">
              <LinkedinIcon round className={"social-icons-about"} />
            </Link>
          </WrapperSocial>
        </Member>

        <Member>
          <WrapperMemberInside>
            <TeamImg src={Emily} />
            <Name>Emily Jablonski</Name>
            <JobDesc>Head of Community</JobDesc>
          </WrapperMemberInside>
          <WrapperSocial>
            <Link target="_blank" href="https://www.linkedin.com/in/emily-jablonski-2021/">
              <LinkedinIcon round className={"social-icons-about"} />
            </Link>
          </WrapperSocial>
        </Member>

        <Member>
          <WrapperMemberInside>
            <TeamImg src={Vigan} />
            <Name>Vigan Abdurrahmani</Name>
            <JobDesc>Chief Information Officer</JobDesc>
          </WrapperMemberInside>
          <WrapperSocial>
            <Link target="_blank" href="https://www.linkedin.com/in/vigan-abdurrahmani-734666121/">
              <LinkedinIcon round className={"social-icons-about"} />
            </Link>
          </WrapperSocial>
        </Member>
      </WrapperTeam>
    </WhoWeAreWrapper>
  );
};

const WrapperMemberInside = styled.div`
  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;

const WhoWeAreWrapper = styled.div`
  background: #faf9f8;
  border-radius: 25px;
  padding: 63px 98px 100px 98px;
  @media ${breakpoints.tablet} {
    padding: 63px 85px 91px 85px;
  }
  @media ${breakpoints.mobile} {
    padding: 50px 30px 59px 30px;
  }
  .social-icons-about {
    border: 1px solid #464967;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;

    circle {
      fill: white;
    }
    path {
      fill: #464967;
    }
  }
`;

const Header = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 34px;
  line-height: 42px;
  color: ${({ theme }) => theme.colors["02Primary700"]};
  margin: 0 0 40px 0;
  text-align: center;
  @media ${breakpoints.mobile} {
    font-size: 24px;
    margin: 0 0 22px 0;
  }
`;

const Paragraph = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin: 0 auto 60px auto;
  max-width: 875px;
  @media ${breakpoints.tablet} {
    max-width: 779px;
  }
  @media ${breakpoints.mobile} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const WrapperTeam = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(373px, 1fr));
  column-gap: 2.375rem;
  row-gap: 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 1195px;

  @media ${breakpoints.mobile} {
    grid-template-columns: repeat(auto-fit, minmax(313px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    justify-content: center;
  }
`;

const Member = styled.div`
  width: 373px;
  height: 366px;
  background: ${({ theme }) => theme.colors["01Primary0"]};
  border-radius: 0px 0px 40px 0px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &:nth-child(odd) {
    margin-left: auto;
  }
  @media ${breakpoints.mobile} {
    margin: auto;
    width: 255px;
    flex-direction: row;
    max-height: 150px;
    align-items: flex-start;
    justify-content: space-between;
    padding: 19px 25px 14px 22px;
    text-align: left;
  }
`;

const TeamImg = styled.img`
  margin: 25px auto 31px auto;
  width: 100%;
  height: auto;
  max-width: 170px;
  @media ${breakpoints.mobile} {
    width: 80px;
    height: 80px;
    margin: 0 0 16px 0;
  }
`;

const Name = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 24px;
  line-height: 24px;
  color: #e2857e;
  margin-bottom: 2px;
  height: 30px;
  @media ${breakpoints.mobile} {
    font-size: 22px;
  }
`;

const JobDesc = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.secondary400};
  height: 57px;
  @media ${breakpoints.mobile} {
    font-size: 14px;
    height: 22px;
    text-align: left;
  }
`;

const WrapperSocial = styled.div`
  display: flex;
  gap: 11px;
`;

export default WhoWeAre;
