import AvatarUsername from "@common/UsernameAvatar";
import { Box, Text, TokenReward } from "@thrivecoin/ui-components";
import styled from "styled-components";

const RowElement = ({
  username,
  _group,
  rewards_earned,
  onProfileClick,
  image_url,
  index,
  user_id,
  showBorderTop,
  rewards_token,
  contributions_count,
  bgColor = "01Primary0",
  dataColor = "01Primary700",
  borderColor = "01Primary0",
  contributor_rank,
  personal,
  isDesktop
}) => {
  const { hide_amounts } = _group;
  const topThree = [1, 2, 3].includes(contributor_rank);
  return (
    <>
      <RowStyled borderColor={borderColor} showBorderTop={showBorderTop} bgColor={bgColor}>
        <DataElement>
          <Box flex alignItems="center" gap="8px">
            {!!_group && (
              <AvatarUsername
                color={dataColor}
                username={username}
                dataID={_group.id}
                onProfileClick={onProfileClick}
                image_url={image_url}
                id={topThree ? null : contributor_rank}
                index={index}
                user_id={user_id}
                imageWidth={!topThree ? "44px" : "54px"}
                imageHeight={!topThree ? "44px" : "56px"}
                contributor_rank={contributor_rank}
                isContributor
              />
            )}
            {personal && contributor_rank < 101 && (
              <Text typography="body/medium" color="02Primary500" bold>
                (You are a Top 100 contributor)
              </Text>
            )}
          </Box>
        </DataElement>

        {!hide_amounts && (
          <DataElement padding="26px 16px 26px 0">
            <Box relative flex alignItems="center" justifyContent="start" gap="6px">
              <TokenReward
                iconSize="40px"
                size="30px"
                lHeight="34px"
                currencySize="16px"
                currencyLHeight="24px"
                color={dataColor}
                amount={+rewards_earned}
                token={rewards_token}
                hideText
              />
            </Box>
          </DataElement>
        )}
        {isDesktop && (
          <DataElement>
            <Box relative flex alignItems="center" justifyContent="start">
              <Text extraBold typography="body/medium" color={dataColor}>
                {contributions_count}
              </Text>
            </Box>
          </DataElement>
        )}
      </RowStyled>
    </>
  );
};

const RowStyled = styled.tr`
  background-color: ${({ bgColor, theme }) => bgColor && (theme.colors[bgColor] || bgColor)};
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    td:first-child {
      border-top-left-radius: 8px;
    }
    td:last-child {
      border-top-right-radius: 8px;
    }
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    td:first-child {
      border-bottom-left-radius: 8px;
    }
    td:last-child {
      border-bottom-right-radius: 8px;
    }
  }

  td {
    border-bottom: 1px solid ${({ borderColor, theme }) => borderColor && (theme.colors[borderColor] || borderColor)};
    border-top: ${({ borderTop }) => borderTop && "1px solid #8DCF9F"};
  }
`;

const DataElement = styled.td`
  padding: ${({ padding }) => padding || `26px 16px`};
  ${({ pointer }) => pointer && `cursor: pointer;`}
`;

export default RowElement;
