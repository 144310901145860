import AvatarUsername from "@common/UsernameAvatar";
import { Box, Text, TokenReward } from "@thrivecoin/ui-components";
import pluralize from "@utils/pluralize";
import styled from "styled-components";
import EmptySearch from "../../EmptySearch";

const Contributor = ({
  index,
  username,
  _group,
  rewards_earned,
  onProfileClick,
  image_url,
  user_id,
  rewards_token,
  dataColor = "02Primary500",
  contributor_rank,
  user,
}) => {
  const { hide_amounts } = _group;
  const topThree = [1, 2, 3].includes(contributor_rank);
  const personal = user_id === user.id;
  let bgColor = contributor_rank < 10 ? "contributors01" : "contributors02";
  bgColor = personal ? "03Primary300" : bgColor;
  return (
    <>
      <Wrapper flex justifyContent="space-between" spacing="px-4 py-4+1" bgColor={bgColor} color={dataColor}>
        <UsernameRankWrapper gap="8px" alignItems="center" flex color={dataColor}>
          {!!_group && (
            <AvatarUsername
              color={dataColor}
              username={username}
              dataID={_group.id}
              onProfileClick={onProfileClick}
              image_url={image_url}
              id={topThree ? null : contributor_rank}
              index={index}
              user_id={user_id}
              imageWidth={!topThree ? "40px" : "44px"}
              imageHeight={!topThree ? "40px" : "44px"}
              contributor_rank={contributor_rank}
              isContributor
              isMobile
            />
          )}
        </UsernameRankWrapper>
        {!hide_amounts && (
          <WrapperReward>
            <TokenReward color={dataColor} amount={+rewards_earned} token={rewards_token} />
          </WrapperReward>
        )}
      </Wrapper>
    </>
  );
};

const MobileContributors = ({
  data,
  _group,
  onProfileClick,
  hideTopEntries,
  onRaffleDetails,
  sortProps,
  onSearch,
  resultsFound,
  rewards_token,
  isCustomToken,
  user,
  searchValue,
  loading,
  titleGroup,
}) => {
  const dataResults = data?.length;
  const results = !loading && `${dataResults} ${pluralize("result", dataResults)} found for "${searchValue}"`;
  const headingText = searchValue ? results : `${titleGroup} Rank`;

  const contributors = (
    <Box>
      {data?.map((item) => (
        <Contributor
          key={item.id}
          id={item.contributor_rank}
          _group={_group}
          index={item.contributor_rank}
          onProfileClick={onProfileClick}
          hideTopEntries={hideTopEntries}
          onRaffleDetails={onRaffleDetails}
          sortProps={sortProps}
          onSearch={onSearch}
          resultsFound={resultsFound}
          rewards_token={rewards_token}
          isCustomToken={isCustomToken}
          borderColor="01Primary300"
          user={user}
          {...item}
        />
      ))}
    </Box>
  );

  return (
    <>
      <Text spacing="pb-4 pt-4+1" bold typography="body/large" color="01Primary700" textAlign="left">
        {headingText}
      </Text>
      {data.length === 0 ? (
        <WrapperOfEmpty>
          <EmptySearch />
        </WrapperOfEmpty>
      ) : (
        <Box>{contributors}</Box>
      )}
    </>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ borderTop, theme }) => theme.colors[borderTop] || borderTop};
  background-color: ${({ bgColor, theme }) => bgColor && theme.colors[bgColor]};

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const UsernameRankWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 16px;
`;

const WrapperReward = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 63px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

const WrapperOfEmpty = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors["01Primary700"]};
`;

export default MobileContributors;
