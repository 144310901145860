import CardHand from "@assets/profile/card-hand.svg";
import { Box, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import useAuthentication from "../hooks/useAuthentication";

const defaultConfig = {
  title: "Connect Wallet",
  description:
    "This is a <b>read-only</b> connection to verify your wallet address and let us know where to send any future crypto rewards.",
  metamaskIcon: "metamask2",
  coinBaseIcon: "coinbase",
  walletConnectIcon: "walletconnect",
  type: "solid",
  bgColor: "button",
  color: "02Primary0",
  spacing: "px-7 pt-7+6 pb-0",
  smSpacing: "px-7 pb-0",
};

const additionalConfig = {
  title: "Connect Additional Wallet",
  description:
    "This is a <b>read-only</b> connection to verify your ownership of an additional wallet. Verifying an additional wallet allows you to be rewarded for your activity across multiple wallets.",
  metamaskIcon: "metamask2",
  coinBaseIcon: "coinbase",
  walletConnectIcon: "walletconnect",
  type: "solid",
  bgColor: "button",
  color: "02Primary0",
  spacing: "px-7 pt-7+6 pb-0",
  smSpacing: "px-7 pb-0",
};

export const DISCORD_KEY = "discord_uid";

const WalletConnectorModal = ({
  hideModal,
  afterClose,
  referral_code,
  rememberProvider,
  useAsProvier,
  isAdditional,
}) => {
  const { isMobile } = useStoreState((state) => state.media);
  const [loading, setLoading] = useState(false);
  const showMetamask = getMetamaskProvider()?.isMetaMask;
  const config = isAdditional ? additionalConfig : defaultConfig;
  const { getProvider } = useAuthentication();

  const {
    title,
    description,
    metamaskIcon,
    coinBaseIcon,
    walletConnectIcon,
    type,
    bgColor,
    color,
    spacing,
    smSpacing,
  } = config;

  const onClick = async (event) => {
    const { name } = event.target.dataset;
    if (name) {
      setLoading(true);
      getProvider(name)
        .then((provider) => afterClose(referral_code, provider, name))
        .then(hideModal)
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      open
      onClose={hideModal}
      spacing={spacing}
      md-spacing="px-0 pt-6"
      sm-spacing={smSpacing}
      maxWidth="684px"
      borderRadius={isMobile ? "24px" : "12px"}
      paddingBody={isMobile && "0"}
    >
      <Box flex direction="column" textAlign="center" alignItems="center">
        <Text typography="heading/large" spacing="mb-4">
          {title}
        </Text>
        <Text
          typography="body/medium"
          maxWidth="520px"
          spacing="mb-6"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        {showMetamask && (
          <Button
            leftIcon={metamaskIcon}
            bold
            iconWidth="20px"
            onClick={onClick}
            loading={loading}
            data-name="Metamask"
            spacing="mb-6 p-4"
            borderRadius="50px"
            width="100%"
            maxWidth="524px"
            color={color}
            borderColor="button"
            bgColor={bgColor}
            kind={type}
            modal={true}
          >
            Connect Metamask
          </Button>
        )}

        <Button
          leftIcon={coinBaseIcon}
          bold
          iconWidth="20px"
          onClick={onClick}
          loading={loading}
          data-name="coinbase"
          spacing="mb-6 p-4"
          borderRadius="50px"
          width="100%"
          maxWidth="524px"
          color={color}
          borderColor="button"
          bgColor={bgColor}
          kind={type}
          modal={true}
        >
          Connect Coinbase Wallet
        </Button>

        <Button
          leftIcon={walletConnectIcon}
          bold
          iconWidth="20px"
          onClick={onClick}
          loading={loading}
          data-name="WalletConnect"
          spacing="p-4"
          borderRadius="50px"
          width="100%"
          maxWidth="524px"
          color={color}
          borderColor="button"
          bgColor={bgColor}
          kind={type}
          modal={true}
        >
          Connect WalletConnect
        </Button>
      </Box>
      <CardHandImg src={CardHand} />
    </Modal>
  );
};

const getMetamaskProvider = () => {
  let provider = window.ethereum;
  if (provider?.providers?.length) {
    provider.providers.forEach((_provider) => {
      if (_provider.isMetaMask) provider = _provider;
    });
  }

  return provider;
};

const CardHandImg = styled.img`
  max-width: 445.33px;
  margin: 16.11px auto 0 auto;
`;

WalletConnectorModal.defaultProps = {
  rememberProvider: true,
  useAsProvier: true,
};

export default WalletConnectorModal;
