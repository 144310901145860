import { Box, Button, IconSvg, Modal, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const FundChallengeModal = ({ hideModal, wallet = {}, maxReward = 100, onProceed }) => {
  const isEtherum = wallet.chain_type === "ethereum";

  return (
    <Modal open leaf maxWidth="680px" onClose={hideModal}>
      <Text bold textAlign="center" color="02Primary700" size="24px" lHeight="30px" spacing="my-5" fontFamily="Outfit">
        Fund Your Challenge
      </Text>
      <StyledBox spacing="mx-6+2 p-5" bgColor="tertiary100" borderRadius="12px">
        <Text extraBold size="14px" lHeight="16px" spacing="mb-4+1" color="02Primary700">
          Challenge Funding:
        </Text>
        <Box flex spacing="mb-5" gap="11px">
          <CirclesWrapper flex direction="column" color="secondary200">
            <PlusCircle>
              <IconSvg icon="plus-circle" width="13px" />
            </PlusCircle>
            {isEtherum && (
              <PlusCircle>
                <IconSvg icon="plus-circle" width="13px" />
              </PlusCircle>
            )}
            <IconSvg icon="plus-circle" width="13px" />
          </CirclesWrapper>
          <Box flex direction="column" gap="19px">
            <Text size="14px" lHeight="21px" color="02Primary700">
              Max Rewards to Distribute: <b>{maxReward} $THRIVE</b>
            </Text>
            {isEtherum && (
              <Box flex alignItems="flex-start" gap="7px">
                <Text size="14px" lHeight="21px" color="02Primary700">
                  Transaction Fee: <b>0.008872994 ETH</b>
                </Text>
                <IconSvg icon="help-circle" color="primary700" width="16px" />
              </Box>
            )}
            <Text size="14px" lHeight="21px" color="02Primary700">
              Platform Fee: <b>2.32 $THRIVE</b>
            </Text>
          </Box>
        </Box>
        <Box flex alignItems="center" gap="6px">
          <IconSvg icon="logo-gold" width="17px" />
          <Text bold fontFamily="Outfit" size="20px" lHeight="24px" color="02Primary700">
            {wallet.balance} $THRIVE
          </Text>
        </Box>
      </StyledBox>
      <Text color="02Primary700" spacing="mt-6 mb-5 mx-6+2">
        The Max Rewards to Distribute will be locked from your wallet balance to ensure you have enough funds available
        to reward contributors. Any unspent funds will be returned when the Challenge closes.
      </Text>
      <Text color="02Primary700" spacing="mb-5 mx-6+2">
        Additional fees will not be returned. To learn more, see our <Link>Help Doc</Link>.
      </Text>
      <Box flex gap="1rem" spacing="mx-6+2">
        <Button
          bold
          size="lg"
          height="53px"
          kind="outline"
          borderRadius="40px"
          width="252px"
          color="secondary400"
          borderColor="#BDBDBD"
          onClick={hideModal}
          modal={true}
        >
          Cancel
        </Button>
        <Button bold size="lg" height="53px" borderRadius="40px" grow={1} bgColor="primary700" onClick={onProceed} modal={true}>
          Proceed to Payment
        </Button>
      </Box>
    </Modal>
  );
};

const StyledBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
`;

const CirclesWrapper = styled(Box)`
  margin: 3px 0;
`;

const PlusCircle = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 27px;

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 29px;
    bottom: -28px;
    background-color: ${({ theme }) => theme.colors.secondary200};
  }
`;

const Link = styled.a`
  cursor: pointer;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.hover};
`;

export default FundChallengeModal;
