import { useStoreActions, useStoreState } from "easy-peasy";
import { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import removeEmptyFields from "../../utils/removeEmptyFields";
import { formatImageFiles } from "./Steps/common/formatImageFiles";

export const IMAGE_FIELDS = ["header_background", "cover_media", "logo", "logo_dark"];

const formatSocials = (obj) => {
  const array = [];
  for (const [id, value] of Object.entries(obj)) {
    array.push({ id, value });
  }

  return array;
};

const socialsObject = (arr) => {
  const data = {};

  for (const item of arr) {
    if (item.id && item.value) {
      data[item.id] = item.value;
    }
  }

  return data;
};

const CHECK_FIELDS = [
  "name",
  "subheadline",
  "slug",
  "font_family",
  "origin",
  "members",
  "vision",
  "impact",
  "get_involved",
];

export const DEFAULT_MEDIA = {
  logo: null,
  logo_type: "",
  logo_dark: null,
  logo_type_dark: "",
  header_background: null,
  header_background_type: "",
  header_background_logo: null,
  header_background_logo_type: "",
  cover_media: null,
  cover_media_type: "",
};

const COMMUNITY_VALUES = {
  name: "",
  subheadline: "",
  slug: "",
  accent_color: "#C2000C",
  header_background_color: "#000000",
  about: "",
  origin: "",
  members: "",
  vision: "",
  impact: "",
  get_involved: "",
  social_links: {},
  admin_emails: [],
  community_tags: [],
  theme: "thrive",
  ...DEFAULT_MEDIA,
};

const BANNER_COLOR = "#000000";

export const CommunityEditorContext = createContext({
  communityValues: {},
  socialsArray: [],
  communityTags: [],
  bannerColor: "",
  community: {},
  isPersisted: false,
  isDraft: false,
  canSaveAsDraft: true,
  previewMode: false,
  errorsValues: {},
  //
  communityData: () => {},
  setCommunityValues: () => {},
  setBannerColor: () => {},
  handleSubmit: async () => {},
  onClose: () => {},
  useMedia: () => ({}),
  createNewCommunity: () => {},
  updateCommunityValues: () => {},
  setPreviewMode: () => {},
  isValid: () => {},
  onChange: () => {},
  setState: () => {},
  setSocialsArray: () => {},
  setCommunityTags: () => {},
  saveAsDraft: () => {},
  onClickPreview: () => {},
});

const filterObject = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([key, val]) => !!key && !!val));
};

const CommunityEditorProvider = ({ children, communityID }) => {
  const id = communityID || useParams()?.id;
  const navigate = useNavigate();
  const { showModal, hideModal } = useStoreActions((actions) => actions.modals);
  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);
  const { createCommunity, createDraftCommunity, updateCommunity, getEditCommunity, reset, find, setPreview } =
    useStoreActions((actions) => actions.community);
  const { editCommunity, community } = useStoreState((state) => state.community);
  const [communityValues, setState] = useState(COMMUNITY_VALUES);
  const [bannerColor, setBannerColor] = useState(BANNER_COLOR);
  const [previewMode, setPreviewMode] = useState(false);
  const [errorsValues, setErrorsValues] = useState({});
  const [socialsArray, setSocialsArray] = useState([{ id: "", value: "" }]);
  const [communityTags, setCommunityTags] = useState([{ id: 1, name: "", featured: false, _destroy: false }]);
  const isPersisted = id || editCommunity.id;
  const isDraft = communityValues.status == "draft";
  const canSaveAsDraft = !id;
  const slugOrID = editCommunity.slug || editCommunity.id;
  const listPath = "/manage/communities";
  const showPath = (id) => `/manage/communities/${slugOrID || id}`;

  const onChange = (event) => {
    if (!event.target.name) return;

    const { value, name, checked, type, dataset } = event.target;
    const { conditionfield, importjobindex } = dataset;

    const actualValue = type === "checkbox" ? checked : value;
    if (conditionfield) {
      return setState((state) => {
        state.conditions[name] = value;
        return { ...state };
      });
    }

    if (importjobindex) {
      return setState((state) => {
        state.import_jobs[importjobindex][name] = value;
        return { ...state };
      });
    }
    setState((state) => ({ ...state, [name]: actualValue }));
  };

  const isValid = () => {
    const data = communityData();
    let errors = {};

    if (!data.name) {
      errors.name = ["Community name is required!"];
    }

    if (!data.slug) {
      errors.slug = ["Custom URL is required!"];
    }

    setErrorsValues(errors);
    return !errors.name && !errors.slug;
  };

  const communityData = () => ({
    ...communityValues,
    theme_color: bannerColor,
    community_type: "open",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValid()) {
      throw new Error("invalid data!");
    }

    hideModal();
    const data = getData();

    let action = isPersisted ? updateCommunity : createCommunity;
    const actionText = action == createCommunity ? "created" : "updated";

    return action(data)
      .then(({ community }) => {
        showToast({
          content: `Community has been ${actionText}!`,
          type: "success",
          dismissTime: 5000,
        });

        navigate(showPath(community.id), { replace: true });
      })
      .catch(onError);
  };

  const saveAsDraft = (e) => {
    e.preventDefault();
    if (!isValid()) {
      return false;
    }

    hideModal();
    const data = getData();

    return createDraftCommunity(data)
      .then(({ community }) => {
        showToast({
          content: "This Community has been saved as a draft on the Manage page.",
          type: "success",
          dismissTime: 5000,
        });
        showModal({ modalName: "CommunityEditorModal", communityID: community.id });
      })
      .catch(onError);
  };

  const getData = () => {
    const social_links = getSocialLink();
    const filteredTags = communityTags.filter((tag) => tag.name !== "");
    const community_tags = filteredTags.length > 0 ? filteredTags : [];
    return {
      ...communityData(),
      social_links: JSON.stringify(social_links),
      community_tags: community_tags,
    };
  };

  const getSocialLink = () => {
    const socialData = { ...socialsObject(socialsArray), website: communityValues.website };
    return filterObject(socialData);
  };

  const onClose = () => {
    const { name, slug } = communityValues;
    if (CHECK_FIELDS.every((field) => !communityValues[field])) {
      hideModal();
      navigate(listPath);
    } else {
      let params = {};
      if (name || slug) {
        params = { filledRequired: true };
      }
      showModal({
        modalName: "BeforeYouLeaveCommunityModal",
        ...params,
        onSubmit: canSaveAsDraft ? saveAsDraft : handleSubmit,
        showPath: showPath(),
        listPath,
      });
    }
  };

  const onError = ({ response }) => {
    const content = response.data.data.errors || response.data.message;
    let message = content;
    if (typeof content === "object") {
      message = Object.values(content)[0];
    }
    setErrorsValues(content);
    dangerToast({ content: message, dismissTime: 5000 });
  };

  const onClickPreview = () => {
    const theme_color = bannerColor;
    const community = { ...getData(), theme_color };
    formatImageFiles(community, IMAGE_FIELDS);
    let _commmunityId = communityValues.id;

    if (_commmunityId) {
      if (_commmunityId === "undefinedtemp") {
        _commmunityId = undefined;
      } else {
        _commmunityId = community.id;
      }
    }

    const updatedCommunity = { ...community, id: _commmunityId };

    setPreview({ ...updatedCommunity });
    setPreviewMode(!previewMode);
  };

  useEffect(() => {
    id && getEditCommunity(id);

    return () => {
      if (id) {
        find(id).catch(() => navigate("/"));
      } else {
        reset();
      }
    };
  }, [id]);

  useEffect(() => {
    if (community.isPreview) {
      const previewCommunity = getData();
      previewCommunity.isPreview = false;
      setBannerColor(previewCommunity.theme_color || "#CFCFCF");
      setState(previewCommunity);
    }
  }, [community.isPreview, communityValues.isPreview]);

  useEffect(() => {
    if (editCommunity.id) {
      setBannerColor(editCommunity.theme_color || "#CFCFCF");

      setCommunityTags(editCommunity.community_tags);
      const data = [communityValues, editCommunity]
        .map(removeEmptyFields)
        .reduce((prev, next) => Object.assign({}, prev, next));
      const social_links = typeof data.social_links == "string" ? JSON.parse(data.social_links) : {}; //we get either a json string or an empty object here
      setState({
        ...data,
        theme: data.theme || "thrive",
        social_links,
        admin_emails: [],
      });
      setSocialsArray(formatSocials(social_links));
    }
  }, [editCommunity.slug]);

  return (
    <CommunityEditorContext.Provider
      value={{
        errorsValues,
        isValid,
        handleSubmit,
        onClose,
        communityData,
        communityValues,
        setState,
        bannerColor,
        setBannerColor,
        editCommunity,
        isPersisted,
        isDraft,
        canSaveAsDraft,
        saveAsDraft,
        previewMode,
        setPreviewMode,
        socialsArray,
        communityTags,
        setCommunityTags,
        setSocialsArray,
        onChange,
        onClickPreview,
      }}
    >
      {children}
    </CommunityEditorContext.Provider>
  );
};

export default CommunityEditorProvider;
