import Web3 from "web3";
import ChainConnections from "./ChainConnections";
import coinConfig from "./coinConfig";
import { connectProvider } from "@thrivecoin/thc-auth-js";

const NETWORK_NAME = "polygonMumbai";

const NOOP = () => {};

export const CHAIN_PARAMS = ChainConnections[NETWORK_NAME];

const testNetworkNames = new Map([
  ["polygon", "polygonMumbai"],
  ["ethereum", "goerli"],
]);

const hexToInteger = (value) => "0x" + parseInt(value).toString(16);

export const isOnPolygonHex = (chainID) => chainID == CHAIN_PARAMS.chainId;

export const polygonScanLink = (tx) => `https://mumbai.polygonscan.com/tx/${tx}`;

export const nameToChainID = (networkName)=>  (ChainConnections[networkName] || {}).chainId;

let instance;

export const getInstance = (getProvider = false) => {
  instance = instance || new Web3Wrapper()
  if(getProvider && !instance.provider){
    connectProvider().then((provider)=>{
      instance.setProvider(provider)
    });
  }

  return instance;
};

export default class Web3Wrapper {
  constructor() {
    this.isReady = false;
  }

  setProvider = (provider) => {
    this.provider = provider;
    this.web3 = new Web3(this.provider);
    this.isReady = true;
  };

  removeProvider = async () => {
    if (this.provider.disconnect) {
      // if the provider allows close the session.
      await this.provider.disconnect();
    }
    this.provider = undefined;
    this.web3 = undefined;
    this.isReady = false;
  };
  getUserSignature = async (message, fromAddress) => {
    return this.providerRequest({ method: "personal_sign", params: [message, fromAddress] });
  };

  sendTransaction = (tx) => this.providerRequest({ method: "eth_sendTransaction", params: [tx] });

  onChainChanged = (handler) => {
    const wrapper = () => {
      handler && handler();
      this.provider.removeListener("chainChanged", wrapper);
    };
    this.provider.on("chainChanged", wrapper);
  };

  currentChainHex = () => hexToInteger(this.chainID());

  getChainType = () => {
    const chainHex = this.currentChainHex();

    if (chainHex == ChainConnections.polygonMumbai.chainId) return "polygon";
    if (chainHex == ChainConnections.goerli.chainId) return "ethereum";

    return;
  };

  switchToEtheterumNetwork = () => this.switchNetwork(ChainConnections.goerli.chainId);

  switchToPolygonNetwork = () => this.switchNetwork(ChainConnections.polygonMumbai.chainId);

  switchNetworkAndGetAccounts = (chainID) => this.switchNetwork(chainID).then(this.connectToAccounts);

  getEthereumWallets = () => this.switchNetworkAndGetAccounts(ChainConnections.goerli.chainId);

  getPolygonWallets = () => this.switchNetworkAndGetAccounts(ChainConnections.polygonMumbai.chainId);

  //Only error if actually adding the network, switch doesnt error out.
  addNetwork = (networkName = NETWORK_NAME) => {
    const params = ChainConnections[networkName];

    return this.providerRequest({
      method: "wallet_addEthereumChain",
      params: [params],
    });
  };

  switchNetwork = (chainId = CHAIN_PARAMS.chainId) => {
    const promise = new Promise((resolve, reject) => {
      if (chainId === this.currentChainHex()) {
        resolve(); // If we are switching to the current network, resolve right away
      } else {
        const timeoutID = setTimeout(reject, 5000);
        const doResolve = (action) => (value) => {
          clearTimeout(timeoutID);
          action(value);
        };

        const onSuccess = doResolve(resolve, false);
        const onError = doResolve(reject, true);
        this.provider
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId }],
          })
          .then(onSuccess)
          .catch(onError);
        this.onChainChanged(resolve);
      }
    });

    return promise;
  };

  switchNetworkAsync = (networkName) => {
    const  chainId = nameToChainID(networkName)
    return this.switchNetwork(chainId);
  }

  addAndSwitchNetwork = (networkName) => {
    return this.addNetwork(networkName);
  };

  addThriveToken = () => {
    //TODO: remove test network names
    const networkName = testNetworkNames.get(this.getChainType());
    const options = coinConfig[networkName];

    return this.providerRequest({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options,
      },
    });
  };

  bindNetworkChaged = (handler) => {
    this.provider.on("chainChanged", handler);
    return () => this.provider.removeListener("chainChanged", handler);
  };

  bindAccountsChanged = (handler) => {
    this.provider.on("accountsChanged", handler);
    return () => this.provider.removeListener("accountsChanged", handler);
  };

  connectToAccount = (handler) => this.providerRequest({ method: "eth_requestAccounts" }).then(handler);

  getAccounts = (handler) => this.providerRequest({ method: "eth_accounts" }).then(handler);

  detectNetworks = () => this.providerRequest({ method: "eth_chainId" });

  isConnected = (handler) => this.selectedAddress();

  isOnPolygon = (chainID = this.chainID()) => chainID == parseInt(CHAIN_PARAMS.chainId, 16);

  connectToAccounts = (handler) =>
    this.web3.eth
      .getAccounts()
      .then(handler)
      .catch((x) => {
        debugger;
        x;
      });

  providerRequest = (params) => {
    return this.provider.request(params);
  };

  selectedAddress = () => {
    const provider = this.web3?.eth.currentProvider;
    return provider?.accounts ? provider.accounts[0] : provider?.selectedAddress;
  }

  subscribeToProviderEvent = (eventName, handler) => {
    //TODO: find a better alternative/initialization for this object when web3 is not available
    if (!this.web3) {
      return NOOP;
    }

    const provider = this.web3.eth.currentProvider;

    if (provider.on) {
      provider.on(eventName, handler);
      return () => this.unsubscribeToProviderEvent(eventName, handler);
    }

    if (provider.subscribe) {
      const subscription = provider.subscribe(eventName, handler);
      return () => subscription.unsubscribe();
    }
  };
  unsubscribeToProviderEvent = (eventName, handler) => {
    //TODO: find a better alternative/initialization for this object when web3 is not available
    if (!this.web3) {
      return NOOP;
    }

    const provider = this.web3.eth.currentProvider;
    const methodName = provider.removeListener ? "removeListener" : "off";
    provider[methodName](eventName, handler);
  };

  subscribeToChainEvent = (eventName, handler) => this.web3.eth.subscribe(eventName, handler);

  isOnNamedChain = (chainName) =>{
    const config = ChainConnections[chainName];
    return parseInt(config.chainId) == this.chainID();
  }

  chainID = ()=> this.provider.chainId || this.provider.networkVersion;

}
