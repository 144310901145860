import BACKGROUND_IMAGE from "@assets/season_background.svg";

const useColors = (season) => {
  const isCustomTheme = season.community_theme === "custom";
  const hasBackgroundImage = isCustomTheme && !!season.community_header_background_url;

  const CONFIGS = {
    thrive: {
      color: "thtext",
      accentColor: "02Primary500",
      background: "thbackground",
      headerGradient: "thgradient",
      headerSurface: "thsurfaces",
      completedColor: "03Primary500",
      tabColor: "01Primary0",
      tabBgColor: "03Primary500",
      topBackground: `url(${BACKGROUND_IMAGE})`,
    },
    custom: {
      color: "cthtext",
      accentColor: season.community_accent_color,
      background: "cthbackground",
      headerGradient: "cthgradient",
      headerSurface: "cthsurfaces",
      completedColor: "02Primary0",
      tabColor: "02Primary0",
      tabBgColor: season.community_accent_color,
      topBackground: hasBackgroundImage
        ? `url(${season.community_header_background_url})`
        : season.community_header_background_color,
    },
  };
  const theme = CONFIGS[season?.community_theme || "thrive"];

  return { theme, isCustomTheme, hasBackgroundImage };
};

export default useColors;
