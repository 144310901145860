import debounce from "@utils/debounce";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useMemo, useState } from "react";

const PERSISTENT_OPTIONS = [
  { value: "all", label: "All", selected: true },
  { value: "favorites", label: "Favorites" },
  { value: "recent", label: "Recent", addLine: true },
];

const formatTagOptions = (tags, onClick) => {
  return tags.map((tag) => ({
    id: tag.id,
    value: tag.id,
    label: tag.name,
    featured: tag.featured,
    onClick,
  }));
};

const useTagFilters = (community_id, filterFN) => {
  const { getCommunityTagsRequest } = useStoreActions((actions) => actions.tags);
  const { byCommunityID } = useStoreState((state) => state.tags);
  const tags = byCommunityID(community_id);
  const [allTags, setTags] = useState([]);
  const [persistentOptions, setPersistentOptions] = useState([]);
  const [community_tag_ids, setSelected] = useState([]);
  const featuredTags = allTags.filter(({ featured }) => featured);
  const [text, setText] = useState(null);
  const [sortDirection, setSortDirection] = useState();
  const [sortType, setSortType] = useState();
  const [sortValue, setSortValue] = useState(null);

  const onItemClick = (event) => {
    const value = event.value || event.target.dataset.value;
    setPersistentOptions((prev) => {
      const newOptions = [...prev];
      newOptions.forEach((option) => (option.selected = option.value == value));
      return newOptions;
    });
  };

  const onTagClick = (event) => {
    const value = event.value || event.target.dataset.value;
    setSelected((ids) => {
      const toAssign = ids.includes(value) ? undefined : value;
      allTags.forEach((item) => (item.selected = item.value == value));
      setTags((tags) =>
        tags.map((tag) => {
          tag.selected = tag.value == toAssign;
          return tag;
        })
      );
      setSelected([toAssign]);
    });
  };

  const onTextChange = useMemo(() => {
    const onchange = (text) => {
      setText(text);
    };

    return debounce(onchange, 3000);
  }, []);

  const paginatedSearch = async (page = 1) => {
    const selectedState = persistentOptions.find(({ selected }) => selected)?.value;
    filterFN(community_tag_ids, text, selectedState, sortDirection, sortType, page);
  };

  useEffect(() => {
    const options = [...PERSISTENT_OPTIONS];
    options.forEach((option) => {
      option.onClick = onItemClick;
    });
    setPersistentOptions(options);
  }, []);

  useEffect(() => {
    getCommunityTagsRequest(community_id);
  }, []);

  useEffect(() => {
    if (tags.length > 0) {
      const formattedTags = [...formatTagOptions(tags, onTagClick)];
      setTags(formattedTags);
    }
  }, [tags.length]);

  useEffect(() => {
    const selectedState = persistentOptions.find(({ selected }) => selected)?.value;
    if (selectedState || text || community_tag_ids.length) {
      filterFN(community_tag_ids, text, selectedState, sortDirection, sortType);
    }
  }, [text, community_tag_ids, persistentOptions, sortDirection, sortType, sortValue]);

  return {
    text,
    paginatedSearch,
    community_tag_ids,
    persistentOptions,
    onTextChange,
    tagOptions: featuredTags,
    sortDirection,
    sortType,
    sortValue,
    /////setters
    setText,
    setSortValue,
    setSortType,
    setSortDirection,
    onItemClick,
    onTagClick,
  };
};

export default useTagFilters;
