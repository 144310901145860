import { Box, breakpoints, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";

const CancelDonationModal = ({ hideModal, seasonId }) => {
  const [loading, setLoading] = useState(false);
  const { cancelDonation, getSeasonDetails } = useStoreActions((actions) => actions.season);
  const { isMobile } = useStoreState((state) => state.media);

  const modalRadius = isMobile ? "24px !important" : "12px !important";

  const onCancel = () => {
    setLoading(true);
    cancelDonation(seasonId).then(() => {
      getSeasonDetails(seasonId);
      hideModal();
      hideModal();
    });
  };

  return (
    <Modal
      maxWidth="684px"
      open
      buttonCircle
      leaf={!isMobile}
      onClose={hideModal}
      borderRadius={modalRadius}
      paddingBody={isMobile && "48px 50px 140px"}
    >
      <Content color="02Primary500">
        <Text typography="heading/large" textAlign="center" spacing="mb-5">
          Cancel your donation?
        </Text>
        <Text extraBold alignSelf="center" textAlign="center" typography="body/large" spacing="mb-5">
          Are you sure? When you choose to cancel, your will need to claim your earnings at the end of the season.
        </Text>
        <Button
          bold
          size="lg"
          borderRadius="40px"
          kind="outline"
          variant="02Primary500"
          color="02Primary500"
          spacing="mb-5"
          disabled={loading}
          onClick={hideModal}
          modal={true}
        >
          No, nevermind
        </Button>
        <Button
          bold
          size="lg"
          borderRadius="40px"
          kind="outline"
          variant="error"
          color="02Primary500"
          loading={loading}
          onClick={onCancel}
          modal={true}
        >
          Yes, Cancel Donation
        </Button>
      </Content>
    </Modal>
  );
};

const Content = styled(Box)`
  padding: 48px 90px 64px;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.mobile} {
    padding: 0;
  }
`;

export default CancelDonationModal;
