import { Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import useWeb3 from "../../hooks/useWeb3";
import { PaddedButton } from "../common";

const AddTokensStep = ({ afterClose, closeModal }) => {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const disabled = !(selected.includes("ethereum") && selected.includes("polygon"));
  const { addPolygonToken, addEthereumToken, providerName } = useWeb3();
  const wrapper = (callback, chain) => () => {
    return callback().then(() => {
      setSelected((old) => [...old, chain]);
    });
  };

  const _WaddPolygonToken = wrapper(addPolygonToken, "polygon");
  const _WaddEthereumToken = wrapper(addEthereumToken, "ethereum");
  const { isMobile } = useStoreState((state) => state.media);

  const WaddPolygonToken = () => {
    setLoading(true);
    _WaddPolygonToken().finally(() => setLoading(false));
  };
  const WaddEthereumToken = () => {
    setLoading(true);
    _WaddEthereumToken().finally(() => setLoading(false));
  };

  const NOT_SELECTED = {
    leftIcon: !isMobile ? "plus-circle" : "",
    color: "01Primary0",
    bgColor: "#7EC0CE",
  };

  const SELECTED = {
    leftIcon: !isMobile ? "checked-circle" : "",
    color: "success500",
    variant: "success100",
  };
  const styleConfig = (network) => (selected.includes(network) ? SELECTED : NOT_SELECTED);

  const goNext = () => {
    afterClose && afterClose();
    closeModal();
  };

  useVirtualPageView({ pathname: "/setup/add-tokens" });

  return (
    <>
      <Text type="h4" fontFamily="Outfit" bold spacing="mb-6">
        See the tokens you’ve received
      </Text>

      <Text spacing="mb-6">To see ThriveCoin tokens in {providerName}, use the buttons below</Text>

      <PaddedButton
        loading={loading}
        spacing="mb-4"
        {...styleConfig("polygon")}
        leftIcon={!isMobile && "plus-circle"}
        onClick={WaddPolygonToken}
        modal={true}
      >
        ADD TOKEN TO MUMBAI NETWORK (POLYGON)
      </PaddedButton>

      <PaddedButton loading={loading} spacing="mb-4" {...styleConfig("ethereum")} onClick={WaddEthereumToken} modal={true}>
        ADD TOKEN TO GOERLI NETWORK (ETHEREUM)
      </PaddedButton>

      <PaddedButton spacing="mb-4" onClick={goNext} disabled={disabled} modal={true}>
        ALL DONE!
      </PaddedButton>
    </>
  );
};

AddTokensStep.FooterPercent = 100;

export default AddTokensStep;
