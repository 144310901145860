import { Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { STEP_INDEXES } from ".";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import useWeb3 from "../../hooks/useWeb3";
import { PaddedButton } from "../common";

const CODE_ERROR = {
  "-32002": "You have a pending transaction on metamask.",
  4001: "Please accept the metamask connection request.",
  default: "Your address is already in use, please switch your address and try again!",
};

const SetUpMetamaskStep = ({ goToStep }) => {
  const { createUserWallets, hasWeb3Provider } = useWeb3();
  const [loading, setLoading] = useState(false);
  const walletChains = useStoreState((state) => state.user.walletChains);
  const { dangerToast } = useStoreActions((actions) => actions.toasts);
  const user = useStoreState((state) => state.user.user);
  const missingMetamask = hasWeb3Provider === false;
  const hasPolygon = walletChains.includes("polygon");
  const hasEthereum = walletChains.includes("ethereum");
  const goToInstall = () => window.open("https://metamask.io/", "_blank", "noopener,noreferrer,resizable");

  const connectToWebsite = () => {
    const chains = [];
    setLoading(true);
    !hasPolygon && chains.push("polygon");
    !hasEthereum && chains.push("ethereum");

    createUserWallets(user.id, chains)
      .then(() => {
        goToStep(STEP_INDEXES.ConfigurePolygonStep);
      })
      .catch((data) => {
        const content = CODE_ERROR[data.code] || CODE_ERROR.default;
        dangerToast({ content: content });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (hasPolygon && hasEthereum) {
      goToStep(STEP_INDEXES.ConfigurePolygonStep);
    }
  }, [hasPolygon, hasEthereum]);

  useVirtualPageView({ pathname: "/setup/install-connect" });

  return (
    <>
      <Text type="h4" fontFamily="Outfit" bold color="02Primary500">
        Install and connect MetaMask
      </Text>
      <Text spacing="mb-4+3" color="02Primary500">
        MetaMask is a decentralized wallet provider used by over 20 million people. Connecting to MetaMask allows our
        app to send on-chain rewards to your account.
      </Text>
      <PaddedButton
        onClick={goToInstall}
        spacing="mb-4"
        kind="outline"
        variant="hover"
        color={hasWeb3Provider ? "hover" : ""}
        disabled={hasWeb3Provider}
        modal={true}
      >
        Download and install MetaMask
      </PaddedButton>
      <PaddedButton
        spacing="mb-5"
        loading={loading}
        variant="hover"
        color={loading ? "#C3C4D4" : ""}
        onClick={connectToWebsite}
        disabled={missingMetamask}
        modal={true}
      >
        Connect MetaMask
      </PaddedButton>
    </>
  );
};

SetUpMetamaskStep.FooterPercent = 33;

export default SetUpMetamaskStep;
