import { Box, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const PublishModal = ({ hideModal, doPublish, editPath, header, text, btnText, direction = "column" }) => {
  const [loading, setLoading] = useState(false);
  const onClick = (e) => {
    setLoading(true);
    return doPublish(e)
      .then(hideModal)
      .finally(() => setLoading(false));
  };

  return (
    <Modal open leaf maxWidth="680px" onClose={hideModal}>
      <Text bold textAlign="center" color="02Primary700" lHeight="30px" spacing="mb-5 px-8" typography="heading/medium">
        {header}
      </Text>
      <Text spacing="mb-6" color="02Primary500" typography="body/medium" align="center">
        {text}
      </Text>
      <Box flex gap="1rem" spacing="mx-6+2" direction={direction}>
        <Button loading={loading} grow="1" borderRadius="40px" bold bgColor="primary700" onClick={onClick} modal={true}>
          {btnText}
        </Button>

        {editPath && (
          <StyledLink to={editPath}>
            <Button
              loading={loading}
              borderRadius="40px"
              bold
              kind="outline"
              borderColor="03Secondary500"
              color="02Primary500"
              onClick={hideModal}
              modal={true}
            >
              Keep editing
            </Button>
          </StyledLink>
        )}

        <Button
          bold
          grow="2"
          loading={loading}
          borderRadius="40px"
          kind="outline"
          borderColor="02PrimaryGrey300"
          color="02Primary500"
          onClick={hideModal}
          modal={true}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export const PublishCommunityModal = (props) => {
  const { publishCommunityRequest } = useStoreActions((actions) => actions.community);
  const { managedCommunities } = useStoreState((state) => state.community);

  const doPublish = () => {
    const community = managedCommunities.find(({ id }) => id == props.id);
    return publishCommunityRequest(community).then(props.callBack);
  };

  return (
    <PublishModal
      {...props}
      doPublish={doPublish}
      btnText="Publish Community"
      header="Are you sure you want to publish this Community?"
      text="The Community is missing additional details that will make it more engaging and inviting to visitors. Once published, the Community will be fully public."
    />
  );
};

export const PublishSeasonModal = ({ id, hideModal, callBack }) => {
  const { publishSeasonRequest } = useStoreActions((actions) => actions.season);
  const doPublish = () => publishSeasonRequest(id).then(callBack);

  return (
    <PublishModal
      hideModal={hideModal}
      doPublish={doPublish}
      btnText="Publish Season"
      header="Publish Season Now?"
      direction="row"
      text="By publishing this season the season start date will auto-set as today."
    />
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
  display: contents;
`;
