import { Box, Button, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import moment from "moment";
import styled from "styled-components";

export const PendingContributionList = ({ contributions }) => {
  const { showModal } = useStoreActions((actions) => actions.modals);

  const manageModal = (
    event,
    isAccepting,
    message,
    username,
    verification_code,
    activity_name,
    conditions,
    reviewer_instructions,
    submission_urls
  ) =>
    showModal({
      modalName: "ManagePendingContributionModal",
      isAccepting,
      id: event.currentTarget.dataset.id,
      contribution_claim_id: event.currentTarget.dataset.contribution_claim_id,
      message,
      username,
      verification_code,
      activity_name,
      conditions,
      reviewer_instructions,
      submission_urls,
    });

  const reject = (event, contribution_claim_id) => manageModal(event, false, contribution_claim_id);
  const accept = (
    event,
    message,
    contribution_claim_id,
    username,
    verification_code,
    activity_name,
    conditions,
    reviewer_instructions,
    submission_urls
  ) =>
    manageModal(
      event,
      true,
      message,
      contribution_claim_id,
      username,
      verification_code,
      activity_name,
      conditions,
      reviewer_instructions,
      submission_urls
    );

  const items = contributions.map((item) => (
    <ContributionRow
      key={item.id}
      username={item.username}
      activity_name={item.activity_name}
      claim_date={item.claim_date}
      auto_approval_time={item.auto_approval_time}
      id={item.id}
      reject={reject}
      accept={accept}
      activity_id={item.activity_id}
      contribution_claim_id={item.id}
      message={item.message}
      verification_code={item.verification_code}
      conditions={item.conditions}
      reviewer_instructions={item.reviewer_instructions}
      submission_urls={item.submission_urls}
    />
  ));

  return (
    <ListContainer>
      <tbody>{items}</tbody>
    </ListContainer>
  );
};

export const ContributionRow = ({
  id,
  username,
  activity_name,
  message,
  claim_date,
  auto_approval_time,
  reject,
  accept,
  activity_id,
  contribution_claim_id,
  verification_code,
  conditions,
  reviewer_instructions,
  submission_urls,
}) => {
  const dataProps = {
    "data-id": activity_id,
    "data-contribution_claim_id": contribution_claim_id,
  };
  let formattedUsername = username;

  if (username.length > 10) {
    const leftName = username.substring(0, 5);
    const rightName = username.substring(username.length - 4, username.length);
    formattedUsername = leftName + "..." + rightName;
  }

  return (
    <tr>
      <td>
        <Box>
          <Text extraBold>{activity_name}</Text>
          <Text>{formattedUsername}</Text>
        </Box>
      </td>

      <td>
        <Box>
          <Text extraBold>{moment(claim_date).format("DD MMM, YYYY | hh:mm A")}</Text>
        </Box>
      </td>
      <td>
        {message && (
          <Box
            {...dataProps}
            pointer
            onClick={(event) =>
              accept(
                event,
                message,
                username,
                verification_code,
                activity_name,
                conditions,
                reviewer_instructions,
                submission_urls
              )
            }
          >
            <StyledText bold color="accent400" flex={1} alignItems="center">
              <IconSvg width="14px" icon="mail" spacing="mr-2" />
              Read Message
            </StyledText>
          </Box>
        )}
      </td>

      <td width="350px">
        <Box flex alignItems="center" gap="1rem">
          <Button
            borderRadius="48px"
            kind="outline"
            variant="error"
            bold
            {...dataProps}
            onClick={(event) => reject(event)}
          >
            Reject
          </Button>
          <StyledButton
            bold
            borderRadius="48px"
            kind="outline"
            color="#7CAC65"
            borderColor="#7CAC65"
            {...dataProps}
            onClick={(event) =>
              accept(
                event,
                message,
                username,
                verification_code,
                activity_name,
                conditions,
                reviewer_instructions,
                submission_urls
              )
            }
          >
            Accept contribution
          </StyledButton>
        </Box>
      </td>
    </tr>
  );
};

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
`;

export const ListContainer = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  tr {
    td:first-child {
      border-top-left-radius: 4px;
      padding: 18px 0 18px 24px;
    }

    td:last-child {
      border-top-right-radius: 4px;
      padding: 18px 0 18px 0;
    }
  }

  tr {
    background-color: ${({ theme }) => theme.colors["01Primary0"]};
  }
`;

const StyledButton = styled(Button)`
  white-space: nowrap;
  margin-right: 2rem;
`;

export default PendingContributionList;
