import { Box, breakpoints, IconSvg, Input, Text } from "@thrivecoin/ui-components";
import truncateText from "@utils/truncateText";
import { referralLink } from "@utils/UrlHelpers";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";
import { DEFAULT_PROFILE_IMAGE } from "../../Constants";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { CornerButton, StyledAvatar, TopWrapper } from "./common";

const TopPart = ({ showEdit, profileData, isPublicProfile }) => {
  const { isMobile, isDesktop } = useStoreState((state) => state.media);
  const { referral_code, address, image_url, username, description, verification_code } = profileData;
  const link = referralLink(referral_code);
  const onCopyClipboard = useCopyToClipboard(link, "Your referral URL has been copied.");
  const onCopyAddress = useCopyToClipboard(address, "Your address has been copied.");
  const onCopyCode = useCopyToClipboard(verification_code, "Your code has been copied.");
  const image = image_url || DEFAULT_PROFILE_IMAGE;
  const handleEdit = () => showEdit(true);

  return (
    <TopWrapper header isPublic={isPublicProfile} description={description}>
      <StyledAvatar src={image} alt="user" />

      <RightBox>
        <Box flex justifyContent="space-between" minWidth="100%">
          <Username
            typography={isDesktop ? "heading/x-large" : "heading/large"}
            color="01Primary700"
            spacing="mb-4"
            bold
          >
            {username}
          </Username>

          {!isPublicProfile && (
            <EditButton onClick={handleEdit}>
              <IconSvg width="16px" icon="pen-outline" />
            </EditButton>
          )}
        </Box>

        <Paragraph typography="body/medium" bold color="01Primary700">
          {description}
        </Paragraph>

        <CopiesBox isPublic={isPublicProfile} flex gap="16px">
          <CopyWrapper isPublic={isPublicProfile} onClick={onCopyAddress}>
            <Text color="01Primary700" typography="body/small">
              Wallet Address:
            </Text>
            <Text bold>{truncateText(address, 6)}</Text>
            <IconBox flex gap="10px" alignItems="center">
              <IconSvg width="14px" color="01Primary700" icon="copy" />
            </IconBox>
          </CopyWrapper>
          {!isPublicProfile && (
            <CopyWrapper onClick={onCopyCode}>
              <Text color="01Primary700" typography="body/small">
                Personal Code:
              </Text>
              <Text bold>{truncateText(verification_code, 6)}</Text>
              <IconBox flex gap="10px" alignItems="center">
                <IconSvg width="14px" color="01Primary700" icon="copy" />
              </IconBox>
            </CopyWrapper>
          )}
        </CopiesBox>
        {!isPublicProfile && (
          <CopyWrapperBox spacing="mt-4" md-spacing="mt-0" flex alignItems="center" onClick={onCopyClipboard} relative>
            <StyledInputCopy defaultValue={link} bold readOnly />
            <IconBoxCopy flex alignItems="center" color="link">
              <VerticalLine />
              <IconSvg width="16px" icon="copy" />
              {!isMobile && (
                <Text extraBold typography="body/medium" spacing="ml-2">
                  Copy referral URL
                </Text>
              )}
            </IconBoxCopy>
          </CopyWrapperBox>
        )}
      </RightBox>
    </TopWrapper>
  );
};

const EditButton = styled(CornerButton)`
  position: initial;
`;

const CopyWrapper = styled(Box)`
  border-radius: 4px;
  height: 42px;
  ${({ isPublic }) => !isPublic && "flex: 1;"}
  cursor: pointer;
  padding: 0 16px;
  ${({ isPublic }) => isPublic && "padding-right: 32px;"}
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
  background: ${({ theme }) => theme.colors["01Primary110"]};
  border: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
 
  &:hover {
     border: 1px solid ${({ theme }) => theme.colors["inputHovers"]};
  }
  @media ${breakpoints.tablet} {
    padding: 0 8px;
    ${({ isPublic }) => isPublic && "padding-right: 32px;"}
    gap: 4px;
  }

  @media ${breakpoints.mobile} {
    flex: unset;
  }
`;

const StyledInput = styled(Input)`
  & input {
    white-space: nowrap;
    overflow: hidden;
    height: 32px;
    border-radius: 4px;
    padding: 4px 30px 4px 10px;
    border: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
    &:disabled {
      opacity: 1;
      color: ${({ theme }) => theme.colors["01Primary700"]};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
  }
`;

const IconBox = styled(Box)`
  position: absolute;
  right: 9px;
  cursor: pointer;
`;

const Paragraph = styled(Text)`
  margin: 0 0 24px;
  max-width: 520px;
  @media ${breakpoints.mobile} {
    margin-bottom: 24px;
  }
`;

const CopyWrapperBox = styled(Box)`
  width: 100%;
  cursor: pointer;
  @media ${breakpoints.tablet} {
    bottom: 24px;
    right: unset;
    position: absolute;
    left: 24px;
    right: 24px;
    max-width: 505px;
  }
`;

const StyledInputCopy = styled(Input)`
  & input {
    max-height: 44px;
    padding-right: 117px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 12px 210px 12px 16px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors["01Primary100"]};
    background: ${({ theme }) => theme.colors["01Primary110"]};
    @media ${breakpoints.mobile} {
      padding: 12px 65px 12px 16px;
    }
  }
`;

const IconBoxCopy = styled(Box)`
  position: absolute;
  right: 20px;
  cursor: pointer;
  &::before {
    content: "";
    width: 1px;
    height: 12px;
    background-color: ${({ theme }) => theme.colors.secondary200};
    position: absolute;
    left: -21.33px;
  }
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 12px;
  background: ${({ theme }) => theme.colors["01Primary700"]};
  margin-right: 16px;
`;

const RightBox = styled(Box)`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  @media ${breakpoints.mobile} {
    min-height: unset;
    margin-top: auto;
  }
`;

const Username = styled(Text)`
  word-break: break-all;
  padding-top: 14px;
  padding-right: 36px;
  @media ${breakpoints.tablet} {
    padding-top: 31px;
  }
  @media ${breakpoints.mobile} {
    padding-right: 46px;
    padding-top: unset;
  }
`;

const CopiesBox = styled(Box)`
  margin-top: auto;

  @media ${breakpoints.tablet} {
    bottom: ${({ isPublic }) => (isPublic ? "24px" : "84px")};
    right: unset;
    position: absolute;
    left: 24px;
    right: 24px;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

export default TopPart;
