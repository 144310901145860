import { Button, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import useClaimInApp from "./useClaimInApp.js";

const ChooseMethodStep = ({ goToStep, reward_id, closeModal, afterClose }) => {
  const goToSetMetamask = () => goToStep(STEP_INDEXES.SetUpMetamaskStep);
  const inAppPayment = useClaimInApp(reward_id, closeModal, afterClose);

  useVirtualPageView({ pathname: "/setup/how-receive" });

  return (
    <>
      <Header>Choose how to receive your reward</Header>
      <Paragraph>
        To receive your reward on the blockchain you will need to take a couple additional steps. This will only be
        required once.
      </Paragraph>
      <Button
        bold={+true}
        borderRadius="50px"
        bgColor="primary700"
        kind="solid"
        heightSize="56px"
        onClick={goToSetMetamask}
        widthFull="100%"
        spacing="mb-4"
        modal={true}
      >
        Receive on blockchain
      </Button>
      <Button
        bold={+true}
        borderRadius="50px"
        heightSize="56px"
        onClick={inAppPayment}
        widthFull="100%"
        kind="outline"
        color="02Primary700"
        borderColor="secondary200"
        spacing="mb-5"
        modal={true}
      >
        Receive in app
      </Button>
      <Text color="secondary400">
        Alternatively, you can receive your reward in app, with no extra steps. Your reward will work identically, but
        not be recorded on the blockchain.
      </Text>
    </>
  );
};

const Header = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: ${({ theme }) => theme.colors["02Primary700"]};
  margin-bottom: 40px;
`;

const Paragraph = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary400};
  margin-bottom: 30px;
`;

export default ChooseMethodStep;
