import { Box, Button, Modal, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const PublishChallengeConfirmationModal = ({ hideModal, onProceed }) => {
  return (
    <Modal open leaf maxWidth="680px" onClose={hideModal}>
      <Text
        bold
        textAlign="center"
        color="02Primary700"
        size="24px"
        lHeight="30px"
        spacing="mt-5 mx-6+2"
        fontFamily="Outfit"
      >
        Are you sure you want to publish this Challenge?
      </Text>
      <List>
        <li>
          <Text spacing="mb-5">Published Challenges will be immediately available to participate in.</Text>
        </li>
        <li>
          <Text>
            Challenge rewards cannot be modified after the Challenge is published. See our <Link>Help Doc</Link> for
            more info.
          </Text>
        </li>
      </List>
      <Box flex gap="1rem" spacing="mx-6+2">
        <Button
          bold
          size="lg"
          height="53px"
          kind="outline"
          borderRadius="40px"
          width="252px"
          color="secondary400"
          borderColor="#BDBDBD"
          onClick={hideModal}
          modal={true}
        >
          Cancel
        </Button>
        <Button bold size="lg" height="53px" borderRadius="40px" grow={1} bgColor="primary700" onClick={onProceed} modal={true}>
          Proceed to Payment
        </Button>
      </Box>
    </Modal>
  );
};

const List = styled.ul`
  margin: 2rem 1.5rem;
  color: ${({ theme }) => theme.colors["02Primary700"]};
`;

const Link = styled.a`
  cursor: pointer;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.hover};
`;

export default PublishChallengeConfirmationModal;
