/* eslint-disable max-len */
import connectToServer from "../consumer";

class ChannelManager {
  constructor(toastActions) {
    this.toastActions = toastActions;
    this.received = ({ notification }) => {
      const { config } = notification;
      const methodName = config.type;

      this[methodName] && this[methodName](config);
    };
  }
  reward_received({ reward_amount, toast_message }) {
    const amount = parseFloat(reward_amount);
    const content = `Congratulations! You recently received another ${amount} $THRIVE for your contributions!`;
    this.toastActions.celebratoryToast({ content });
  }
}

const connectToChannel = (id, toastActions) => {
  let consumer = connectToServer();
  const channelParams = { channel: "UserChannel", id, token: localStorage.getItem("token") };
  const manager = new ChannelManager(toastActions);

  return consumer.subscriptions.create(channelParams, manager);
};

export default connectToChannel;
