import PersonPc from "@assets/about-us/person-pc-ground.svg";
import Tree from "@assets/about-us/tree.svg";
import { breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const AboutSection = ({ executeScroll }) => {
  return (
    <Wrapper>
      <Top>
        <StyledImage src={Tree} />
      </Top>
      <PersonPcImg src={PersonPc} />
      <Bottom>
        <Header>About ThriveCoin</Header>
        <Paragraph>
          ThriveCoin was founded in September 2021. We are committed to helping everyone in the world earn crypto by
          supporting the communities we love - no matter who we are, where we are, what we look like, or what our
          background is!
        </Paragraph>
        <StyledArrowWrapper onClick={executeScroll}>
          <IconSvg width="12px" icon="arrow-down-long" />
        </StyledArrowWrapper>
      </Bottom>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 113px;
  position: relative;
  @media ${breakpoints.tablet} {
    margin-bottom: 32px;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 30px;
  }
`;

const Top = styled.div`
  border-radius: 25px 25px 0px 0px;
  background: #3b5277;
  min-height: 325px;
  display: flex;
  align-items: end;
  @media ${breakpoints.mobile} {
    min-height: 273px;
  }
`;
const Bottom = styled.div`
  background: linear-gradient(0deg, rgba(156, 203, 164, 0) 0%, #8dcf9f 100%);
  min-height: 244px;
`;

const StyledImage = styled.img`
  margin: 0 auto;
  @media ${breakpoints.mobile} {
    max-width: 255px;
  }
`;
const PersonPcImg = styled.img`
  position: absolute;
  top: 250px;
  z-index: 99;
  left: 125px;
  @media ${breakpoints.tablet} {
    left: 100px;
  }
  @media ${breakpoints.mobile} {
    max-width: 245px;
    left: 50%;
    top: 230px;
    transform: translateX(-50%);
  }
`;

const Header = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 34px;
  line-height: 42px;
  color: ${({ theme }) => theme.colors["01Primary0"]};
  margin: 36px 0 32px 0;
  text-align: center;
  @media ${breakpoints.mobile} {
    margin: 92px 0 32px 0;
  }
`;

const Paragraph = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors["02Primary700"]};
  max-width: 917px;
  text-align: center;
  margin: 0 auto 42px auto;
  @media ${breakpoints.tablet} {
    max-width: 561px;
    margin: 0 auto 39px auto;
  }
  @media ${breakpoints.mobile} {
    padding: 0 32px;
    margin: 0 auto 55px auto;
    font-size: 16px;
    max-width: 440px;
  }
`;

const StyledArrowWrapper = styled.a`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 1px solid #dadada;
  background: ${({ theme }) => theme.colors["01Primary0"]};
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default AboutSection;
