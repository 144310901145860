import { Box, breakpoints, Button, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useWeb3 from "../../../hooks/useWeb3";
import useColors from "../../useColors";
import ToggleButton from "./ToggleButton";
import useAuthentication from "../../../hooks/useAuthentication";

const RAFFLE_INSTRUCTIONS = [
  "Connect your wallet",
  "Make any of the contributions listed below",
  "Every contribution earns you raffle entries, ThriveCoin tokens ($THRIVE), and on-chain thanks",
  "The more you contribute, the higher your chances of winning!",
];

const TOKEN_INSTRUCTIONS = [
  "Connect your wallet",
  "Make any of the contributions listed below",
  "Every contribution earns you tokens and on-chain thank you notes",
  // "Claim your rewards at the end of each season!",
];

const ActionButton = ({ confirmed, loading, isMobile, connect, scrollToBody, color, contributed, borderColor }) => {
  return false ? (
    <ConnectBtn
      extraBold={+true}
      color={color}
      borderColor="02Primary0"
      hoverBackground="02PrimaryGrey100"
      hoverBorderColor="02PrimaryGrey100"
      hoverColor={color}
      borderRadius="40px"
      bgColor="02Primary0"
      height="56px"
      onClick={connect}
      loading={loading}
      spacing="mt-6+2"
      md-spacing="mt-6+1"
      sm-spacing="mt-6"
      width={isMobile ? "100%" : "240px"}
    >
      Connect Your Wallet
    </ConnectBtn>
  ) : (
    <StyledButton
      onClick={scrollToBody}
      variant={!contributed && "02Primary0"}
      spacing="mt-6+2"
      md-spacing="mt-6"
      sm-spacing="mt-6"
      borderRadius="40px"
      color={color}
      borderColor={borderColor}
      hoverBackground={contributed ? "transparent" : "02PrimaryGrey50"}
      hoverBorderColor="02PrimaryGrey50"
      heightSize="56px"
      size="lg"
      bold
      kind={contributed ? "outline" : "solid"}
    >
      Discover Ways to Contribute
    </StyledButton>
  );
};

const UncompletedState = ({ season, scrollToBody, seasonDetails }) => {
  const [expanded, setExpanded] = useState(false);
  const { isMobile } = useStoreState((state) => state.media);
  const { authLoading } = useStoreState((state) => state.authentication);
  const { confirmed } = useStoreState((state) => state.user.user);
  const { installMetamask, addNetwork, hasWeb3Provider } = useWeb3();
  const { loginFlow } = useAuthentication();
  const referral_code = useParams().referral_code || sessionStorage.getItem("referral-code");
  const instructions = season.reward_system === "custom_token" ? TOKEN_INSTRUCTIONS : RAFFLE_INSTRUCTIONS;
  const contributed = +seasonDetails.rewards_earned > 0;

  const { theme } = useColors(season);
  const borderColor = !contributed ? "02Primary0" : theme.color;

  const connectWallet = (e) => {
    e.stopPropagation();

    if (!hasWeb3Provider) {
      installMetamask();
    } else {
      loginFlow(referral_code).catch((error) => {
        if (error.code === 4902) {
          addNetwork().then(() => loginFlow(referral_code));
        }
      });
    }
  };

  const toggleExpanded = () => setExpanded((prev) => !prev);

  return (
    <Wrapper bgColor={theme.headerGradient} flex direction="column" color={theme.color} onClick={toggleExpanded}>
      <Box flex alignItems="center">
        <Text typography="heading/small" spacing="mr-a">
          How it Works
        </Text>
        {isMobile && <ToggleButton expanded={expanded} setExpanded={setExpanded} />}
      </Box>
      {(expanded || !isMobile) && (
        <Box flex direction="column" alignItems="flex-start">
          <List accentColor={theme.accentColor}>
            {instructions.map((item, i) => (
              <li key={i}>
                <Text typography="body/small" bold>
                  {item}
                </Text>
              </li>
            ))}
          </List>
          <ActionButton
            confirmed={confirmed}
            loading={authLoading}
            contributed={confirmed && contributed}
            isMobile={isMobile}
            connect={connectWallet}
            scrollToBody={scrollToBody}
            borderColor={borderColor}
            color={contributed ? theme.color : "02Primary500"}
          />
        </Box>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  border-radius: 8px;
  padding: 18px 28px 40px;
  max-width: 418px;
  min-width: 418px;
  box-sizing: border-box;

  @media ${breakpoints.tablet} {
    max-width: unset;
    padding: 16px 24px 36px 24px;
  }

  @media ${breakpoints.mobile} {
    padding: 20px 20px 24px;
    max-width: unset;
    min-width: unset;
  }
`;

const List = styled.ol`
  counter-reset: styled-counter;
  list-style: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 10px;
  & li {
    margin-top: 10px;
    counter-increment: styled-counter;
    position: relative;
    padding-left: 30px;
    padding-right: 32px;

    @media ${breakpoints.tablet} {
      margin-top: 20px;
    }
    @media ${breakpoints.miniTablet} {
      padding-right: 10px;
    }

    &:before {
      content: counter(styled-counter);
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      font-family: Proxima Nova;
      background: ${({ theme, accentColor }) => theme.colors[accentColor] || accentColor};
      color: ${({ theme }) => theme.colors["02Primary0"]};
    }
  }
`;

const ConnectBtn = styled(Button)`
  font-size: 16px;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  align-self: center;

  @media ${breakpoints.belowDesktop} {
    align-self: flex-start;
    width: 100%;
  }

  @media ${breakpoints.mobile} {
    align-self: unset;
  }
`;

export default UncompletedState;
