import Innovate from "@assets/about-us/innovate.svg";
import Value from "@assets/about-us/value-we-recognise.svg";
import WhoWeAre from "@assets/about-us/who-we-are.svg";
import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const ValueRecognize = () => {
  return (
    <ValueWrapper>
      <WrapBoxFirst flex={true} justifyContent={"center"} spacing="mb-4">
        <LeftPart>
          <HeaderTitle color="#8DCF9F">What we are</HeaderTitle>
          <Paragraph>
            We are a Community, a platform, a token economy, and a set of blockchain-based tools – making it easy for
            diverse sets of Communities to recognize their Community members for any value they create in their
            Communities.
          </Paragraph>
        </LeftPart>
        <WhoWeareImg src={WhoWeAre} />
      </WrapBoxFirst>

      <WrapBoxReverse flex={true} justifyContent={"center"}>
        <ValueImg src={Value} />
        <RightPart>
          <HeaderTitle color="#57A1B0">Types of value we recognize</HeaderTitle>

          <WrapperUl>
            <li>Learn how to make your community a better place – earn ThriveCoin!</li>
            <li>Teach other community members useful skills – earn ThriveCoin! </li>
            <li>Introduce new members to your community – earn ThriveCoin! </li>
            <li>Do anything else your community values! – earn ThriveCoin! </li>
          </WrapperUl>
        </RightPart>
      </WrapBoxReverse>

      <WrapBox flex={true} justifyContent={"center"}>
        <LeftPart>
          <HeaderTitle color="#3F5475">Where we innovate in crypto and the blockchain</HeaderTitle>

          <WrapperUl>
            <li>Reimagining how to auto-validate all types of contributions</li>
            <li>Reimagining governance structures to give greater voice to contributors</li>
            <li>Tracking and recording all contributions and contribution-types on-chain </li>
            <li>Creating greater and greater utility associated with tokens and token rewards</li>
          </WrapperUl>
        </LeftPart>
        <InnovateImg src={Innovate} />
      </WrapBox>
    </ValueWrapper>
  );
};

const InnovateImg = styled.img`
  @media ${breakpoints.mobile} {
    max-width: 289px;
  }
`;

const WhoWeareImg = styled.img`
  @media ${breakpoints.mobile} {
    max-width: 284px;
  }
`;

const ValueImg = styled.img`
  @media ${breakpoints.mobile} {
    max-width: 284px;
  }
`;

const WrapBox = styled(Box)`
  gap: 37px;
  @media ${breakpoints.tablet} {
    gap: 83px;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 83px;
  }
`;

const WrapBoxFirst = styled(Box)`
  gap: 121px;
  @media ${breakpoints.tablet} {
    gap: 148px;
  }
  @media ${breakpoints.mobile} {
    gap: 48px;
    flex-direction: column;
    align-items: center;
  }
`;

const WrapBoxReverse = styled(Box)`
  margin-bottom: 5rem;
  gap: 91px;
  @media ${breakpoints.tablet} {
    gap: 41px;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 9rem;
    gap: 41px;
  }
`;

const ValueWrapper = styled.div`
  background: #fff;
  padding: 190px 100px 152px 100px;
  @media ${breakpoints.tablet} {
    padding: 189px 82px 107px 88px;
  }
  @media ${breakpoints.mobile} {
    padding: 59px 4px 114px 4px;
  }
`;

const HeaderTitle = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 34px;
  line-height: 42px;
  margin: 0 0 32px 0px;
  max-width: 490px;
  @media ${breakpoints.mobile} {
    text-align: center;
    font-size: 28px;
    margin: 0 auto 18px auto;
  }
`;

const LeftPart = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  text-align: left;
  margin-top: 70px;
  @media ${breakpoints.mobile} {
    margin: 0;
  }
`;

const RightPart = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  text-align: left;
  margin-top: 90px;
`;

const WrapperUl = styled.ul`
  margin: 0;
  padding-inline-start: 20px;
  max-width: 500px;
  @media ${breakpoints.mobile} {
    font-size: 18px;
  }
`;

const Paragraph = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors["02Primary700"]};
  max-width: 572px;
  @media ${breakpoints.tablet} {
    max-width: 313px;
  }
`;

export default ValueRecognize;
