import styled from "styled-components";
export const WrapperButtons = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt};`}

  hr {
    width: 100%;
  }

  button {
    line-height: 24px;
    width: 100%;
    padding: 16px;
    svg {
      overflow: overlay;
    }
  }
`;