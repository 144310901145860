import useSocialProfilesInfo from "../../hooks/useSocialProfilesInfo";
import { TabWrapper } from "../common";
import ConnectedNetworks from "./ConnectedNetworks";
import ProfileConnectionButtons from "./ProfileConnectionButtons";

const PersonalInformationTab = () => {
  const { ...props } = useSocialProfilesInfo();
  return (
    <TabWrapper>
      <ConnectedNetworks />
      <ProfileConnectionButtons {...props} />
    </TabWrapper>
  );
};

export default PersonalInformationTab;
