import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import styled from "styled-components";

const TokenInfo = ({ executeScrollToken }) => {
  const { isTablet, isMobile } = useStoreState((state) => state.media);
  return (
    <TokenInfoWrapper>
      <WrapperGrid>
        <TokenBox>
          <Title>Token info</Title>
          <TokenBoxInside>
            <TitleWrapper>
              <Labels>Name</Labels>
              <TextStyled>ThriveCoin</TextStyled>
            </TitleWrapper>

            <TitleWrapper>
              <Labels>Symbol</Labels>
              <TextStyled>THRIVE</TextStyled>
            </TitleWrapper>

            <TitleWrapper>
              <Labels>Decimals</Labels>
              <TextStyled>8</TextStyled>
            </TitleWrapper>

            <TitleWrapper>
              <Labels>Total Supply</Labels>
              <TextStyled>8,000,000,000 THRIVE</TextStyled>
            </TitleWrapper>

            <TitleWrapper>
              <Labels>Hard Cap</Labels>
              <TextStyled>8,000,000,000 THRIVE</TextStyled>
            </TitleWrapper>
          </TokenBoxInside>
        </TokenBox>

        <TokenBox>
          <TitleChains>Chains</TitleChains>
          <RightBox flex={true} gap={"10px"} direction="column">
            <BoxTextStyled>
              <LabelText>Goerli Ethereum Testnet</LabelText>
              <TextStyled>https://goerli.etherscan.io</TextStyled>
            </BoxTextStyled>

            <BoxTextStyled>
              <LabelText>Mumbai Polygon Testnet</LabelText>
              <TextStyled>https://mumbai.polygonscan.com</TextStyled>
            </BoxTextStyled>
          </RightBox>
        </TokenBox>
      </WrapperGrid>
      <TitleBox>Initial Tokenomics</TitleBox>
      <WrapperTokensGrid>
        <Tokenomic>
          <Text color="#A9DCE7" size={"18px"} bold={+true} fontFamily="Outfit" lineHeight="24px">
            Treasury
          </Text>
          <Text color="#A9DCE7" size={"18px"} bold={+true} fontFamily="Outfit" lineHeight="24px">
            [for creators and contributors]
          </Text>
          <Text
            color="01Primary0"
            size={"18px"}
            spacing={(isTablet && "pl-8 pr-8") || (isMobile && "pl-9 pr-9")}
            bold={+true}
            fontFamily="Outfit"
            lineHeight="24px"
          >
            4,960,000,000 THRIVE (62%)
          </Text>
        </Tokenomic>

        <Tokenomic>
          <Text color="#A9DCE7" size={"18px"} bold={+true} fontFamily="Outfit" lineHeight="24px">
            ThriveCoin Team and Investors
          </Text>
          <Text
            color="01Primary0"
            size={"18px"}
            spacing={(isTablet && "pl-8 pr-8") || (isMobile && "pl-9 pr-9")}
            bold={+true}
            fontFamily="Outfit"
            lineHeight="24px"
          >
            2,240,000,000 THRIVE (28%)
          </Text>
        </Tokenomic>

        <Tokenomic>
          <Text color="#A9DCE7" size={"18px"} bold={+true} fontFamily="Outfit" lineHeight="24px">
            Future core team,
          </Text>
          <Text color="#A9DCE7" size={"18px"} bold={+true} fontFamily="Outfit" lineHeight="24px">
            contributors, investors, etc{" "}
          </Text>
          <Text
            color="01Primary0"
            size={"18px"}
            spacing={(isTablet && "pl-8 pr-8") || (isMobile && "pl-9 pr-9")}
            bold={+true}
            fontFamily="Outfit"
            lineHeight="24px"
          >
            800,000,000 THRIVE (10%)
          </Text>
        </Tokenomic>
      </WrapperTokensGrid>
      <StyledArrowWrapper onClick={executeScrollToken}>
        <IconSvg width="12px" icon="arrow-down-long" />
      </StyledArrowWrapper>
    </TokenInfoWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 427px;
  @media ${breakpoints.mobile} {
    flex-direction: column;
    width: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`;

const RightBox = styled(Box)`
  @media ${breakpoints.tablet} {
    height: 115px;
    justify-content: space-between;
  }
  @media ${breakpoints.mobile} {
    text-align: center;
    align-items: center;
  }
`;

const WrapperTokensGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(412px, 1fr));
  column-gap: 34px;
  row-gap: 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 1315px;

  @media ${breakpoints.tablet} {
    max-width: 902px;
    grid-template-columns: repeat(auto-fit, minmax(278px, 1fr));
    column-gap: 20px;
  }

  @media ${breakpoints.mobile} {
    max-width: 902px;
    grid-template-columns: repeat(auto-fit, minmax(327px, 1fr));
    column-gap: 16px;
    row-gap: 16px;
  }
`;

const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(628px, 1fr));
  gap: 54px;
  row-gap: 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 1310px;
  @media ${breakpoints.tablet} {
    width: 876px;
    grid-template-columns: repeat(auto-fit, minmax(411px, 1fr));
  }

  @media ${breakpoints.mobile} {
    width: 327px;
    grid-template-columns: repeat(auto-fit, minmax(327px, 1fr));
  }
`;

const TokenInfoWrapper = styled.div`
  background: linear-gradient(180deg, rgba(126, 192, 206, 0) 0%, #7ec0ce 100%);
  border-radius: 0px 0px 25px 25px;
  max-height: 977px;
  padding: 144px 40px 60px 39px;
  @media ${breakpoints.desktop} {
    ${Text} {
      font-size: 20px;
    }
  }

  @media ${breakpoints.mobile} {
    padding: 0 10px 62px 12px;
    max-height: 1362px;
  }
`;

const TokenBoxInside = styled.div`
  height: 128px;
  @media ${breakpoints.mobile} {
    height: 392px;
  }
`;

const TokenBox = styled.div`
  max-height: 318px;
  background: #ffffff;
  border-radius: 25px;
  padding: 38px 10px 43px 115px;
  @media ${breakpoints.tablet} {
    padding: 38px 40px 43px 40px;
  }
  @media ${breakpoints.mobile} {
    max-height: 392px;
    padding: 32px 0 0 0;
    height: 100%;
    text-align: center;
  }
`;

const Title = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 34px;
  line-height: 42px;
  color: #e2857e;
  height: 42px;
  margin-bottom: 42px;
  @media ${breakpoints.mobile} {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 16px;
  }
`;

const TitleChains = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 34px;
  line-height: 42px;
  color: #e2857e;
  height: 42px;
  margin-bottom: 42px;
  @media ${breakpoints.mobile} {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 41px;
  }
`;

const TextStyled = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #33343d;
  @media ${breakpoints.tablet} {
    font-size: 18px;
  }
`;

const Labels = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #33343d;
  width: 113px;
  @media ${breakpoints.tablet} {
    font-size: 18px;
    line-height: 24px;
  }
  @media ${breakpoints.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const LabelText = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #33343d;
  width: 436px;
  @media ${breakpoints.tablet} {
    font-size: 18px;
  }
  @media ${breakpoints.mobile} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const TitleBox = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 34px;
  line-height: 42px;
  color: #33343d;
  margin: 85px 0 65px;
  text-align: center;
  @media ${breakpoints.tablet} {
    margin: 84px 0 65px;
  }
  @media ${breakpoints.mobile} {
    font-size: 28px;
    line-height: 36px;
    margin: 84px 0 26px 0;
  }
`;

const Tokenomic = styled.div`
  height: 164px;
  background: #3b5277;
  border-radius: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media ${breakpoints.mobile} {
    height: 184px;
  }
`;

const StyledArrowWrapper = styled.a`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 1px solid #dadada;
  background: ${({ theme }) => theme.colors["01Primary0"]};
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  cursor: pointer;
  @media ${breakpoints.mobile} {
    display: none;
  }
`;

const BoxTextStyled = styled(Box)`
  @media ${breakpoints.mobile} {
    text-align: center;
    align-items: center;
  }
`;

export default TokenInfo;
