import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const SelectionBox = ({ chain_type, id, balance, address, onClick, selected }) => {
  const isTreassury = chain_type === "virtual";
  const isEthereum = chain_type === "ethereum";
  const isPolygon = chain_type === "polygon";
  const subtitle = `${balance || "0"} $THRIVE`;
  let title = isPolygon ? "POLYGON (MATIC) BASED" : "ETHEREUM (ETH) BASED";
  title = isTreassury ? "IN APP (THRIVECOIN)" : title;
  let icon = isPolygon ? "matic" : "eth";
  icon = isTreassury ? "logo" : icon;
  const leftAddress = address.substring(0, 5);
  const rightAddress = address.substring(address.length - 4, address.length);
  const fee = isEthereum ? "Requires ETH for transaction (gas) fees" : "No transaction fees";

  return (
    <Wrapper
      data-id={id}
      onClick={onClick}
      flex
      alignItems="center"
      spacing="px-5 py-4"
      gap="1rem"
      className={selected && "selected-wrapper"}
    >
      <IconSvg icon={icon} width="35px" height="41px" />
      <Box flex direction="column">
        <Text color="secondary400" size="14px" lHeight="21px">
          {title}
        </Text>
        <Text color="02Primary700" bold size="20px" lHeight="21px" spacing="my-1">
          {subtitle}
        </Text>
        <Text size="14px" color="secondary300">
          {leftAddress}...{rightAddress}
        </Text>
        <FeeWrapper className={isEthereum && "etherum-wrapper"}>
          <Text bold lHeight="21px">
            {fee}
          </Text>
        </FeeWrapper>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.tertiary300};
  cursor: pointer;

  &.selected-wrapper {
    border: 1px solid ${({ theme }) => theme.colors.hover};
    outline: 1px solid ${({ theme }) => theme.colors.hover};
  }
`;

const FeeWrapper = styled(Box)`
  margin-top: 3px;
  font-size: 14px;
  align-self: flex-start;
  border-radius: 4px;
  padding: 2px 7.5px;
  color: ${({ theme }) => theme.colors.success500};
  background-color: ${({ theme }) => theme.colors.success100};

  &.etherum-wrapper {
    padding: 2px 4.5px;
    color: ${({ theme }) => theme.colors.error};
    background-color: ${({ theme }) => theme.colors.destructive100};
  }
`;

export default SelectionBox;
