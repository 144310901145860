import { normalize } from "@utils/socialUtils";
import { useStoreActions, useStoreState } from "easy-peasy";
import useAuthentication from "../../../hooks/useAuthentication";
import useConnectSocialMedias from "../../../hooks/useConnectSocialMedias";
import useWeb3 from "../../../hooks/useWeb3";

const checkSocialMedia = (profiles, type) => profiles.some((obj) => obj.provider === type);

const useWaysToContribute = () => {
  const { installMetamask, hasWeb3Provider } = useWeb3();
  const { loginFlow } = useAuthentication();
  const referral_code = sessionStorage.getItem("referral-code");
  const { user } = useStoreState((state) => state.user);
  const { socialProfiles } = user;
  const { showModal } = useStoreActions((actions) => actions.modals);
  const connectionMethods = useConnectSocialMedias(true);

  const isSocialProfileConnected = (type) => {
    const { provider } = normalize(type) || {};
    return provider ? checkSocialMedia(socialProfiles, provider) : user.confirmed;
  };

  const readMore = (id) => showModal({ modalName: "ActivityDetailsModal", id });

  const onRefresh = () => {};

  const onSignUp = () => (hasWeb3Provider ? loginFlow(referral_code) : installMetamask());

  const manuallyComplete = (activity, callback) =>
    showModal({ modalName: "ConfirmContributionModal", activity, callback });

  const connectSocialMedias = (app) => {
    const { displayName, provider } = normalize(app);

    if (provider.includes("discourse")) {
      return connectionMethods.discourse(displayName, provider);
    }

    if (provider) {
      return connectionMethods[provider]();
    }

    showModal({ modalName: "ConnectSocialMediasModal" });
  };

  return {
    onRefresh,
    onSignUp,
    isSocialProfileConnected,
    readMore,
    connectSocialMedias,
    manuallyComplete,
  };
};

export default useWaysToContribute;
