import rewardFoundImage from "@assets/rewardFoundImage.svg";
import sparklesImg from "@assets/sparkles-img.svg";
import { Box, breakpoints, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FirstThrive from "./FirstThrive";

const RewardFoundModal = ({ hideModal, reward_amount, name }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const fullScreenModal = isMobile;
  const navigate = useNavigate();
  const borderRadius = fullScreenModal ? "0 !important" : "24px 24px 0 0 !important";

  const onShareClick = () => {
    hideModal();
    showModal({ modalName: "ShareSocialMediaModal" });
  };

  const goToContribution = () => {
    const url = `/current_user/profile?tabIndex=1`;

    navigate(url);
    hideModal();
  };

  const footerContent = <Footer hideModal={hideModal} />;

  return (
    <StyledModal
      open
      bgColor="#EDF9EF"
      onClose={hideModal}
      leaf={!isMobile}
      spacing="px-7+6 pt-7+6 pb-6+2"
      footerContent={footerContent}
      overflowY="hidden"
      maxWidth="810px"
      fullScreen={fullScreenModal}
      hideX={fullScreenModal}
      borderRadius={isMobile && borderRadius}
      sm-spacing="px-0 pt-4"
      backText={fullScreenModal && "Back to community"}
      contentHeight={fullScreenModal && "100%"}
    >
      <StyledBox direction="column" textAlign="center">
        <Box sm-spacing="mx-4">
          <SparklesImg src={sparklesImg} alt="sparklesImg" />
          <Text typography="heading/large" spacing="mb-6" sm-spacing="mb-4 mt-6">
            Congratulations! Crypto received!
          </Text>

          <Text typography="body/medium" spacing="mb-5" color="secondary400">
            You recently recieved another <b>reward</b> for your contribution "{name}"!
          </Text>

          <Box flex justifyContent="center" spacing="mb-6">
            <FirstThrive amount={reward_amount} text="You've earned" />
          </Box>

          <Box maxWidth="429px" spacing="mx-a">
            <StyledButton
              borderRadius="40px"
              widthFull
              height="56px"
              variant="hover"
              bold
              spacing="mb-4"
              size="lg"
              onClick={onShareClick}
              modal={true}
            >
              Share With Friends
            </StyledButton>

            <StyledButton
              onClick={goToContribution}
              spacing="mb-8+8+7"
              size="lg"
              bgColor="#EDF9EF"
              color="02Primary500"
              kind="outline"
              borderColor="02Primary200"
              variant="#BDBDBD"
              rightIcon="right-long"
              borderRadius="40px"
              widthFull
              height="56px"
              bold
              modal={true}
            >
              View your contribution
            </StyledButton>
          </Box>
        </Box>
      </StyledBox>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  margin-top: 0;

  @media only screen and (max-height: 990px) {
    transform: translateY(0);
  }
  & > div > div {
    &:not(:last-child) {
      z-index: 9;
    }
  }
`;

const Footer = () => (
  <Box height="132px" bgColor="#81C394">
    <StyledImage src={rewardFoundImage} />
  </Box>
);

const StyledImage = styled.img`
  position: absolute;
  bottom: 73px;
  left: 170px;
  height: 249px;

  @media ${breakpoints.mobile} {
    left: 50%;
    transform: translateX(-50%);
    height: 209.11px;
    bottom: 53px;
  }
`;

const StyledBox = styled(Box)`
  @media ${breakpoints.tablet} {
    height: 100%;
  }

  @media ${breakpoints.mobile} {
    height: 100%;
  }
`;

const StyledButton = styled(Button)`
  line-height: 24px;
  display: flex;
  align-items: center;
  svg {
    width: 20px;
  }
`;

const SparklesImg = styled.img`
  position: absolute;
  top: 24px;
  left: 24px;
  @media ${breakpoints.mobile} {
    display: none;
  }
`;
export default RewardFoundModal;
