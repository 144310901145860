import { normalize } from "@utils/socialUtils";
import styled from "styled-components";
import { SOCIALS_NO_DISCORD } from "../../../../constants";
import { WrapperButtons } from "./common";
import ConnectionButton from "./ConnectionButton";
const ConnectionButtons = ({ canDisconnect, countByApp, showCount, children, ...props }) => {
  const buttons = SOCIALS_NO_DISCORD.map((social) => {
    const config = normalize(social);
    const connected = props[`${social}Connected`];
    const count = countByApp[config.appName];

    return (
      <ConnectionButton
        key={social}
        showCount={showCount}
        canDisconnect={canDisconnect}
        social="discord"
        connected={connected}
        count={count}
        {...config}
      />
    );
  });

  return (
    <StyledWrapperButtons>
      {buttons}
      {children}
    </StyledWrapperButtons>
  );
};

const StyledWrapperButtons = styled(WrapperButtons)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 524px;
  margin: 0 auto;
`;

ConnectionButtons.defaultProps = {
  canDisconnect: true,
  showCount: true,
};

export default ConnectionButtons;
