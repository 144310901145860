import { Box, breakpoints, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";

const CompleteSeasonModal = ({ hideModal, callback, id }) => {
  const { forceComplete } = useStoreActions((actions) => actions.season);
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    setLoading(true);
    forceComplete(id)
      .then(() => {
        callback();
        hideModal();
      })
      .catch(() => setLoading(false));
  };

  return (
    <Modal open onClose={hideModal} leaf={true} maxWidth="680px" spacing="px-8 pt-7">
      <Wrapper>
        <Text fontFamily="Outfit" bold type="header" size="28px" lineHeight="36px">
          Are you sure you want to complete this season?
        </Text>
        <Text color="02Primary700" spacing="mb-6 mt-3">
          New contributions to this community will no longer be counted as part of this season.
        </Text>
      </Wrapper>
      <ButtonsWrapper flex gap="1rem">
        <Button
          size="lg"
          borderRadius="56px"
          bold
          className="cancel-btn"
          onClick={hideModal}
          kind="outline"
          color="02Primary500"
          variant="03Primary500"
          grow="1"
          modal={true}
        >
          No, cancel
        </Button>
        <Button
          size="lg"
          bold
          kind="outline"
          borderRadius="56px"
          onClick={onClick}
          color="02Primary500"
          variant="error2"
          grow="1"
          loading={loading}
          modal={true}
        >
          Yes, complete
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  text-align: center;
  margin-top: 37px;

  @media ${breakpoints.mobile} {
    margin: 0 1rem;
  }
`;

const ButtonsWrapper = styled(Box)`
  margin-bottom: 10px;
  margin-top: 8px;

  @media ${breakpoints.mobile} {
    margin: 0 1rem;
    flex-direction: column;
    gap: 1rem;
  }
`;

export default CompleteSeasonModal;
