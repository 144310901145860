import LoadMore from "@common/LoadMore";
import { Box, Button, ListingCompactModular } from "@thrivecoin/ui-components";
import { textLinkify } from "@utils/text";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { MainContainer } from "../common/MainContainer";
import Orderable, { dragClass } from "../ListingEditor/CommunityListings/Orderable";
import Table from "../ListingEditor/CommunityListings/Table";
import { Statuses } from "./ManageCommunityCard/common";

const { ContributionTD, RewardTD, CanBeCompletedTD, CompletionCheckTD, ConnectOrDetailsTD, EditTD } =
  ListingCompactModular;

const CELLS = [ContributionTD, RewardTD, CanBeCompletedTD, CompletionCheckTD, ConnectOrDetailsTD, EditTD];

const useListingTab = () => {
  const { id } = useParams();
  const [status, setStatus] = useState("Active");
  const { managableListings, managablePagination } = useStoreState((state) => state.listings);
  const { getListingsRequest } = useStoreActions((actions) => actions.listings);
  const { updateOrders, setProperty } = useStoreActions((actions) => actions.listings);
  const onLoadMore = (page) => getListingsRequest({ id, page, isPaginating: true });

  const _updateOrders = ({ ids, orders }) => {
    updateOrders({ ids, orders }).then(() => {
      setProperty({ field: "managableIDs", data: ids });
    });
  };

  const onStatusChange = ({ value }) => {
    setStatus(value);
    getListingsRequest({ id, status: value.toLowerCase() });
  };

  useEffect(() => {
    id && getListingsRequest({ id });
  }, [id]);

  return {
    listings: managableListings,
    getListings: getListingsRequest,
    pagination: managablePagination,
    onStatusChange,
    status,
    onLoadMore,
    updateOrders: _updateOrders,
  };
};

const ListingsTab = ({ communityID }) => {
  const { infoToast, successToast } = useStoreActions((actions) => actions.toasts);
  const { isMobile, isDesktop, isTablet } = useStoreState((state) => state.media);
  const { archiveRequest, activateRequest, getSnapshotDetails, getActivityDetails, deleteListing } = useStoreActions(
    (actions) => actions.listings
  );
  const { showModal } = useStoreActions((actions) => actions.modals);

  const { listings, onLoadMore, pagination, updateOrders, status, onStatusChange } = useListingTab();

  const onStatusClick = (e) => {
    const value = e.target.dataset.value;
    onStatusChange({ value });
  };

  const onChange = (indexes) => {
    const ids = indexes.map((index) => listings[index].id);
    const orders = [...Array(ids.length)].map((_, index) => index);

    updateOrders({ ids, orders });
  };

  const { bindItems, removeItems } = Orderable({ itemSelector: "tr.listing-row", onChange });

  const onEdit = (event) => {
    const { id } = event.target.dataset;
    showModal({ modalName: "ListingEditorModal", listingID: id });
  };

  const doArchive = (event) => {
    const { id } = event.target.dataset;
    const { name } = listings.find((listing) => listing.id == id);
    const action = () =>
      archiveRequest(id).then(() => {
        infoToast({ content: `Your contribution “${name}” has been archived.`, timeout: 5000 });
      });

    showModal({ modalName: "ArchiveListingModal", action });
  };

  const doActivate = (event) => {
    const { id } = event.target.dataset;
    const { name } = listings.find((listing) => listing.id == id);
    const action = () =>
      activateRequest(id).then(() => {
        successToast({ content: `Your contribution “${name}” is now active.`, timeout: 5000 });
      });
    showModal({ modalName: "ActivateListingModal", action });
  };

  const sharedProps = {
    onEdit,
    status: status.toLowerCase(),
    doArchive,
    doActivate,
    deleteDraft: deleteListing,
  };

  const addListing = () => showModal({ modalName: "ListingEditorModal", communityID });

  useEffect(() => {
    bindItems();

    return removeItems;
  }, [listings]);

  return (
    <MainContainer>
      <Container className="listings-container">
        <Box flex alignItems="center">
          <Statuses status={status} onStatusClick={onStatusClick} />
          <Button borderRadius="40px" height="44px" spacing="ml-a" rightIcon="arrow-up-right" onClick={addListing}>
            Add New Way to Contribute
          </Button>
        </Box>
        <Table
          isMobile={isMobile}
          isDesktop={isDesktop}
          // onSort={onSort}
          // sortIcon={sortIcon
          // sortType={sortType}
          sortIcon={"up-long"}
          isCommunity
          confirmed
          isTablet={isTablet}
        >
          {/* <OrderableList onChange={onChange} ContainerKlass={TBody}> */}
          {listings.map((listing) => (
            <ListingCompactModular
              sharedProps={sharedProps}
              rowClass="listing-row"
              spacing="mb-5"
              key={listing.id}
              {...listing}
              isAuthenticated={true}
              reward={parseFloat(listing.reward_amount)}
              earned={parseFloat(listing.rewards_earned)}
              completedCount={listing.contributions_count || 0}
              canCompleteMultipleTimes={listing.unlimited_contributions_per_user}
              type={listing.contribution_app}
              manuallyVerification={listing.verification_method === "manual"}
              getSnapshotDetails={getSnapshotDetails}
              getActivityDetails={getActivityDetails}
              fieldClasses={CELLS}
              description={textLinkify(listing.description)}
            />
          ))}
        </Table>
        {!!pagination && <LoadMore pagination={pagination} loadMore={onLoadMore} />}
      </Container>
    </MainContainer>
  );
};

const Container = styled(Box)`
  .spacing-tr {
    display: none;
  }

  .listing-row {
    cursor: grab;
  }

  .${dragClass} {
    outline: 1px solid ${({ theme }) => theme.colors.hover};
    cursor: grab;

    &.active {
      cursor: grabbing;
    }
  }

  .gap-row {
    td {
      padding: 2rem;
    }

    &.active td {
      outline: solid 2px ${({ theme }) => theme.colors["01Primary300"]};
      outline-offset: -32px;
    }
  }
`;

export default ListingsTab;
