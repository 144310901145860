import Badge1 from "@assets/contributors/badge1.svg";
import Badge2 from "@assets/contributors/badge2.svg";
import Badge3 from "@assets/contributors/badge3.svg";
import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import truncateText from "@utils/truncateText";
import styled from "styled-components";
import { DEFAULT_PROFILE_IMAGE, DEFAULT_PROFILE_IMAGE_DARK } from "../Constants";
const UsernameAvatar = ({
  username,
  dataID,
  onProfileClick,
  image_url,
  id,
  myRank,
  isMobile,
  user_id,
  imageWidth,
  imageHeight,
  color,
  textSize,
  contributor_rank,
  isContributor,
}) => {
  const DEFAULT_AVATAR = myRank ? DEFAULT_PROFILE_IMAGE_DARK : DEFAULT_PROFILE_IMAGE;

  const avatar = image_url || DEFAULT_AVATAR;

  return (
    <>
      <UsernameBox alignItems="center" gap={isContributor ? "16px" : "12px"} flex>
        {isContributor && <ContributorBadge contributor_rank={contributor_rank} isMobile={isMobile}/>}
        {!isMobile && id && (
          <Text spacing="mr-2" width={isContributor && "45px"} typography={textSize} color={color} center>
            {id}.
          </Text>
        )}
        <WrapperAvatar>
          <Avatar
            width={imageWidth}
            height={imageHeight}
            src={avatar}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = DEFAULT_AVATAR;
            }}
          />
        </WrapperAvatar>
        <Text
          pointer
          bold
          data-id={dataID}
          data-userID={user_id}
          onClick={onProfileClick}
          textAlign="left"
          color={color}
          typography={textSize}
          flex
          alignItems="center"
          gap="8px"
        >
          {truncateText(username ?? "")}
        </Text>
      </UsernameBox>
    </>
  );
};

const badgeImages = [Badge1, Badge2, Badge3];
const ContributorBadge = ({ contributor_rank, isMobile }) => {
  const badgeImage = badgeImages[contributor_rank - 1];

  if (!badgeImage) return null;

  return (
    <Box width={isMobile ? "37px" : "45px"} height={isMobile ? "46px" : "56px"}>
      <Badge src={badgeImage} />
    </Box>
  );
};

const WrapperAvatar = styled(Box)`
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
  display: flex;
  justify-content: center;
`;

const Avatar = styled.img`
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
  object-fit: cover;
  border-radius: 50%;
`;

const UsernameBox = styled(Box)`
  &:hover {
    div:last-child {
      text-decoration-line: underline;
      color: ${({ theme }) => theme.colors["02Primary500"]};
    }
  }
`;

const Badge = styled.img`
  width: 45px;
  height: 56px;

  @media ${breakpoints.mobile} {
    width: 37px;
    height: 46px;
  }
`;

UsernameAvatar.defaultProps = {
  imageWidth: "40px",
  imageHeight: "40.9px",
  textSize: "body/medium",
};

export default UsernameAvatar;
