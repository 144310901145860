import { configure } from "@thrivecoin/thc-auth-js";
import { useEffect } from "react";
import { getInstance } from "../../utils/web3wrapper";

const useConfigureAuth = () => {
  useEffect(() => {
    configure({
      projectID: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
      auth_url: process.env.REACT_APP_AUTHENTICATION_API_URL,
      app_url: process.env.REACT_APP_API_BASE_URL,
    });

    getInstance(true);
  }, []);
};

export default useConfigureAuth;
