import { Modal } from "@thrivecoin/ui-components";
import SeasonEditor from "../SeasonEditor";

const SeasonEditorModal = ({ hideModal, seasonID, communityID, edit }) => {
  return (
    <Modal
      open
      hideX
      width="100%"
      paddingBody="0"
      transform="unset"
      onClose={hideModal}
      maxWidth="calc(100vw - 64px)"
      contentHeight="calc(100vh - 64px)"
      borderRadius="12px"
    >
      <SeasonEditor communityID={communityID} seasonID={seasonID} edit={edit} />
    </Modal>
  );
};

export default SeasonEditorModal;
