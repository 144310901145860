import { TextArea } from "@thrivecoin/ui-components";
import { useContext } from "react";
import { Divider, InputGroup, wrappedSelect } from "../common";
import { ListingEditorContext } from "../ListingEditorContext";

const NameSelectionStep = () => {
  const { isEditing, communities, listing } = useContext(ListingEditorContext);
  const { name, community_id, description, custom_url } = listing;
  const options = communities.map(({ id, logo_url, name }) => ({ value: id, label: name, url: logo_url }));

  return (
    <div>
      <InputGroup field="name" label="Name (Required)" required defaultValue={name} />
      <InputGroup
        field="custom_url"
        placeholder="E.g. https://twitter.com/thrivecoinhq"
        label="Take Action Link (Optional)"
        description="URL where a contributor can go to complete this contribution"
        defaultValue={custom_url}
      />
      <Divider />
      <InputGroup
        label="Community (Required)"
        field="community_id"
        InputClass={wrappedSelect(options)}
        defaultValue={community_id}
        required
        disabled={isEditing}
      />
      <Divider />
      <InputGroup
        field="description"
        label="Description (Optional)"
        placeholder="E.g. Here’s more details about how to do this activity, and why it’s important for our Community!"
        InputClass={TextArea}
        value={description}
      />
    </div>
  );
};

export default NameSelectionStep;
