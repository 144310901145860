import { Box, breakpoints, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import styled from "styled-components";

const DisconnectSocialProfileModal = ({ hideModal, provider, name }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const { disconnectSocialProfile, getProfileRequest } = useStoreActions((actions) => actions.authentication);
  const showName = name || provider;

  const doDisconnect = () =>
    disconnectSocialProfile(provider).then(() => {
      getProfileRequest();
      hideModal();
    });

  return (
    <Modal
      open
      onClose={hideModal}
      leaf={!isMobile && true}
      borderRadius={isMobile && "40px"}
      maxWidth="684px"
      sm-spacing="px-6"
      spacing="pt-6+6"
    >
      <Wrapper>
        <Title bold size="24px" lHeight="30px" color="02Primary700">
          Are you sure you want to disconnect your {showName} identity?
        </Title>
        <StyledText color="02Primary700">
          You will no longer receive rewards for contributions made on {showName}
        </StyledText>
      </Wrapper>
      <ButtonsWrapper flex>
        <StyledButton
          size="lg"
          grow={1}
          heightSize="56px"
          borderRadius="40px"
          color="02Primary700"
          kind="outline"
          borderColor="secondary200"
          onClick={hideModal}
          modal={true}
        >
          No, I don't want to do that
        </StyledButton>
        <StyledButton
          size="lg"
          grow={1}
          heightSize="56px"
          borderRadius="40px"
          color="02Primary700"
          kind="outline"
          borderColor="destructive500"
          onClick={doDisconnect}
          modal={true}
        >
          Yes, Disconnect
        </StyledButton>
      </ButtonsWrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)`
  text-align: center;
`;

const Title = styled(Text)`
  font-family: Outfit;
  margin-top: 20px;
`;

const StyledText = styled(Text)`
  margin: 16px 0 32px 0;
  font-size: 16px;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  font-weight: 600;

  @media ${breakpoints.mobile} {
    width: 100% !important;
  }
`;

const ButtonsWrapper = styled(Box)`
  gap: 16px;

  button {
    line-height: 24px;
    width: 252px;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

export default DisconnectSocialProfileModal;
