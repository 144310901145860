import Bar from "@assets/about-us/bar.svg";
import PersonImg from "@assets/about-us/person-walking-hand.svg";
import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const EarlyTest = () => {
  return (
    <EarlyTestWrapper>
      <Title>We are just beginning early testnet</Title>
      <WrapperBar>
        <LineBlack />
        <BarImg src={Bar} />
        <LineWhite />
      </WrapperBar>

      <Paragraph>
        ThriveCoin has recently soft-launched TestNet to a few communities that some of our founding team members are
        passionate about. In the coming weeks and months, we will support more and more communities.
      </Paragraph>
      <WrapImgText className="lalalala" flex={+true} gap={"83px"} justifyContent={"center"}>
        <StylePerson src={PersonImg} />
        <Box>
          <BoxSquare flex={+true} alignItems={"flex-start"} spacing={"mb-7"}>
            <Number>1</Number>
            <StyledText>
              The purpose of TestNet is to fully develop our protocol and infrastructure with the collaboration of the
              community members who are using us to improve their lives and their communities. Your feedback and
              collaboration is vital to seeing our vision through.
            </StyledText>
          </BoxSquare>

          <BoxSquare flex={+true} alignItems={"flex-start"}>
            <Number>2</Number>
            <StyledText>
              We would love to collaborate deeply with you - as deeply as you desire. As you contribute to communities
              you love, please let us know what works, what doesn’t work, and what you think might work. We’ll listen
              and work with you to be better each day!
            </StyledText>
          </BoxSquare>
        </Box>
      </WrapImgText>
    </EarlyTestWrapper>
  );
};

const BoxSquare = styled(Box)`
  gap: 34px;
  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: center;
    padding: 0 17px;
    gap: 23px;
  }
`;

const StylePerson = styled.img`
  @media ${breakpoints.mobile} {
    max-width: 144px;
    align-items: center;
    margin: 0 auto;
  }
`;

const WrapImgText = styled(Box)`
  @media ${breakpoints.mobile} {
    flex-direction: column;
    text-align: center;
    font-size: 16px;
    gap: 18px;
  }
`;

const EarlyTestWrapper = styled.div`
  background: #ecb2ae;
  border-radius: 25px;
  height: 823px;
  padding: 53px 0 48px 0;
  margin-bottom: 36px;
  @media ${breakpoints.mobile} {
    height: 1262px;
  }
`;

const BarImg = styled.img`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 61px;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  @media ${breakpoints.tablet} {
    width: 100%;
    height: 38px;
  }
  @media ${breakpoints.mobile} {
    width: 349px;
  }
`;

const WrapperBar = styled.div`
  position: relative;
  height: 100px;
  @media ${breakpoints.mobile} {
    height: 52px;
  }
`;

const LineBlack = styled.div`
  position: absolute;
  background: #2a3756;
  border: 5px solid #2a3756;
  left: 0;
  width: 35%;
  top: 14px;
  z-index: 0;
  display: none;
  @media ${breakpoints.desktop} {
    display: block;
  }
`;

const LineWhite = styled.div`
  position: absolute;
  border: 5px solid ${({ theme }) => theme.colors["01Primary0"]};
  background: ${({ theme }) => theme.colors["01Primary0"]};
  width: 50%;
  top: 14px;
  z-index: 0;
  right: 0;
  display: none;
  @media ${breakpoints.desktop} {
    display: block;
  }
`;

const Title = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 34px;
  line-height: 42px;
  color: ${({ theme }) => theme.colors["01Primary0"]};
  margin-bottom: 55px;
  text-align: center;
  @media ${breakpoints.mobile} {
    font-size: 28px;
    line-height: 36px;
    padding: 0 17px;
    margin-bottom: 32px;
  }
`;

const Paragraph = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: ${({ theme }) => theme.colors["02Primary700"]};
  margin: 0 auto 93px auto;
  max-width: 1227px;
  text-align: center;
  @media ${breakpoints.tablet} {
    max-width: 801px;
  }

  @media ${breakpoints.mobile} {
    font-size: 18px;
    line-height: 26px;
    padding: 0 17px;
    margin: 0 auto 29px auto;
  }
`;

const StyledText = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors["02Primary700"]};
  max-width: 543px;
  @media ${breakpoints.tablet} {
    max-width: 428px;
  }
  @media ${breakpoints.mobile} {
    font-size: 16px;
  }
`;

const Number = styled.div`
  width: 63px;
  height: 63px;
  border-radius: 50%;
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 32px;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors["01Primary0"]};
  @media ${breakpoints.mobile} {
    width: 39px;
    height: 39px;
    font-size: 26px;
    line-height: 26px;
  }
`;

export default EarlyTest;
