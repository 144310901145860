import image from "@assets/empty-result.svg";
import imageDark from "@assets/empty_result_dark.svg";
import { Box, Button, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const EmptyContent = ({
  title,
  text,
  btnText,
  onClick,
  imageWidth,
  rightIcon,
  spacing,
  marginTopImage,
  hideBtnIcon,
  maxWidth,
}) => (
  <CenteredContainer flex direction="column" maxWidth={maxWidth} align="center" alignItems="center" spacing={spacing}>
    <Text color="01Primary700" typography="heading/large" spacing="mb-5" bold>
      {title}
    </Text>
    <Text color="01Primary700" typography="body/medium" spacing="mb-6">
      {text}
    </Text>
    {btnText && (
      <Button
        bold
        onClick={onClick}
        size="lg"
        color="01Primary0"
        height="56px"
        borderRadius="50px"
        rightIcon={!hideBtnIcon && rightIcon}
      >
        {btnText}
      </Button>
    )}
    {imageWidth && <StyledImage marginTopImage={marginTopImage} width={imageWidth} />}
  </CenteredContainer>
);

export const SearchEmpty = () => (
  <EmptyContent
    title="No results found"
    text="Please try again with different search terms."
    imageWidth="100%"
    spacing="mt-7"
  />
);

const CenteredContainer = styled(Box)`
  margin: 0 auto;
`;

const StyledImage = styled.img.attrs(({ theme }) => ({
  src: theme.themeName === "dark" ? imageDark : image,
}))`
  margin-top: ${({ marginTopImage }) => marginTopImage && marginTopImage};
`;

EmptyContent.defaultProps = {
  rightIcon: "arrow-up-right",
  marginTopImage: "46px",
  maxWidth: "440px",
};

export default EmptyContent;
