import PeopleImg from "@assets/community-editor/people-friends.svg";
import { Box, Button, IconSvg, Modal, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const BenefitsCommunityCreationModal = ({ hideModal }) => {
  return (
    <Modal
      marginTop={"77px"}
      open
      onClose={hideModal}
      leaf={true}
      spacing="px-0 pt-7+6 pb-7"
      maxWidth="684px"
      borderRadiusX="50px"
    >
      <Header>Benefits of Community Creation</Header>
      <Paragraph>
        Communities are a way to gather individuals around a shared belief, interest, background, geography, or
        professional affiliaton.
      </Paragraph>
      <Line />
      <WrapText>
        <WrapperIcon backgroundColor="rgba(218, 245, 222, 0.31)" borderColor="#8DCF9F">
          <IconSvg icon="zap" color="#3C994B" width="15px" />
        </WrapperIcon>
        <StyledText>
          Communities on ThriveCoin may be eligible for grants from our treasury, and can easily activate potential
          contributors with community challenges.
        </StyledText>
      </WrapText>

      <WrapText>
        <WrapperIcon backgroundColor="rgba(249, 215, 96, 0.2)" borderColor="#E2BC5D">
          <IconSvg icon="trophy" color="#A47C18" width="16px" />
        </WrapperIcon>
        <StyledText>Challenges allow you to reward any contribution the Community values.</StyledText>
      </WrapText>

      <WrapText>
        <WrapperIcon backgroundColor="rgba(136, 207, 222, 0.15)" borderColor="#88CFDE">
          <IconSvg icon="plus" color="#4F99A9" />
        </WrapperIcon>
        <StyledText>
          As the founder, you will be responsible for ensuring the community follows our Community Guidelines, and for
          funding any community chalenges.
        </StyledText>
      </WrapText>
      <StyledImage src={PeopleImg} />
      <WrapperButton>
        <Button
          bold={+true}
          borderRadius="40px"
          bgColor="primary700"
          kind="solid"
          heightSize="56px"
          onClick={hideModal}
          modal={true}
        >
          Get Started
        </Button>
      </WrapperButton>
    </Modal>
  );
};

const Header = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors["02Primary700"]};
`;

const Paragraph = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors["02Primary700"]};
  margin: 0 auto 0 auto;
  padding-bottom: 24px;
  max-width: 524px;
`;

const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.tertiary100};
  margin-bottom: 24px;
`;

const WrapperIcon = styled.div`
  min-width: 46px;
  min-height: 44px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
`;

const WrapText = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  padding: 0 80px;
`;

const StyledText = styled(Text)`
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.secondary400};
`;

const StyledImage = styled.img`
  max-width: 250px;
  max-height: 191px;
  margin: 8px auto 40px auto;
`;

const WrapperButton = styled.div`
  padding: 0 78px 0 82px;
  button {
    width: 100%;
  }
`;
export default BenefitsCommunityCreationModal;
