import { Modal } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import AboutSection from "./AboutSection";
import AboutTreasury from "./AboutTreasury";
import Commitment from "./Commitment";
import EarlyTest from "./EarlyTest";
import TokenInfo from "./TokenInfo";
import ValueRecognize from "./ValueRecognize";
import WhoWeAre from "./WhoWeAre";

const AboutUsModal = ({ hideModal }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const useScroll = () => {
    const elRef = useRef(null);
    const abouttokenRef = useRef(null);
    const executeScroll = () => elRef.current?.scrollIntoView({ behavior: "smooth" });

    const executeScrollToken = () => abouttokenRef.current?.scrollIntoView({ behavior: "smooth" });
    return [executeScroll, elRef, abouttokenRef, executeScrollToken];
  };

  const [executeScroll, elRef, abouttokenRef, executeScrollToken] = useScroll();
  useEffect(() => {}, [executeScroll, executeScrollToken]);
  useVirtualPageView({ pathname: "/about-us" });

  return (
    <Modal
      open
      onClose={hideModal}
      leaf={true}
      fullScreen={true}
      headerLogo={true}
      onLogoClick={hideModal}
      paddingBody={isMobile ? "12px" : "1.25rem 1.5rem 1.5rem 1.5rem"}
    >
      <WrapperInside>
        <AboutSection executeScroll={executeScroll} />
        <WhoWeAre elRef={elRef} />
        <ValueRecognize />
        <EarlyTest />
        <TokenInfo executeScrollToken={executeScrollToken} />
        <AboutTreasury abouttokenRef={abouttokenRef} />
        <Commitment hideModal={hideModal} />
      </WrapperInside>
    </Modal>
  );
};

const WrapperInside = styled.div`
  margin: 31px auto 0 auto;
  width: 100%;
`;
export default AboutUsModal;
