import { Box, Button, Grid, Input, Text, TextArea } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";

const ReasonsText = {
  uncompleted: "Contribution was submitted, but is not considered complete",
  unable_to_verify_completion: "Unable to verify contribution was completed",
  inappropriate: "Inappropriate contribution was submitted",
  due_to_other_contributions:
    "Contribution was completed, but is rejected in favor of other contributions (Not recommended)",
};

const RadioButton = styled(Input)`
  ${({ theme }) => `

      input[type='radio'] {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        outline: none;
        padding: 2px;
        cursor: pointer;
        margin-top: 5px;
        border-color: ${theme.colors["02PrimaryGrey300"]};
        background: ${theme.colors["02Primary0"]};
        position: relative;
      }

      input[type='radio']:before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
      }

      input[type='radio']:checked {
        border: 1px solid ${theme.colors["error"]};
        background: #FFFFFF;
      }

      input[type='radio']:checked:before {
        background-color: ${theme.colors["error"]};
      }
  `}
`;

const reasons = Object.keys(ReasonsText);

const PLACEHOLDER = "Changes needed for contribution to be approved (Optional)";

const RejectStep = ({ hideModal, id, contribution_claim_id, isMobile }) => {
  const [selected, setSelected] = useState();
  const { rejectClaimRequest } = useStoreActions((actions) => actions.contributions);
  const [reviewer_comments, setRejectionNote] = useState("");
  const disabled = !selected;
  const { loading } = useStoreState((state) => state.contributions);

  const selectReason = (event) => {
    const reason = event.currentTarget.dataset.value;
    setSelected(reason);
  };

  const changeRejectionNote = (event) => {
    setRejectionNote(event.target.value);
  };

  const { showToast, dangerToast } = useStoreActions((actions) => actions.toasts);

  const onClick = (event) => {
    rejectClaimRequest({
      id,
      contribution_claim_id,
      rejection_reason: selected,
      reviewer_comments,
    })
      .then(refreshPendingContributions)
      .then(() => {
        showToast({
          content: "Contribution claim was rejected successfully!",
          dismissTime: 5000,
        });
        hideModal();
      })
      .catch(({ response }) => {
        dangerToast({
          content: response.data.message,
          dismissTime: 5000,
        });
      });
  };

  const { getPendingContributions } = useStoreActions((actions) => actions.pendingContributions);
  const { community_id } = useParams();
  const refreshPendingContributions = () => {
    getPendingContributions({ community_id });
  };

  const elements = reasons.map((reason) => (
    <OptionBox key={reason} selected={selected == reason} data-value={reason} onClick={selectReason}>
      <RadioButton type="radio" checked={selected == reason} />
      {ReasonsText[reason]}
    </OptionBox>
  ));

  return (
    <Box flex direction="column" spacing="my-5">
      <StyledText typography="heading/medium" spacing="mb-4" color="02Primary500" bold>
        Select reason for rejecting contribution
      </StyledText>
      <StyledText typography="body/medium" spacing="mx-a" color="02Primary500" spacing="mb-6">
        <b>Please note:</b> Contributors can re-submit contributions until they're accepted.
      </StyledText>

      <Grid spacing={isMobile ? "mb-4" : "mb-6"} gap={isMobile ? "16px" : "1.5rem"}>
        {elements}
      </Grid>

      <StyledTextArea
        value={reviewer_comments}
        onChange={changeRejectionNote}
        name="rejection-note"
        spacing={isMobile ? "mb-4" : "mb-6"}
        borderColor="02PrimaryGrey300"
        bgColor="02PrimaryGrey50"
        color="02Primary500"
        placeholder={PLACEHOLDER}
        rows="6"
      />

      <Box flex direction={isMobile && "column"} gap="16px" justifyContent="space-between">
        <Button
          borderRadius="50px"
          bold
          kind="outline"
          variant="white"
          onClick={hideModal}
          grow="1"
          spacing=" py-4"
          modal={true}
        >
          Cancel
        </Button>
        <StyledButton>
          <Button
            spacing="py-4"
            borderRadius="50px"
            semiBold
            grow={1}
            variant="red"
            disabled={disabled}
            onClick={onClick}
            loading={loading}
            modal={true}
          >
            Reject
          </Button>
        </StyledButton>
      </Box>
      {isMobile && <Box bgColor="02PrimaryGrey300" spacing="mt-5" height="1px" width="100%" />}
    </Box>
  );
};

const OptionBox = styled(Box)`
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  color: ${({ theme }) => theme.colors["02Primary500"]};
  background: ${({ theme }) => theme.colors["02PrimaryGrey100"]};
  border-radius: 12px;
  border: 1px solid transparent;
  gap: 12px;

  ${({ selected, theme }) =>
    selected &&
    `
    border: 1px solid ${theme.colors["error"]};
  `}
`;

const StyledButton = styled(Box)`
  flex-grow: 1;
  display: flex;

  & button {
    &:disabled {
      background: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
      border-color: ${({ theme }) => theme.colors["02PrimaryGrey300"]};
    }
  }
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const StyledTextArea = styled(TextArea)`
  textarea {
    &::placeholder {
      color: ${({ theme }) => theme.colors["02Primary500"]};
    }
  }
`;
export default RejectStep;
