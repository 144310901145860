import { breakpoints, TabsContainer } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useRef } from "react";
import styled from "styled-components";
import AboutCommunity from "./Challenges/AboutCommunity";
import Hero from "./Hero/index";

let HEADERS = ["Announcements", "Ways to Contribute", "About This Community", "Contributors", "Contributions"];
let TAB_CLASSES = [null, null, AboutCommunity];

const PreviewCommunityPage = () => {
  const containerRef = useRef(null);
  const { myContributions, community } = useStoreState((state) => state.community);

  const customTheme = community.theme === "custom";
  const tabBgColor = customTheme ? community.accent_color : "03Primary500";
  const tabColor = customTheme ? "02Primary0" : "01Primary0";

  return (
    <Wrapper>
      <Hero community={community} myContributions={myContributions} loading={false} />

      <MainGrid>
        <Container ref={containerRef}>
          <WrapperTab noSpace={true}>
            <TabsContainer
              tabProps={{ preview: true }}
              selectedTabBgColor={tabBgColor}
              selectedTabColor={tabColor}
              tabClasses={TAB_CLASSES}
              headers={HEADERS}
              selectedIndex={2}
            />
          </WrapperTab>
        </Container>
      </MainGrid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors["01Primary0"]};
`;

const MainGrid = styled.div`
  background: ${({ theme }) => theme.colors.background};
  border-top: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
  position: relative;
  @media ${breakpoints.tablet}, @media ${breakpoints.mobile} {
    display: flex;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  // display: grid;
  grid-template-columns: auto;
  gap: 25px;
  padding-bottom: 63px;

  @media (max-width: 1003px) {
    grid-template-columns: auto;
  }

  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0 0 24px;
  }
`;

const WrapperTab = styled.div`
  padding-left: 0;
  position: relative;

  & > div:first-child {
    & > div:first-child {
      & > div {
        opacity: 0.5;
      }
      & > div:nth-child(3) {
        opacity: 1;
      }
      & > div:not(:nth-child(3n)) {
        pointer-events: none;
      }
    }
  }
  & > div > div {
    width: 100%;
    & > div:nth-child(2) {
      border-top: 1px solid ${({ theme }) => theme.colors["01Primary200"]};
      background: ${({ theme }) => theme.colors.background};
      & > div {
        @media (min-width: 1003px) and (max-width: 1456px) {
          max-width: calc(100vw - 64px);
        }
        @media ${breakpoints.tablet} {
          max-width: ${({ noSpace }) => (noSpace ? "unset" : " calc(100vw - 64px)")};
        }
        @media ${breakpoints.mobile} {
          max-width: ${({ noSpace }) => (noSpace ? "unset" : " calc(100vw - 64px)")};
        }
      }
    }

    & > div:first-child {
      margin: 0 auto;
      padding: 27px 0 28px 32px;

      @media ${breakpoints.tablet} {
        padding: 27px 0 28px 32px;
      }
      @media ${breakpoints.mobile} {
        width: unset;
      }
    }
  }
`;

export default PreviewCommunityPage;
