import { abis, web3Utils } from "@thrivecoin/web3-utils";
import ConnectionConfigs from "@utils/ChainConnections";
import { useStoreActions } from "easy-peasy";
import { useEffect } from "react";
import { getInstance } from "../../utils/web3wrapper";
import { readPendingTransaction, removePendingTransaction } from "./useRewardsContract";

const getWeb3 = (chainID) => {
  const rpcUrl = ConnectionConfigs[chainID].rpcUrls[0];
  return web3Utils.getProvider(rpcUrl);
};

const getContractTransaction = async (chain_id, contractAddress, userAddress) => {
  const web3 = getWeb3(chain_id);
  const contract = web3Utils.getContract(web3, abis.ThriveCoinRewardSeasonIERC20GasRefundable, contractAddress);
  const seasonNumber = await contract.methods.currentSeason().call();
  const result = await contract.methods.readReward(seasonNumber, userAddress).call();

  return result.claimed;
};

const useManageClaimingState = (season) => {
  const { id, earnings_status, reward_claiming_config } = season;
  const web3Wrapper = getInstance();
  const { web3 } = web3Wrapper;
  const { updateSeason, claimRewardRequest } = useStoreActions((actions) => actions.season);

  const { contract_address, chain_id } = reward_claiming_config || {};

  const checkIfAlreadyClaimed = async (chain_id, txHash) => {
    const userAddress = web3Wrapper.selectedAddress();
    const isClaimed = await getContractTransaction(chain_id, contract_address, userAddress);
    let earnings_status = "pending";

    if (isClaimed) {
      await claimRewardRequest({ id, tx_id: txHash });
      removePendingTransaction(season);
      earnings_status = "claimed";
    }

    updateSeason({ id, earnings_status });
  };

  useEffect(async () => {
    if (contract_address && earnings_status != "claimed" && web3) {
      const txHash = readPendingTransaction(season);
      if (txHash) {
        checkIfAlreadyClaimed(chain_id, txHash);
      }
    }
  }, [contract_address, web3]);
};

export default useManageClaimingState;
