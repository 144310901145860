import AvatarUsername from "@common/UsernameAvatar";
import { Box, breakpoints, Text, TokenReward } from "@thrivecoin/ui-components";
import { formatDate } from "@utils/formatting";
import styled from "styled-components";
import { LinkThankYou } from "../common";

const ContributionsRow = ({
  contribution,
  community,
  amount,
  date,
  receipt_link,
  processing,
  onCommunityClick,
  username,
  image_url,
  onProfileClick,
  user_id,
  reward_token,
  hide_amounts,
  showCommunity,
  isDesktop,
  isTablet,
  isMobile,
  isFromProfile,
  showRewardCommunity,
  fromProfileTablet,
}) => {
  const textSize = isMobile ? "body/small" : "body/medium";

  return (
    <>
      <Row isFromProfile={isFromProfile}>
        {!isFromProfile && (
          <DataElement>
            {!!community && (
              <AvatarUsername
                color="01Primary700"
                username={username}
                community={community}
                onCommunityClick={onCommunityClick}
                image_url={image_url}
                onProfileClick={onProfileClick}
                user_id={user_id}
                textSize={textSize}
              />
            )}
          </DataElement>
        )}
        <DataElement maxWidth="180px" padding={isTablet ? "24px" : undefined} isFromProfile={isFromProfile}>
          <Text color="01Primary700" bold={!isDesktop} typography={textSize} data-id={community?.id}>
            {contribution}
          </Text>
        </DataElement>
        {showRewardCommunity && (
          <>
            {showCommunity && (
              <DataElement padding={fromProfileTablet && "0"}>
                <Box flex alignItems="center" justifyContent="start" gap="6px">
                  <Text
                    typography={textSize}
                    pointer
                    color="link"
                    bold
                    data-id={community?.id}
                    onClick={onCommunityClick}
                  >
                    {community?.name}
                  </Text>
                </Box>
              </DataElement>
            )}
            {!hide_amounts && (
              <DataElementReward isFromProfile={isFromProfile} gapReward={fromProfileTablet && "6px"}>
                <TokenReward
                  hideText
                  amount={+amount}
                  token={reward_token}
                  color="01Primary700"
                  size={isFromProfile && !isDesktop && "26px !important"}
                />
              </DataElementReward>
            )}
          </>
        )}
        <DateElement isFromProfile={isFromProfile}>
          <Text color="01Primary700" typography={textSize} textAlign="left">
            {formatDate(date)}
          </Text>
        </DateElement>
        {isDesktop && (
          <DataElement>
            {!!receipt_link && (
              <LinkThankYou target="blank" href={receipt_link}>
                View Thank You Note
              </LinkThankYou>
            )}
            {!!processing && (
              <Text typography="body/medium" textAlign="right" color="01Primary700" size="16px" lineHeight="24px">
                Receipt processing
              </Text>
            )}
          </DataElement>
        )}
      </Row>
      <SpacingRow />
    </>
  );
};

const DataElement = styled.td`
  ${({ pointer }) => pointer && `cursor: pointer;`}
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  padding: ${({ padding }) => padding || "0 24px"};
  height: 108px;
  @media ${breakpoints.tablet} {
    height: auto;
    min-height: 90px;
  }
`;

const DataElementReward = styled.td`
  ${({ pointer }) => pointer && `cursor: pointer;`}
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  padding: ${({ padding }) => (padding ? padding : "0 24px")};
  height: 108px;
  @media ${breakpoints.tablet} {
    height: auto;
    min-height: 90px;
  }

  & > div {
    gap: ${({ gapReward }) => gapReward && gapReward};
  }

  @media ${breakpoints.mobile} {
    ${({ isFromProfile }) =>
      isFromProfile &&
      `
      padding: 0!important;
      position: relative;
      &>div {
        margin-top: auto;
      }
      `}
  }
`;

const DateElement = styled.td`
  ${({ pointer }) => pointer && `cursor: pointer;`}
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  padding: ${({ padding }) => (padding ? padding : "0 24px")};
  height: 108px;
  @media ${breakpoints.tablet} {
    padding: ${({ isFromProfile }) => (isFromProfile ? "24px" : "0 24px")};
    height: auto;
    min-height: 90px;
  }
  @media ${breakpoints.mobile} {
    ${({ isFromProfile }) =>
      isFromProfile &&
      `
      padding: 0!important;
      position: relative;
      &>div {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      `}
  }
`;

const Row = styled.tr`
  background: ${({ theme, isFromProfile }) =>
    isFromProfile ? theme.colors["01Primary110"] : theme.colors["01Primary0"]};
  border-radius: 8px;

  @media ${breakpoints.mobile} {
    display: inline-block;
    padding: 15.55px 16px 16px 16px;
    width: -webkit-fill-available;
    ${({ isFromProfile }) =>
      isFromProfile &&
      `
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          td {
            display: flex !important;
            width: 50% !important;
          }
          td:nth-child(2) {
            &>div {
              margin-left: auto;
              align-items: flex-start;
            }
          }
            td:last-child {
            &>div {
              margin-left: auto;
              align-items: center;
            }
          }
        `};

    td {
      height: unset;
      display: inline-block;
      padding: 0 0 15.55px 0;
      & > div {
        display: inherit;
      }
    }
    td:first-child {
      display: inherit;
      width: 100%;
      & > div {
        display: flex;
      }
    }
    td:last-child {
      float: right;
      & > div {
        padding-left: 24px;
      }
    }
  }
`;

const SpacingRow = styled.tr`
  margin-bottom: 20px;
  display: block;
  @media ${breakpoints.mobile} {
    margin-bottom: 16px;
  }
`;

export default ContributionsRow;
