import { Box, breakpoints, IconSvg, Modal, Text } from "@thrivecoin/ui-components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import useSocialProfilesInfo from "../../hooks/useSocialProfilesInfo";
import DiscourseConnectStep from "../SharedModalSteps/DiscourseConnectStep";
import { Content as UpdateProfileInfoStep } from "../UpdateProfileModal";
import SelectANetworkStep from "./SelectANetworkStep";

const STEP_INDEXES = {
  SelectANetworkStep: 0,
  SelectDiscourseStep: 1,
  UpdateProfileInfoStep: 2,
  DiscourseConnectStep: 3,
};

const StyledDiscourseConnectStep = (props) => (
  <Box flex direction="column" spacing="p-4">
    <DiscourseConnectStep {...props} />
  </Box>
);

const SelectDiscourseStep = (props) => <SelectANetworkStep {...props} useDiscourse />;

const STEP_KLASSES = [SelectANetworkStep, SelectDiscourseStep, UpdateProfileInfoStep, StyledDiscourseConnectStep];

const UpdateProfileFlowModal = ({ hideModal, initialStep, provider: _provider, ...props }) => {
  const [step, _goToStep] = useState(initialStep);
  const [provider, setProvider] = useState(_provider);
  const [showGoBack, setShowGoBack] = useState(false);
  const [discourseData, setDiscourseData] = useState(initialStep);
  const StepKlass = STEP_KLASSES[step];
  const socialInfo = useSocialProfilesInfo();
  const manualFill = () => goToStep(STEP_INDEXES.UpdateProfileInfoStep);
  const gotoSelectDiscourse = () => goToStep(STEP_INDEXES.SelectDiscourseStep);
  const goToDiscourse = (name, provider) => {
    setDiscourseData({ name, provider });
    goToStep(STEP_INDEXES.DiscourseConnectStep);
  };

  const onConnected = (provider) => setProvider(provider);

  const goToStep = (index) => {
    if (index !== 0) {
      setShowGoBack(true);
    }
    _goToStep(index);
  };

  const goBack = () => {
    goToStep(STEP_INDEXES.SelectANetworkStep);
    setShowGoBack(false);
  };

  useEffect(() => {
    if (provider) {
      goToStep(STEP_INDEXES.UpdateProfileInfoStep);
    }
  }, [provider]);

  return (
    <Modal open onClose={hideModal} maxWidth="680px" footerContent={<MobileFooter />} sm-spacing="px-4 pt-8" relative>
      <StyledBox direction="column" textAlign="center" relative>
        {showGoBack && <GoBack onClick={goBack} />}
        <StepKlass
          goToStep={goToStep}
          goToDiscourse={goToDiscourse}
          hideModal={hideModal}
          onConnected={onConnected}
          provider={provider}
          setShowGoBack={setShowGoBack}
          manualFill={manualFill}
          gotoSelectDiscourse={gotoSelectDiscourse}
          {...props}
          {...socialInfo}
          {...discourseData}
        />
      </StyledBox>
    </Modal>
  );
};

const MobileFooter = styled(Box)`
  display: none;
  width: 100%;
  @media ${breakpoints.mobile} {
    display: block;
    height: 78px;
    border-top: 1px solid #dadada;
  }
`;

const GoBack = styled(({ onClick, className }) => (
  <Box pointer flex alignItems="center" gap="8px" onClick={onClick} className={className}>
    <IconSvg icon="left-long" size="14px" />
    <Text size="15px" lHeight="20px" bold>
      Back
    </Text>
  </Box>
))`
  @media ${breakpoints.mobile} {
    position: absolute;
    top: -32px;
  }
`;

const StyledBox = styled(Box)`
  @media ${breakpoints.tablet} {
    height: 100%;
  }

  @media ${breakpoints.mobile} {
    height: 100%;
  }
`;

UpdateProfileFlowModal.defaultProps = {
  initialStep: 0,
};

export default UpdateProfileFlowModal;
