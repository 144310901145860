import { Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useEffect, useRef, useState } from "react";
import { STEP_INDEXES } from ".";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import useWeb3 from "../../hooks/useWeb3";
import { PaddedButton } from "../common";
import { PolygonFeesPanel } from "./FeesPage";

const TIMEOUT = 3000;

const CONFIGURED_PROPS = {
  variant: "success100",
  color: "success500",
  leftIcon: "checked-circle",
  text: "Polygon is configured",
};

const ConfigurePolygon = ({ goToStep }) => {
  const { addNetwork, isOnPolygon } = useWeb3();
  const initialRender = useRef(true);
  const [justInstalled, setJustInstalled] = useState(isOnPolygon);
  const addPolygon = addNetwork;
  const buttonProps = justInstalled ? CONFIGURED_PROPS : { text: "Configure Polygon", onClick: addPolygon };

  useEffect(() => {
    if (isOnPolygon) {
      if (initialRender.current) {
        return goToStep(STEP_INDEXES.AddTokensStep);
      } else {
        setJustInstalled(true);
        setTimeout(() => {
          goToStep(STEP_INDEXES.AddTokensStep);
        }, TIMEOUT);
      }
    }
    initialRender.current = false;
  }, [isOnPolygon]);

  useVirtualPageView({ pathname: "/setup/polygon" });

  return (
    <>
      <Text type="h4" fontFamily="Outfit" bold spacing="mb-6">
        Want no transaction fees?
      </Text>
      <PolygonFeesPanel></PolygonFeesPanel>

      <Text spacing="mb-6">
        Take one extra step to add MetaMask support for Polygon ($MATIC), one of the world’s most traded
        cryptocurrencies.
      </Text>

      <PaddedButton spacing="mb-4" {...buttonProps}>
        {buttonProps.text}
      </PaddedButton>
    </>
  );
};

ConfigurePolygon.FooterPercent = 66;

export default ConfigurePolygon;
