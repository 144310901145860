import { Box, breakpoints, IconSvg, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { STEP_INDEXES } from ".";
import useAuthentication from "../../hooks/useAuthentication";
import useVirtualPageView from "../../hooks/useVirtualPageView";
import useWeb3 from "../../hooks/useWeb3";
import { Link, PaddedButton } from "../common";

const CreateAccountStep = ({ goToStep }) => {
  const [loading, setLoading] = useState(false);
  const { freshAccount } = useStoreState((state) => state.user);
  const referral_code = sessionStorage.getItem("referral-code");
  const { installMetamask, hasWeb3Provider } = useWeb3();
  const { loginFlow } = useAuthentication();
  const invalid = !hasWeb3Provider;
  useVirtualPageView({ pathname: "/sign-up" });

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    loginFlow(referral_code).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (freshAccount) {
      goToStep(STEP_INDEXES.SearchForRewardStep);
    }
  }, [freshAccount]);

  return (
    <Page
      onSubmit={onSubmit}
      invalid={invalid}
      installed={hasWeb3Provider}
      goToInstall={installMetamask}
      loading={loading}
    />
  );
};

const Page = ({ onSubmit, invalid, installed, goToInstall, loading }) => {
  const { isMobile } = useStoreState((state) => state.media);

  return (
    <Container>
      <Text typography="Heading/Large" spacing="mb-4" maxWidth={isMobile && "294px"} color="02Primary500">
        Pick a passcode to connect
      </Text>
      <Text typography="Body/Medium" spacing="mb-5" sm-spacing="mb-6" color="02Primary500">
        Once you connect, you’ll be rewarded for contributions you’ve already completed, and automatically receive
        rewards for future contributions.
      </Text>
      <div>
        <PaddedButton
          size="lg"
          type="submit"
          typography="Body/Medium"
          onClick={onSubmit}
          disabled={invalid}
          loading={loading}
          variant="hover"
          modal={true}
        >
          Connect via MetaMask
        </PaddedButton>
        {!installed && (
          <Box flex gap="9px" color="#f16d67" pointer onClick={goToInstall}>
            <IconSvg width="16px" icon="alert-triangle" />
            <Text align="left" spacing="mt-2" typography="body/small">
              MetaMask is still not installed. Please install MetaMask before continuing with connecting.
            </Text>
          </Box>
        )}
      </div>
      <Box align={isMobile && "center"}>
        <Terms />
      </Box>
    </Container>
  );
};

const Terms = () => {
  return (
    <Text bold size="14px" lHeight="21px" color="02Primary500" spacing="mt-6">
      By registering, you accept our{" "}
      <Link color="link" to={`/terms_services`} target="_blank">
        Terms of use
      </Link>{" "}
      and{" "}
      <Link color="link" to={`/terms_privacy`} target="_blank">
        Privacy Policy
      </Link>
    </Text>
  );
};

const Container = styled(Box)`
  flex: 1;
  text-align: center;
  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    max-width: 426px;
    margin: 0 auto;
  }
  @media ${breakpoints.mobile} {
    padding: 0 1rem;
    text-align: left;
    margin-top: 40px;
  }
`;

const BorderedBox = styled(Box)`
  border-top: 1px solid #e4e4ed;
`;

const SignInText = styled.span`
  color: ${({ theme }) => theme.colors.link};
  cursor: pointer;
`;

const Footer = ({ goToStep }) => {
  const onClick = () => goToStep(STEP_INDEXES.SignInStep);
  return (
    <BorderedBox align="center" spacing="p-6" bgColor="tertiary50" color="02Primary500" bold>
      <Text bold>
        Already set up? <SignInText onClick={onClick}>Sign In</SignInText>
      </Text>
    </BorderedBox>
  );
};

CreateAccountStep.Footer = Footer;
CreateAccountStep.maxWidth = "684px";

export default CreateAccountStep;
