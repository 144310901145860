import { Box, breakpoints, Modal, ProgressBar } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import AddTokensStep from "./AddTokensStep";
import ChooseMethodStep from "./ChooseMethodStep";
import ConfigurePolygonStep from "./ConfigurePolygonStep";
import SetUpMetamaskStep from "./SetUpMetamaskStep";

export const STEP_INDEXES = {
  ChooseMethodStep: 0,
  SetUpMetamaskStep: 1,
  ConfigurePolygonStep: 2,
  AddTokensStep: 3,
};

const STEP_CLASSES = [ChooseMethodStep, SetUpMetamaskStep, ConfigurePolygonStep, AddTokensStep];

const ClaimRewardFlowModal = ({ hideModal, initialStep, reward_id, afterClose, onClose }) => {
  const [step, setStep] = useState(initialStep);
  const StepClass = STEP_CLASSES[step];
  const { isMobile } = useStoreState((state) => state.media);

  const footerContent = StepClass.FooterPercent ? <Footer percent={StepClass.FooterPercent} /> : null;


  return (
    <Modal
      marginTop={!isMobile && "77px"}
      open
      onClose={onClose}
      leaf={1}
      spacing={isMobile ? "px-0 pt-7" : "px-7+6 pt-7+6"}
      maxWidth="684px"
      footerContent={footerContent}
      borderRadius={isMobile && "24px"}
    >
      <Content textAlign="center">
        <StepClass goToStep={setStep} closeModal={hideModal} reward_id={reward_id} afterClose={afterClose} />
      </Content>
    </Modal>
  );
};

const Footer = ({ percent }) => (
  <StyledBox bgColor="tertiary50" spacing="p-7">
    <ProgressBar bgColor="#8DCF9F" height="6" percent={percent} />
  </StyledBox>
);

const Content = styled(Box)`
  @media ${breakpoints.mobile} {
    padding: 0 1rem;
  }
`;

const StyledBox = styled(Box)`
  box-shadow: 0px -0.5px 0.5px rgba(0, 0, 0, 0.2);
`;

ClaimRewardFlowModal.defaultProps = { initialStep: 0 };

export default ClaimRewardFlowModal;
