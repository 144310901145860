import toFormData from "@utils/toFormData";
import { action, actionOn, computed, thunk } from "easy-peasy";
import { isAdmin, isOwner } from "../../components/utils/general";
import HttpWrapper from "../httpRequester";
import { deNormalizeCollection, normalizeCollection2 } from "../normalizerSchema";
import { showComputed } from "./common";

const DEFAULT_PAGINATION = {
  page: 1,
  per_page: 4,
  total: 1,
};

const EMPTY_BUT_FETCHED = "";

const requester = new HttpWrapper("communities");
const usersRequester = new HttpWrapper("users");

const managedResolver = (state, storeState) => ({
  ids: state.managedCommunityIDs,
  entities: storeState.entities,
});

const myContributionsResolver = (state, storeState) => ({
  userID: storeState.user.id,
  userContributions: state.userContributions,
});

const rolesResolver = (state, storeState) => ({
  role: storeState.user.user.roles_by_community[state.showID],
  thrive_coin_roles: storeState.user.user.thrive_coin_roles,
});

const attributes = () => ({
  recentlyExplored: [],
  activeCommunities: [],
  activeCommunitiesPagination: DEFAULT_PAGINATION,
  contributedTo: [],
  publicSeasons: [],
  unclaimedSeasons: [],
  managedCommunityIDs: [],
  seasonsCount: {},
  communityBrand: { brand_color: "" },
  showID: undefined,
  rolesCommunityID: undefined,
  //TODO: save contributions and role under userData or byUserID
  userContributions: {},

  editCommunity: {},
});

const computedProps = {
  role: computed([rolesResolver], ({ role }) => role),
  myContributions: computed([myContributionsResolver], ({ userID, userContributions }) => {
    return userContributions[userID];
  }),
  isAdmin: computed([rolesResolver], (state) => isAdmin(state.role) || state.thrive_coin_roles.some(isAdmin)),
  isDirectAdmin: computed([rolesResolver], (state) => isAdmin(state.role)),
  isOwner: computed([rolesResolver], (state) => isOwner(state.role) || state.thrive_coin_roles.some(isAdmin)),
  community: showComputed("community"),
  managedCommunities: computed([managedResolver], ({ ids, entities }) => {
    return deNormalizeCollection(ids, entities, "community") || [];
  }),
};

const actions = {
  setPreview: thunk(({ normalize, setShowID }, community) => {
    community.isPreview = true;
    community.id = community.id + "temp";
    const { id } = community;
    normalize({ data: [community], entityName: "community", id });
    setShowID(id);
  }),
  getContributedTo: thunk(({ setContributedTo }) => {
    return usersRequester
      .get("current/contributed_communities?serializer=card")
      .then(({ communities }) => setContributedTo(communities));
  }),
  getRecentlyExploredCommunity: thunk(({ setRecentlyExplored }, ids) => {
    const getRequest = (id) => requester.get(`${id}/show?serializer=panel_card`);
    return Promise.all(ids.map(getRequest)).then((values) => {
      const communities = values.map(({ community }) => community);
      setRecentlyExplored(communities);
    });
  }),
  getActiveCommunities: thunk(
    ({ setActiveCommunities, setActiveCommunitiesPagination, loadMoreCommunities }, page = 1) => {
      return requester.post("search", { serializer: "card", per_page: 4, page }).then(({ communities, pagination }) => {
        if (page > 1) {
          loadMoreCommunities(communities);
        } else {
          setActiveCommunities(communities);
        }
        setActiveCommunitiesPagination(pagination);
      });
    }
  ),
  getCommunityBrand: thunk(({ setCommunityBrand }, brand) => {
    return requester.get(`${brand}/show?serializer=brand`).then(({ community }) => setCommunityBrand(community));
  }),
  getExternalCommunitiesRequest: thunk(() => {
    return requester.post("search");
  }),
  getPublicSeasons: thunk(({ setPublicSeasons }, { id, status }) => {
    return requester.get(`${id}/public_seasons`, { status }).then(({ seasons }) => setPublicSeasons(seasons));
  }),
  getSeasonsCountByStatus: thunk(({ setSeasonsCount }, id) => {
    return requester.get(`${id}/seasons_count_by_status`).then((data) => setSeasonsCount(data));
  }),
  find: thunk(({ normalize, setShowID }, idOrSlug) => {
    return requester.find(idOrSlug).then(({ community }) => {
      const { id } = community;
      normalize({ data: [community], entityName: "community", id });
      setShowID(id);
    });
  }),
  getEditCommunity: thunk(({ setEditCommunities }, community_id) => {
    return requester.get(`${community_id}/edit`).then(({ community }) => setEditCommunities(community));
  }),
  setEditCommunities: action((state, community) => {
    state.editCommunity = community;
  }),
  setContributedTo: action((state, communities) => {
    state.contributedTo = communities;
  }),
  setRecentlyExplored: action((state, communities) => {
    state.recentlyExplored = communities;
  }),
  setActiveCommunities: action((state, communities) => {
    state.activeCommunities = communities;
  }),
  setCommunityBrand: action((state, communityBrand) => {
    state.communityBrand = communityBrand;
  }),
  loadMoreCommunities: action((state, communities) => {
    state.activeCommunities = [...state.activeCommunities, ...communities];
  }),
  setActiveCommunitiesPagination: action((state, pagination) => {
    state.activeCommunitiesPagination = pagination;
  }),
  setShowID: action((state, id) => {
    state.showID = id;
  }),
  reset: action((state) => {
    //TOOD: only change the showID if we link everything using normalzr
    const managedCommunityIDs = state.managedCommunityIDs;
    const _attributes = attributes();
    _attributes.managedCommunityIDs = managedCommunityIDs;
    Object.assign(state, _attributes);
  }),
  setTriggerContribution: action((state, value) => {
    state.triggerContribution = value;
  }),
  setPublicSeasons: action((state, seasons) => {
    state.publicSeasons = seasons;
  }),
  setSeasonsCount: action((state, count) => {
    state.seasonsCount = count;
  }),
  getMyContributionsRequest: thunk(({ setUserContributions }, id, { getStoreState }) => {
    const userID = getStoreState().user.id;
    return usersRequester.get(`current/community/${id}/stats`).then(({ stats }) => {
      setUserContributions({ stats, userID });
    });
  }),
  setUserContributions: action((state, { stats, userID }) => {
    state.userContributions[userID] = stats;
  }),
  normalize: thunk((actions, { data, entityName }, { getStoreActions }) => {
    const { setEntities } = getStoreActions().entities;
    const result = normalizeCollection2(data, entityName);
    setEntities(result);
    return result.result;
  }),
  createCommunity: thunk((actions, communityData) => {
    const community = toFormData("community", communityData);

    return requester.post("", community);
  }),
  createDraftCommunity: thunk((actions, communityData) => {
    const community = toFormData("community", communityData);
    // TODO: create the draft community
    return requester.post("/create_draft", community);
  }),
  publishCommunityRequest: thunk((actions, communityData) => {
    const data = Number.isInteger(parseInt(communityData)) ? { id: communityData } : communityData;
    const community = toFormData("community", data);

    return requester.patch(`/${data.id}/publish`, community);
  }),
  updateCommunity: thunk((actions, communityData) => {
    const url = `/${communityData.id}`;
    const community = toFormData("community", communityData);

    return requester.patch(url, community);
  }),
  getManagedCommunities: thunk(({ normalize, setManagedIDs }, status = "") => {
    const params = { serializer: "card", status };
    return usersRequester.get("/current/managed_communities", params).then(({ communities }) => {
      const ids = normalize({ data: communities, entityName: "community" });
      setManagedIDs(ids);
    });
  }),
  getUnclaimedSeasons: thunk(({ setUnclaimedSeasons }, communityID) => {
    return requester
      .get(`/${communityID}/earnings_unclaimed_seasons`)
      .then(({ seasons }) => setUnclaimedSeasons(seasons));
  }),
  setUnclaimedSeasons: action((state, seasons) => {
    state.unclaimedSeasons = seasons;
  }),
  setManagedIDs: action((state, ids) => {
    state.managedCommunityIDs = ids;
  }),
  deleteDraft: thunk((actions, id) => {
    const url = `/${id}`;

    return requester.delete(url);
  }),
};

const listeners = {
  onLogOut: actionOn(
    (_, actions) => actions.authentication.logout,
    (state) => {
      state.rolesCommunityID = undefined;
      state.role = EMPTY_BUT_FETCHED;
    }
  ),
};

const community = {
  ...attributes(),
  ...computedProps,
  ...actions,
  ...listeners,
};

export default community;
