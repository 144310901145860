import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import { normalize } from "@utils/socialUtils";
import styled from "styled-components";
import { PaddedButton } from "../common";
import ConnectionButton from "./SocialButtons/ConnectionButton";
import ConnectionButtons from "./SocialButtons/ConnectionButtons";

export const discourseStats = ({ discourseConnections, countByApp }) => {
  const discourseKeys = Object.keys(discourseConnections);
  const nonConnectedKeys = discourseKeys.filter((key) => !discourseConnections[key]);
  const connectedCount = discourseKeys.length - nonConnectedKeys.length;
  const pendingWaysCount = nonConnectedKeys
    .map((key) => normalize(key).appName)
    .reduce((acumulator, key) => acumulator + (countByApp[key] || 0), 0);

  return { connectedCount, pendingWaysCount, networksCount: discourseKeys.length };
};

const ConnectStep = ({ goToStep, ...props }) => {
  const override = () => goToStep(1);
  const { connectedCount, pendingWaysCount, networksCount } = discourseStats(props);
  const allForumsConnected = connectedCount === networksCount;
  const forumsBtnText = allForumsConnected ? "Discussion Forum" : "Discourse Discussion Forums";
  const connectedForumsText = `${connectedCount} of ${networksCount} forums connected`;
  const helperForumsText =
    allForumsConnected || pendingWaysCount === 0
      ? connectedForumsText
      : `Opens ${pendingWaysCount} more ways to contribute (${connectedForumsText})`;

  return (
    <>
      <Text bold typography="Heading/Large" spacing="mt-5 mx-a">
        Connect more networks for more rewards
      </Text>
      <Content color="02Primary500" sm-spacing="px-4">
        <Text textAlign="center" spacing="mb-4">
          Connect to verify your identity on other networks you use to contribute. Verified identities are never
          exposed.
        </Text>

        <ConnectionButtons {...props}>
          <ConnectionButton
            social="discourse"
            displayName={forumsBtnText}
            override={override}
            connected={allForumsConnected}
            canDisconnect={false}
            multipleConnection
            onModal
          />
          <Text align="center" color="#1C2942" bold>
            {helperForumsText}
          </Text>
        </ConnectionButtons>

      </Content>
    </>
  );
};

export const Footer = ({ closeModal, override, text }) => {
  const onClick = override || closeModal;
  return (
    <Box height="116px" bgColor="#81C394" flex justifyContent="center" alignItems="center">
      <PaddedButton
        onClick={onClick}
        typography="body/medium"
        bgColor="#FFFFFF"
        color="02Primary700"
        kind="outline"
        maxWidth="396px"
        height="56px"
        variant="#5FB974"
        borderColor="02Primary0"
        hoverBackground="02PrimaryGrey100"
        hoverBorderColor="02PrimaryGrey100"
        hoverColor="02Primary500"
        sm-spacing="mx-5+1"
      >
        {text}
      </PaddedButton>
    </Box>
  );
};

Footer.defaultProps = {
  text: "Keep Contributing",
};

const Content = styled(Box)`
  padding: 1rem 40px 0;
  @media ${breakpoints.mobile} {
    padding-left: 0px;
    padding-right: 0px;

    & > ${Text} {
      text-align: left;
    }
  }
`;

ConnectStep.Footer = Footer;

export default ConnectStep;
