import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { Link } from "../modals/common";
import { SubHeader, Wrapper, WrapperBox } from "./common";

const TermsPrivacy = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <Wrapper>
      <WrapperBox>
        <Box flex alignItems="center" pointer spacing="mb-4+1 " onClick={goBack}>
          <IconSvg icon="arrow-left" />
          <Text spacing="ml-3" bold>
            Back to Previous Page
          </Text>
        </Box>
        <Text typography="heading/large" center spacing="mb-4">
          ThriveCoin
        </Text>
        <Text typography="heading/medium" center spacing="mb-6">
          Privacy Notice
        </Text>
        <Text typography="body/large" extraBold spacing="mb-4">
          Last Updated: July 4, 2023
        </Text>

        <Text size="16px" color="01Primary700" lineHeight="26px">
          Welcome! This Privacy Notice explains how ThriveCoin Inc. (<b>“ThriveCoin”, “Company”, “we”, “us”,</b> or{" "}
          <b>“our”</b>) collects, uses, discloses, and otherwise processes personal information in connection with our
          websites, including [Insert URL] and other websites we own and operate that link to this Privacy Notice
          (collectively, the <b>“Sites”</b>), our ThriveCoin mobile applications (the <b>“Apps”</b>) and the related
          content, platform, services, products, and other functionality offered on or through our online services
          (collectively, the <b>“Services”</b>). It does not address our privacy practices relating to ThriveCoin
          employees and other personnel.
        </Text>
      </WrapperBox>
      <WrapperBox>
        <SubHeader>Blockchain Participants:</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          Please note that by virtue of the public nature of the blockchain, including the Celo blockchains, the
          holdings and transactions associated with your crypto wallet address will be publicly available and
          accessible to third parties. This includes, but is not limited to, your public sending address, the public
          address of the receiver, the amount sent or received, and any other data a user has chosen to include in a
          given transaction. Information stored on the blockchain may not be able to be modified or deleted due to the
          immutable nature of the blockchain. Transactions and addresses may reveal information about the user’s
          identity and information can potentially be correlated now or in the future by any party who chooses to do so.
          Please review how privacy and transparency on the blockchain works.
        </Text>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>1.OUR COLLECTION OF PERSONAL INFORMATION</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          We collect personal information in connection with your visits to and use of the Service. This collection
          includes information that you provide in connection with the Service, information from third parties, and
          information that is collected automatically such as through the use of cookies and other technologies.
        </Text>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>Personal Information Collected from Individuals</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          We may collect the following personal information submitted to us by individuals through the Services:
        </Text>
        <br />
        <List>
          <li>
            {" "}
            - {}
            <b>Account and Profile Information.</b> To use certain portions of the Services, you may be required to
            maintain an account with us. To create an account, we collect your email address, username, and password. We
            also collect your public blockchain address associated with your crypto wallet. If you are a Poster of a
            Request (each as defined in the Terms of Service), we may also collect additional payment information.
            Please note we utilize third-party providers to process payments on our behalf and do not accept payment
            directly through our Services. Once you create an account, you may also be able to create a profile that
            other users can see. Your profile may include information such as your twitter handle, profile photo, URLs
            to your personal accounts (i.e., website, GitHub, blog) and Crypto Wallet information. Note that information
            included in your profile, along with any Submissions you make, and other preferences and information
            included in your profile will be visible to all other individuals using the Services. We use this
            information to administer your account, provide you with the relevant services and information, communicate
            with you regarding your account, the Service, and for customer support purposes.
          </li>
          <li>
            - {}
            <b>Communications.</b> If you communicate with us through any paper or electronic form (e.g., Discord, Twitter, a “contact us”
            form, email), we may collect your name, username, social media handle,, crypto wallet public address, email address, phone number, or any other personal information you
            choose to provide to us. We use this information to investigate and respond to your inquiries, and to
            communicate with you, to enhance the services we offer to our users and to manage and grow our organization.
          </li>
          <li>
            - {}
            <b> Marketing Emails.</b> If you sign up for our newsletter, we collect your email address in order to send
            you regular updates about the Service, such as information about new features of the Service or Rewards
            available. We use this information to manage our communications with you and send you information about
            products and services we think may be of interest to you. If you wish to stop receiving email messages from
            us, simply click the “unsubscribe link” provided at the bottom of the email communication. Note that you
            cannot unsubscribe from certain services-related email communications (e.g., account verification,
            confirmations of transactions, technical or legal notices).
          </li>
          <li>
            - {}
            <b>User Content.</b> You may upload, or transmit audio, videos, images, data, or information through your
            communications with us or as part of a Submission made when you complete a Request, or otherwise use the
            Service (collectively, "User Content"). We may collect this information, and information about this content,
            such as the date and time you created this content, along with other information about you, including your username,
            social media handle and/or crypto wallet information. When you make a Submission, the content of your
            Submissions and your social media handle may be publicly visible on your profile. Please remember that
            ThriveCoin may, but has no obligation to, monitor, record, and store User Content in order to protect your
            safety or the safety of other users, to assist with regulatory or law enforcement efforts, or to protect and
            defend our rights and property. Each Poster is responsible for managing their own Communities. <br /> For
            more information, please see our {}
            <Link semiBold to={"/terms_services"}>
              Terms of Service
            </Link>
          </li>
          <li>
            - {}
            <b>Employment or Contractor Application Information,</b>  including information provided when you enter a ThriveCoin Contribution or Challenge, or information included in any questions submitted through surveys.
          </li>
          <li>
            - {}
            <b>Employment or Contractor Application Information,</b> including your contact and demographic information, educational
            and work history, employment interests, information obtained during interviews and any other information you
            choose to provide, if you apply for employment or to work as an independent contractor.
          </li>
        </List>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>Personal Information Automatically Collected</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          As is true of most digital platforms, we and our third-party providers may also collect personal information
          from an individual’s device, browsing actions and website usage patterns automatically when visiting or
          interacting with our Services, which may include <b>log data </b>(such as internet protocol (IP) address,
          operating system, browser type, browser id, the URL entered and the referring page/campaign, date/time of
          visit, the time spent on our Services and any errors that may occur during the visit to our Services),{" "}
          <b>analytics data</b> (such as the electronic path taken to our Services, through our Services and when
          exiting our Services, as well as usage and activity on our Services such as user list, roles, image,
          description, tags, and information related to a Request such as the description, reward amount, access terms,
          release terms) and <b>location data </b>(such as general geographic location based on the log data we or our
          third-party providers collect). <br /> <br /> When you access our Sites from a mobile device, we may collect
          unique identification numbers associated with your device or our mobile application (including, for example, a
          UDID, Unique ID for Advertisers (“IDFA”), Google AdID, or Windows Advertising ID), mobile carrier, device
          type, model and manufacturer, mobile device operating system brand and model, phone number, and depending on
          your mobile device settings, your geographical location data, including GPS coordinates (e.g., latitude and/or
          longitude) or similar information regarding the location of your mobile device, or we may be able to
          approximate a device’s location by analyzing other information, like an IP address. <br /> <br />
          We and our third-party providers may use (i) cookies or small data files that are stored on an individual’s
          computer and (ii) other, related technologies, such as web beacons, pixels, embedded scripts,
          location-identifying technologies and logging technologies (collectively, <b>“cookies”</b>) to automatically
          collect this personal information. For example, our Sites use Google Analytics, a web analytics service
          provided by Google LLC (<b>“Google”</b>), to collect and view reports about the traffic on our Sites. More
          information about the use of Google Analytics for these analytical and non-advertising purposes can be
          obtained by visiting Google’s privacy policy{" "}
          <Link semiBold to="https://policies.google.com/technologies/partner-sites">
            here
          </Link>{" "}
          and Google’s currently available opt-out options are available{" "}
          <Link semiBold to="https://tools.google.com/dlpage/gaoptout">
            here
          </Link>
          <br /> <br />
          To manage cookies, an individual may change their browser settings to: (i) notify them when they receive a
          cookie, so the individual can choose whether or not to accept it; (ii) disable existing cookies; or (iii)
          automatically reject cookies. Please note that doing so may negatively impact an individual’s experience using
          our Services, as some features and offerings may not work properly or at all. Depending on an individual’s
          device and operating system, the individual may not be able to delete or block all cookies. In addition, if an
          individual wants to reject cookies across all browsers and devices, the individual will need to do so on each
          browser on each device they actively use. An individual may also set their email options to prevent the
          automatic downloading of images that may contain technologies that would allow us to know whether they have
          accessed our email and performed certain functions with it. <br /> <br />
          For more information on our and third-party partners’ data collection and online advertising practices, please
          see the{" "}
          <Link semiBold to="#">
            Third Party Data Collection and Online Advertising
          </Link>{" "}
          section of this Privacy Notice.
        </Text>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>Personal Information from Third Parties</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          We also obtain personal information from third parties; which we often combine with personal information we
          collect either automatically or directly from an individual.
          <br />
        </Text>
        <List>
          <li>
            {" "}
            - {}
            <b>Other Users or Individuals who Interact with our Services:</b> We may receive your information from other
            users or other individuals who interact with our Services. For example, if you engage in one of our
            communicates hosted on third-party platforms, such as Discord, we will be able to see any public
            communications made within that platform.
          </li>

          <li>
            {" "}
            - {}
            <b>Business Partners:</b> We may receive your information from our business partners, such as companies that
            offer their products and/or services on our Services.
          </li>

          <li>
            {" "}
            - {}
            <b> Social Media or Third-party Platforms:</b> When an individual interacts with our Services through
            various social media networks and third-party platforms, we may collect information publicly shared such as
            your username or handle. Individuals should always review and, if necessary, adjust their privacy settings
            on third-party websites and social media networks and communications platforms before sharing information.
          </li>

          <li>
            {" "}
            - {}
            <b>Crypto Wallets:</b> When you connect your crypto wallets such as MetaMask, we receive information such as
            your wallet address, crypto wallet types (including amounts and balances), and any related transaction and
            technical information associated with your crypto wallet address such as network information regarding
            transactions. We use this information to administer your account, provide you with the relevant Services.
          </li>

          <li>
            {" "}
            - {}
            <b>Single Sign-On:</b> We may use single sign-on (“SSO”) to allow a user to authenticate their account using
            one set of login information, including through Google. We will have access to certain information from
            those third parties in accordance with the authorization procedures determined by those third parties, which
            may include, for example, your name, username, email address, language preference, and profile picture. We
            use this information to operate, maintain, and provide to you the features and functionality of the Service.
            We may also send you service-related emails or messages (e.g., account verification, customer support,
            changes, or updates to features of the Site, technical and security notices).
          </li>

          <li>
            {" "}
            - {}
            <b>Service Providers:</b> Our service providers that perform services solely on our behalf, such as survey
            and marketing providers and payment processors, collect personal information and often share some or all of
            this information with us.
          </li>

          <li>
            {" "}
            - {}
            <b>Information from Other Sources:</b> We may also collect personal information about individuals that we do
            not otherwise have from, for example, publicly available sources, third-party data providers, other
            customers, third-party digital wallet providers, or through transactions such as mergers and acquisitions.
            Such information may include contact information, employment-related information, and interest-in-services
            information. We may combine this information with the information we collect from an individual directly. We
            use this information to provide the Services, contact individuals, to process employment applications and
            job applicant screening, to send advertising or promotional materials or to personalize our Services and to
            better understand the demographics of the individuals with whom we interact.
          </li>
        </List>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>2. {} OUR USE OF PERSONAL INFORMATION</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          We use personal information we collect to:
        </Text>
        <List>
          <li>
            {" "}
            - {}
            Fulfill or meet the reason the information was provided, such as to fulfill our contractual obligations, to
            deliver the Services;
          </li>

          <li>
            {" "}
            - {}
            Manage our business and its day-to-day operations;
          </li>

          <li>
            {" "}
            - {}
            Authenticate your account credentials and identify you, as necessary to log you in and/or ensure the
            security of your account;
          </li>

          <li>
            {" "}
            - {}
            Communicate with individuals, including via email and social media;
          </li>
          <li>
            {" "}
            - {}
            Request individuals to complete surveys about our company, companies we partner with, and the Services;
          </li>

          <li>
            {" "}
            - {}
            For marketing and advertising purposes, including to market to you or offer you through email, direct mail,
            and updates on products or services we think that you may be interested in;
          </li>

          <li>
            {" "}
            - {}
            Enable you to communicate and share files with users you designate;
          </li>

          <li>
            {" "}
            - {}
            Test, enhance, update and monitor the Services, or diagnose or fix technology problems;
          </li>

          <li>
            {" "}
            - {}
            Facilitate customer benefits and services, including customer support through our command center services;
          </li>

          <li>
            {" "}
            - {}
            Identify and analyze how individuals use our Services;
          </li>

          <li>
            {" "}
            - {}
            Conduct research and analytics on our customer and user base and our Services;
          </li>

          <li>
            {" "}
            - {}
            Improve and customize our Services to address the needs and interests of our user base and other individuals
            we interact with;
          </li>

          <li>
            {" "}
            - {}
            Help maintain the safety, security and integrity of our property and Services, technology assets and
            business;
          </li>

          <li>
            {" "}
            - {}
            Evaluate your candidacy for employment or to work as an independent contractor, to communicate with you during the application process and if successful, to
            facilitate the onboarding process.
          </li>

          <li>
            {" "}
            - {}
            Fulfill or enforce our legal or contractual obligations and requirements (including in relation to our Terms
            of Service), to resolve disputes, to carry out our obligations and enforce our rights, and to protect our
            business interests and the interests and rights of third parties;
          </li>

          <li>
            {" "}
            - {}
            Audit transactions conducted in connection with our services;
          </li>

          <li>
            {" "}
            - {}
            Prevent, investigate or provide notice of fraud or unlawful or criminal activity;
          </li>

          <li>
            {" "}
            - {}
            For any other lawful purpose, or other purpose that you consent to or for which you provide your
            information.
          </li>
        </List>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          Where you choose to contact us, we may need additional information to fulfill the request or respond to
          inquiries. We may provide you with additional privacy-related information where the scope of the
          inquiry/request and/or personal information we require fall outside the scope of this Privacy Notice. In that
          case, the additional privacy notice will govern how we may process the information provided at that time.
        </Text>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>3. {} OUR DISCLOSURE OF PERSONAL INFORMATION</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          We disclose personal information in the following ways:
        </Text>
        <List>
          <li>
            {" "}
            - {}
            <b>Affiliates</b>. We may share personal information with other companies owned or controlled by ThriveCoin,
            and other companies owned by or under common ownership as ThriveCoin, which also includes our subsidiaries
            (i.e., any organization we own or control) or our ultimate holding company (i.e., any organization that owns
            or controls us) and any subsidiaries it owns, particularly when we collaborate in providing the Services.
          </li>

          <li>
            {" "}
            - {}
            <b>Blockchain Participants:</b> If you participate in our Services, by virtue of the public nature of the
            blockchain, the holdings and transactions associated with your crypto wallet address will be publicly
            available and accessible to blockchain participants and other third parties, as well as any other
            information you choose to provide or make public.
          </li>

          <li>
            {" "}
            - {}
            <b>Contests, Sweepstakes, and Survey Providers:</b> We share personal information with third parties who
            assist us in delivering our contests, sweepstakes, or survey offerings and processing the responses.
          </li>

          <li>
            {" "}
            - {}
            <b>Other Users of the Service:</b> We may share your information with other users in order to facilitate the
            Services. For example, if you complete a Submission, we may share your Submission with the relevant Posters.
          </li>

          <li>
            {" "}
            - {}
            <b>Marketing Providers:</b> We coordinate and share personal information with our marketing providers in
            order to communicate with individuals about the Services we make available.
          </li>

          <li>
            {" "}
            - {}
            <b>Customer Service and Communication Providers:</b> We share personal information with third parties who
            assist us in providing our customer services and facilitating our communications with individuals that
            submit inquiries.
          </li>

          <li>
            {" "}
            - {}
            <b>Other Service Providers:</b> In addition to the third parties identified above, we engage other
            third-party service providers that perform business or operational services for us or on our behalf, such as
            website hosting, infrastructure provisioning, IT services, analytics services, conducting crypto
            transactions, employment application-related services, payment processing services, and administrative
            services.
          </li>

          <li>
            {" "}
            - {}
            <b>Business Transaction or Reorganization:</b> We may take part in or be involved with a corporate business
            transaction, such as a merger, acquisition, joint venture, or financing or sale of company assets. We may
            disclose personal information to a third party during negotiation of, in connection with or as an asset in
            such a corporate business transaction. Personal information may also be disclosed in the event of
            insolvency, bankruptcy or receivership.
          </li>

          <li>
            {" "}
            - {}
            <b>Legal Obligations and Rights:</b> We may disclose personal information to third parties, such as legal
            advisors and law enforcement:
          </li>
          <ListCircle>
            <li>in connection with the establishment, exercise, or defense of legal claims;</li>

            <li>to comply with laws or to respond to lawful requests and legal process;</li>

            <li>
              to protect our rights and property and the rights and property of others, including to enforce our
              agreements and policies;
            </li>

            <li>to detect, suppress, or prevent fraud;</li>

            <li>to protect the health and safety of us and others; or</li>

            <li>as otherwise required by applicable law.</li>
          </ListCircle>
          <li>
            {" "}
            - {}
            <b>With Your Consent:</b> We may disclose personal information about an individual to certain other third
            parties or publicly with their consent or direction. For example, with an individual’s consent or direction
            we may post their testimonial on our Sites or service-related publications.
          </li>
        </List>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>4. {} THIRD-PARTY TRACKING AND ONLINE ADVERTISING</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          We may share, or we may permit third-party online advertising networks, social media companies, and other
          third-party services, to collect information about your use of our Site over time so that they may play or
          display ads that may be relevant to your interests on other websites or apps, or on other devices you may use.
          Typically, though not always, the information we share is provided through cookies or similar tracking
          technologies, which recognize the device you are using and collect information, including hashed data, click
          stream information, browser type, time and date you visited the site, and other information. This information
          is used to display targeted ads on or through our services or on other websites, apps, or services. We or the
          online advertising networks use this information to make the advertisements you see online more relevant to
          your interests. We may also display targeted advertising to you through social media platforms, such as
          Facebook, Twitter, Google and others. These companies have interest-based advertising programs that allow us
          to direct advertisements to users who have shown interest in our services while those users are on the social
          media platform, or to groups of other users who share similar traits, such as likely commercial interests and
          demographics. These advertisements are governed by the privacy policies of those social media companies that
          provide them.
          <br />
          <br />
          To learn more about interest-based advertising and how you may be able to opt-out of some of this advertising,
          you may wish to visit the Digital Advertising Alliance’s (DAA) resources and/or the Network Advertising
          Initiative’s (NAI) online resources, at www.aboutads.info/choices or
          http://www.networkadvertising.org/choices/ and you can opt-out of receiving some interest-based advertisements
          on mobile apps by visiting http://www.aboutads.info/appchoices. You may also be able to limit interest-based
          advertising through the settings menu on your mobile device by selecting “limit ad tracking” (iOS) or “opt-out
          of interest-based ads” (Android).
          <br />
          <br />
          Please note that when you opt out of receiving interest-based advertisements, this does not mean you will no
          longer see advertisements from us or on our online services. It means that the online ads that you do see from
          DAA program participants should not be based on your interests. We are not responsible for the effectiveness
          of, or compliance with, any third-parties’ opt-out options or programs or the accuracy of their statements
          regarding their programs. In addition, third parties may still use cookies to collect information about your
          use of our online services, including for analytics and fraud prevention as well as any other purpose
          permitted under the DAA’s Principles.
          <br />
          <br />
          <b>Google Analytics and Advertising</b>. We may also utilize certain forms of display advertising and other
          advanced features through Google Analytics, such as Remarketing with Google Analytics, Google Display Network
          Impression Reporting, and Google Analytics Demographics and Interest Reporting. These features enable us to
          use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick
          advertising cookie) or other third-party cookies together to inform, optimize, and display ads based on your
          past visits to our services. You may control your advertising preferences or opt-out of certain Google
          advertising products by visiting the Google Ads Preferences Manager, currently available at
          https://google.com/ads/preferences, or by vising NAI’s online resources at
          http://www.networkadvertising.org/choices.
        </Text>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>5. {} CONTROL OVER YOUR INFORMATION</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          You may control your information in the following ways:
        </Text>

        <List>
          <li>
            {" "}
            - {}
            <b>Email Communications Preferences.</b> You can stop receiving promotional email communications from us by
            clicking on the “unsubscribe” link provided in such communications. You may not opt-out of service-related
            communications (e.g., account verification, transactional communications, changes/updates to features of the
            Services, technical and security notices).
          </li>

          <li>
            {" "}
            - {}
            <b>Modifying or Deleting Your Information.</b> If you have any questions about reviewing, modifying, or
            deleting your information, you can contact us directly at [INSERT]. We may not be able to modify or delete
            your information in all circumstances, especially in relation to information on the blockchain.
          </li>

          <li>
            {" "}
            - {}
            <b>Cookies:</b> For information about our and our third-party partners’ use of cookies and related
            technologies to collect information automatically, and any choices you may have in relation to cookies,
            please see the <b>Information We Collect Automatically</b> section above.
          </li>

          <li>
            {" "}
            - {}
            <b>Online Advertising. </b> For information about any choices you may have in relation to online advertising
            please see the <b>Third Party Tracking and Online Advertising</b> section above.
          </li>
        </List>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>6. {} CHILDREN’S PERSONAL INFORMATION</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          Our Services are not directed to, and we do not intend to, or knowingly, collect or solicit personal
          information from children under the age of 13. If an individual is under the age of 13, they should not use
          our Services or otherwise provide us with any personal information either directly or by other means. If a
          child under the age of 13 has provided personal information to us, we encourage the child’s parent or guardian
          to contact us to request that we remove the personal information from our systems. If we learn that any
          personal information we collect has been provided by a child under the age of 13, we will promptly delete that
          personal information.
        </Text>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>7. LINKS TO THIRD-PARTY WEBSITES OR SERVICES</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          Our Services may include links to third-party websites, plug-ins and applications. Except where we post, link
          to or expressly adopt or refer to this Privacy Notice, this Privacy Notice does not apply to, and we are not
          responsible for, any personal information practices of third-party websites and online services or the
          practices of other third parties. To learn about the personal information practices of third parties, please
          visit their respective privacy notices.
        </Text>
      </WrapperBox>

   <WrapperBox>
        <SubHeader>8. {} NOTICE TO CALIFORNIA RESIDENTS </SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
        If you are a resident of the State of California, California law may provide you with additional rights regarding our use of your personal information. To learn more about your California privacy rights, visit the California Consumer Privacy Act Privacy Notice here. California's "Shine the Light" law (Civil Code Section § 1798.83) permits users of our App that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please contact us at support@thrivecoin.com with the subject line “California Opt Out Request” to submit such a request.

        </Text>
      </WrapperBox>
      
      <WrapperBox>
        <SubHeader>9. {} NOTICE TO NEVADA RESIDENTS</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          If you are a resident of the State of Nevada, Chapter 603A of the Nevada Revised Statutes permits a Nevada
          resident to opt out of future sales of certain covered information that a website operator has collected or
          will collect about the resident. Although we do not currently sell covered information, please contact us at
          social@thrivecoin.com with the subject line “Nevada Opt Out Request” to submit such a request.
        </Text>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>10. {} UPDATES TO THIS PRIVACY NOTICE</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          We will update this Privacy Notice from time to time. When we make changes to this Privacy Notice, we will
          change the date at the beginning of this Privacy Notice. If we make material changes to this Privacy Notice,
          we will notify individuals by email to their registered email address, by prominent posting on our Services,
          or through other appropriate communication channels. All changes shall be effective from the date of
          publication unless otherwise provided.
        </Text>
      </WrapperBox>

      <WrapperBox>
        <SubHeader>11. {} CONTACT US</SubHeader>
        <Text size="16px" color="01Primary700" lineHeight="26px">
          If you have any questions or requests in connection with this Privacy Notice or other privacy-related matters,
          please send an email to social@thrivecoin.com.
        </Text>
      </WrapperBox>
    </Wrapper>
  );
};

const List = styled.ul`
  margin: 0 0 0 -18px;
  list-style-type: none;
  li {
    margin-bottom: 8px;
  }
`;

const ListCircle = styled.ul`
  margin: 0 0 0 -18px;
  list-style-type: circle;
  li {
    margin-bottom: 8px;
  }
`;

export default TermsPrivacy;
