import { useEffect, useRef, useState } from "react";
import debounce from "../../utils/debounce";

export const TAGS = [
  { value: "All", label: "All Contributors", selected: true },
  { value: "user_id", label: "Just Me", addLine: true },
  { value: "filter_top_ten", label: "Top 10" },
  { value: "filter_top_hundred", label: "Top 100" },
];

const useTagFilters = (sortOptions, confirmed) => {
  const modifiedTags = confirmed ? TAGS : TAGS.filter((tag) => tag.value !== "user_id");
  const [allTags, setTags] = useState(modifiedTags);
  const [tagIds, setSelected] = useState([]);

  const ref = useRef(false);

  const onTagClick = (event) => {
    ref.current = true;
    const { value } = event.target.dataset;
    setSelected((tagIds) => {
      const toAssign = tagIds.includes(value) ? undefined : value;
      allTags.forEach((item) => (item.selected = item.value == value));
      setTags((tags) =>
        tags.map((tag) => {
          tag.selected = tag.value == toAssign;
          return tag;
        })
      );
      setSelected([toAssign]);
    });
  };

  const _onTextChange = (text) => {
    ref.current = true;
    sortOptions.onChange(text);
  const updatedTags = allTags.map((tag) => ({
    ...tag,
    selected: tag.value === TAGS[0].value,
  }));
  
  setTags(updatedTags);
  };

  const onTextChange = debounce(_onTextChange, 3000);

  useEffect(() => {
    const tags = [...modifiedTags];
    tags.forEach((tag) => (tag.onClick = onTagClick));

    setTags(tags);
  }, [confirmed]);

  return {
    tagIds,
    persistentOptions: allTags,
    onTextChange,
  };
};

export default useTagFilters;
