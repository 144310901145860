import { connectProvider, doLogout, loginUser } from "@thrivecoin/thc-auth-js";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment/moment";
import getENSData from "../../utils/getENSData";
import { DISCORD_KEY } from "../DiscordSignUpPage";

const ERROR_MESSAGES = {
  4001: "Accept the connection to see earned tokens in your wallet provider.",
  internal_error: "Something went wrong while communicating with your wallet provider! Please notify us!",
};

const SIGN_IN_ERROR = {
  4001: "You must sign the message in your wallet provider in order to log in",
  422: "Something went wrong while logging you in! Please contact support@thrivecoin.com!",
};

const LOGIN_TIMEOUT = 20 * 1000;

const useAuthentication = () => {
  const { isMobile } = useStoreState((state) => state.media);
  const { authLoading } = useStoreState((state) => state.authentication);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const { dangerToast, infoToast, clear: clearToasts } = useStoreActions((actions) => actions.toasts);
  const { setAuthLoading, processUser, logout: appLogout } = useStoreActions((state) => state.authentication);

  const signInErrorHandler = ({ code, message, response }) => {
    const status = response?.status || "";
    let content = SIGN_IN_ERROR[code] || SIGN_IN_ERROR[status] || ERROR_MESSAGES["internal_error"];
    if (status === 422 && response.data.message) {
      content = response.data.message;
    }
    dangerToast({ content, timeout: 5000 });

    return Promise.reject(message || content);
  };

  const getProvider = async (name) => connectProvider(name.toLowerCase());

  const _loginProcess = async (referral_code, provider, providerName) => {
    if (authLoading) {
      return;
    }
    setAuthLoading(true);

    const discord_generated_uid = localStorage.getItem(DISCORD_KEY);
    if (!provider) {
      provider = await getProvider(providerName.toLowerCase());
    }

    mobileSignatureNotice();
    const { user, profile } = await loginUser(provider, referral_code, discord_generated_uid);
    localStorage.removeItem(DISCORD_KEY);
    processUser({ user });

    afterCreate(user.created_at, profile.address);
    ////////////////////////////////////
  };

  const loginProcess = (...args) => {
    let timeout;

    return new Promise((resolve, reject) => {
      timeout = setTimeout(() => {
        reject({ code: 4001, message: "The login flow has timed out." });
      }, LOGIN_TIMEOUT);

      _loginProcess(...args)
        .then(resolve)
        .catch(reject);
    })
      .catch((error) => {
        doLogout();
        signInErrorHandler(error);
      })
      .finally(() => {
        clearTimeout(timeout);
        setAuthLoading(false);
      });
  };

  const mobileSignatureNotice = () => {
    if (isMobile) {
      infoToast({
        content: "Complete the signature request in your wallet provider to finish Signing In.",
      });
    }
  };

  const afterCreate = async (created_at, address) => {
    const minutesSinceCreated = moment().diff(moment(created_at), "m");
    if (minutesSinceCreated < 10) {
      const ensData = await getENSData(address);
      if (ensData) {
        showModal({ modalName: "UpdateProfileModal", modalOrder: 9, profile: ensData, provider: "ENS" });
      }
      showModal({ modalName: "ConnectSocialMediasModal" });
    }
  };

  const loginFlow = (referral_code) => {
    const callback = (...args) => loginProcess(...args);

    showModal({ modalName: "WalletConnectorModal", afterClose: callback, referral_code });
  };

  const logout = async () => {
    doLogout().then(() => {
      appLogout();
    });
  };

  return { loginFlow, logout, getProvider };
};

export default useAuthentication;
