let CONFIG = {
  snapshot: {
    appName: "snapshot_app",
    social: "snapshot",
    provider: "snapshot",
    displayName: "Snapshot",
  },
  discord: {
    appName: "discord_app",
    social: "discord",
    provider: "discord",
    displayName: "Discord",
  },
  email: {
    appName: "email_app",
    social: "email",
    provider: "email",
    displayName: "Email",
  },
  twitter: {
    appName: "twitter_app",
    social: "twitter",
    provider: "twitter",
    displayName: "Twitter",
  },
  github: {
    appName: "github_app",
    social: "github",
    provider: "github",
    displayName: "Github",
  },
  bankless_discourse: {
    appName: "banklessdao_discourse_app",
    social: "discourse",
    provider: "bankless_discourse",
    displayName: "Bankless DAO Discussion Forum",
  },
  apecoin_discourse: {
    appName: "apecoin_discourse_app",
    social: "discourse",
    provider: "apecoin_discourse",
    displayName: "ApeCoin Discussion Forum",
  },
  aavegotchi_discourse: {
    appName: "aavegotchi_discourse_app",
    social: "discourse",
    provider: "aavegotchi_discourse",
    displayName: "Aavegotchi Discussion Forum",
  },
  // particle_discourse: {
  //   appName: "particledao_discourse_app",
  //   social: "discourse",
  //   provider: "particle_discourse",
  //   displayName: "Particle DAO Discussion Forum",
  // },
  asana: {
    appName: "asana_app",
    social: "asana",
    provider: "asana",
    displayName: "Asana",
  },
  linkedin: {
    appName: "linkedin_app",
    social: "linkedin",
    provider: "linkedin",
    displayName: "Linkedin",
  },
  zoom: {
    appName: "zoom_app",
    social: "zoom",
    provider: "zoom",
    displayName: "Zoom",
  },
  gitcoin: {
    appName: "gitcoin_app",
    social: "gitcoin",
    provider: "gitcoin",
    displayName: "Gitcoin Passport",
  },
};

Object.keys(CONFIG).reduce((accumulator, key) => {
  const newKey = `${key}_app`;
  accumulator[newKey] = accumulator[key];
  return accumulator;
}, CONFIG);

CONFIG["banklessdao_discourse_app"] = CONFIG.bankless_discourse;

export const normalize = (appName) => CONFIG[appName];
