import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../Loader";
import useGitcoinRequirementCheck from "../hooks/useGitcoinRequirementCheck";
import useManageClaimingState from "../hooks/useManageClaimingState";
import useQuery from "../hooks/useQuery";
import BodySeason from "./BodySeason";
import HeroPart from "./Hero";

const SeasonPage = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const bodyRef = useRef(null);
  const { getActiveSeasonRequest, getSeasonDetails, reset } = useStoreActions((actions) => actions.season);
  const { getPersonalStatus, resetContributorDetails } = useStoreActions((actions) => actions.contributors);
  const { confirmed } = useStoreState((state) => state.user.user);
  const selectedIndex = parseInt(useQuery().get("tabIndex")) || 0;
  const [currentTabIndex, setCurrentTabIndex] = useState(selectedIndex);
  const { season } = useStoreState((state) => state.season);
  const { id } = useParams();
  const { raffle_id } = season;
  useGitcoinRequirementCheck(season.community_required_gitcoin_score);
  useManageClaimingState(season);

  const onRaffleDetails = () => navigate(`/raffles/${raffle_id}`);

  const scrollToBody = (e) => {
    e.stopPropagation();
    bodyRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const focusAboutTab = () => setCurrentTabIndex(1);

  useEffect(() => {
    getActiveSeasonRequest(id)
      .then(async () => {
        if (confirmed) {
          await getSeasonDetails(id);
        }
        setLoading(false);
      })
      .catch(() => navigate("/"));

    if (confirmed) {
      getPersonalStatus({ id, type: "seasons" });
    } else {
      resetContributorDetails();
    }

    return () => {
      reset();
      resetContributorDetails();
    };
  }, [id, confirmed]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <HeroPart
        scrollToBody={scrollToBody}
        focusAboutTab={focusAboutTab}
        season={season}
        onRaffleDetails={onRaffleDetails}
      />
      <div ref={bodyRef} className="background-container">
        <BodySeason
          currentTabIndex={currentTabIndex}
          setCurrentTabIndex={setCurrentTabIndex}
          season={season}
          onRaffleDetails={onRaffleDetails}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.background};
  overflow-x: hidden;
`;

export default SeasonPage;
