import createLoadingThunks from "../createLoadingThunks";
import activity from "./activity";
import authentication from "./authentication";
import challenges from "./challenges";
import commitChallenge from "./commitChallenge";
import community from "./community";
import contributionApps from "./contributionApps";
import contributions from "./contributions";
import contributors from "./contributors";
import entities from "./entities";
import listing from "./listing";
import listings from "./listings";
import media from "./media";
import members from "./members";
import modals from "./modals";
import notification from "./notification";
import notifications from "./notifications";
import pendingContributions from "./pendingContributions";
import preferences from "./preferences";
import raffle from "./raffle";
import rewards from "./rewards";
import season from "./season";
import services from "./services";
import tags from "./tags";
import toasts from "./toasts";
import user from "./user";
import wallets from "./wallets";

export default createLoadingThunks({
  activity,
  authentication,
  challenges,
  commitChallenge,
  community,
  contributionApps,
  contributions,
  contributors,
  entities,
  listing,
  listings,
  media,
  members,
  modals,
  notification,
  notifications,
  pendingContributions,
  preferences,
  raffle,
  rewards,
  season,
  services,
  tags,
  toasts,
  user,
  wallets,
});
