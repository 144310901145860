import { useStoreActions } from "easy-peasy";

const NOOP = () => {};

const useClaimInApp = (reward_id, closeModal = NOOP, afterClose = NOOP) => {
  const { claimRewardVirtuallyRequest } = useStoreActions((actions) => actions.challenges);
  const { successToast } = useStoreActions((actions) => actions.toasts);
  return () => {
    claimRewardVirtuallyRequest(reward_id)
      .then(closeModal)
      .then(afterClose)
      .finally(() => {
        successToast({
          content: "Your reward is processing and should appear within 24 hours",
        });
      });
  };
};

export default useClaimInApp;
