import TabWrapper from "@common/TabWrapper";
import { Box, breakpoints, Collapsible, MarkdownText, Text } from "@thrivecoin/ui-components";
import { formatDate } from "@utils/formatting";
import { textLinkify } from "@utils/text";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CollapsibleIcon from "./CollapsibleIcon";
import { faq, generateRulesContent } from "./data";

const collapsibleConfig = {
  paddingContainer: "19.98px 25.63px 24.02px 24.12px",
  paddingInside: "0 25.63px 40.6px 24.06px",
};

const RaffleFAQ = ({ raffle_id }) => {
  const { getRaffle } = useStoreActions((actions) => actions.raffle);
  const { raffle } = useStoreState((state) => state.raffle);
  const [activeId, setActiveId] = useState(null);

  const handleCollapsibleClick = (id) => {
    setActiveId((prevId) => (prevId === id ? null : id));
  };
  useEffect(() => {
    getRaffle({ id: raffle_id });
  }, []);

  return (
    <TabWrapper>
      <WrapperInside>
        <LeftCol>
          <Title typography="heading/small" color="01Primary700">
            Raffle Rules & FAQ
          </Title>
          <Text typography="body/medium" color="01Primary700">
            For each prize, one raffle entry will be randomly drawn as the winner. Winning raffle entries will be
            selected live during a virtual event on {formatDate(raffle.draw_date)}. A link to the event will become
            available on this page 4 hours before the event begins. Winners will be announced on this page.
          </Text>

          <Line height="1px" bgColor="01Primary100" />

          <StyledBox flex direction="column" gap="5px">
            <Text typography="body/small" color="01Primary700" bold>
              Community
            </Text>
            <Text typography="body/medium" color="01Primary700" extraBold>
              {raffle.community_name}
            </Text>
          </StyledBox>

          <StyledBox flex direction="column" gap="5px">
            <Text typography="body/small" color="01Primary700" bold>
              Season
            </Text>
            <Text typography="body/medium" color="01Primary700" extraBold>
              {raffle.season_number}
            </Text>
          </StyledBox>

          <StyledBox flex direction="column" gap="5px">
            <Text typography="body/small" color="01Primary700" bold>
              Raffle date
            </Text>
            <Text typography="body/medium" color="01Primary700" extraBold>
              {formatDate(raffle.draw_date)}
            </Text>
          </StyledBox>

          <Box flex direction="column" gap="5px">
            <Text typography="body/small" color="01Primary700" bold>
              Raffle Questions?
            </Text>
            <Text typography="body/medium" color="01Primary700" extraBold>
              support@thrivecoin.com
            </Text>
          </Box>
        </LeftCol>

        <RightCol>
          <Collapsible
            id="collapsible-1"
            onClick={handleCollapsibleClick}
            isActive={activeId === "collapsible-1"}
            title="Raffle Rules"
            IconComponent={CollapsibleIcon}
            {...collapsibleConfig}
          >
            <Box height="1px" bgColor="01Primary200" />
            <Box spacing="pt-5">
              {" "}
              <MarkdownText color="01Primary700">
                {generateRulesContent(raffle.community_name, raffle.season_number)}
              </MarkdownText>
            </Box>
          </Collapsible>

          <LineMiddle height="1px" bgColor="01Primary100" />

          {faq.map(({ title, description }) => {
            return (
              <Collapsible
                id={title}
                onClick={handleCollapsibleClick}
                isActive={activeId === title}
                key={title}
                title={title}
                IconComponent={CollapsibleIcon}
                {...collapsibleConfig}
              >
                {textLinkify(description)}
              </Collapsible>
            );
          })}
        </RightCol>
      </WrapperInside>
    </TabWrapper>
  );
};

const WrapperInside = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 24x;
  border-radius: 12px;
  padding: 32px;
  padding-bottom: 58px;
  background: ${({ theme }) => theme.colors["01Primary0"]};

  @media ${breakpoints.tablet} {
    padding: 34.74px 23px 24px 31.61px;
    gap: 30px;
  }
  @media ${breakpoints.miniTablet} {
    flex-direction: column;
    padding: 34.74px 23px 24px 31.61px;
    gap: 53.78px;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column;
    padding: 40.85px 22.99px 24px 29px;
  }
`;

const LeftCol = styled(Box)`
  width: 100%;
  max-width: 338px;

  @media ${breakpoints.tablet} {
    margin-bottom: 32px;
    max-width: 270px;
  }
  @media ${breakpoints.miniTablet} {
    margin-bottom: 0;
    max-width: unset;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 24px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 26.13px;
`;

const Line = styled(Box)`
  margin: 25px 0;
`;

const StyledBox = styled(Box)`
  margin-bottom: 17px;
`;

const RightCol = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 836px;
  margin-left: auto;
  @media ${breakpoints.miniTablet} {
    max-width: unset;
  }
`;

const LineMiddle = styled(Box)`
  margin: 12px 0;
`;

export default RaffleFAQ;
