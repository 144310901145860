import { Box, IconSvg, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";
import ConnectionButtons from "./ConnectionButtons";
import DiscourseButtons from "./DiscourseButtons";

const ProfileConnectionButtons = (props) => {
  const { discourseConnections } = props;
  const keys = Object.keys(discourseConnections);
  const connectionCount = keys.filter((key) => discourseConnections[key]).length;

  return (
    <>
      <ConnectionButtons {...props} />
      <Box>
        <Line height="1px" bgColor="01Primary100" />
        <Box flex gap="12px" alignItems="center" spacing="mb-6+2">
          <DiscourseBox flex justifyContent="center" alignItems="center">
            <IconSvg icon="discourse" color="white" />
          </DiscourseBox>
          <Text typography="body/large" bold color="01Primary700">
            {connectionCount} of {keys.length} Discourse Connections
          </Text>
        </Box>
        <DiscourseButtons {...props} />
      </Box>
    </>
  );
};

ProfileConnectionButtons.defaultProps = {
  canDisconnect: true,
  showCount: true,
};

const Line = styled(Box)`
  margin: 40px 0 32px 0;
`;

const DiscourseBox = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors["discourse"]};
`;
export default ProfileConnectionButtons;
