import { breakpoints } from "@thrivecoin/ui-components";
import { normalize } from "@utils/socialUtils";
import styled from "styled-components";
import { SOCIALS_NO_DISCORD } from "../../../constants";
import { WrapperButtons } from "../../modals/ConnectSocialMediasModal/SocialButtons/common";
import ConnectionButton from "./ConnectionButton";

const ConnectionButtons = ({ canDisconnect, countByApp, showCount, mt, children, onModal, ...props }) => {
  const buttons = SOCIALS_NO_DISCORD.map((social) => {
    const config = normalize(social);
    const connected = props[`${social}Connected`];
    const count = countByApp[config.appName];

    return (
      <ConnectionButton
        key={social}
        showCount={showCount}
        canDisconnect={canDisconnect}
        social="discord"
        connected={connected}
        count={count}
        onModal={onModal}
        {...config}
      />
    );
  });

  return (
    <StyledWrapperButtons mt={mt}>
      {buttons}
      {children}
    </StyledWrapperButtons>
  );
};

const StyledWrapperButtons = styled(WrapperButtons)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 29.24px;
  @media ${breakpoints.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${breakpoints.miniTablet} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${breakpoints.mobile} {
    grid-template-columns: 1fr;
    gap: 24px;
  }
`;

ConnectionButtons.defaultProps = {
  canDisconnect: true,
  showCount: true,
};

export default ConnectionButtons;
