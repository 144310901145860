import TabWrapper from "@common/TabWrapper";
import { Box, breakpoints, Collapsible, IconSvg, Text } from "@thrivecoin/ui-components";
import { numberWithCommas } from "@utils/formatting";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import useVirtualPageView from "../../hooks/useVirtualPageView";

const CONFIG = {
  discourse: { icon: "discourse", bgColor: "#59C2BC" },
  twitter: { icon: "twitter", bgColor: "twitter" },
  snapshot: { icon: "bolt", bgColor: "03Secondary500", strokeColor: "02Primary0" },
  discord: { icon: "discord", bgColor: "discord" },
  other: { icon: "globe2", bgColor: "01Primary700", fill: "01Primary0" },
};

const SECTIONS = ["about", "origin", "members", "vision", "impact", "get_involved"];

const CustomIcon = ({ isActive, hideIcon }) => {
  const iconElement = hideIcon ? null : <IconSvg color={"03Primary500"} width="18px" icon="chevron-down" />;
  return <IconWrapper isActive={isActive}>{iconElement}</IconWrapper>;
};

const AboutCommunity = () => {
  const { community } = useStoreState((state) => state.community);
  const { isTablet } = useStoreState((state) => state.media);
  const [activeId, setActiveId] = useState("collapsible-0");
  useVirtualPageView({ page: "/about" });

  const handleCollapsibleClick = (id) => {
    setActiveId((prevId) => (prevId === id ? null : id));
  };

  const socialLinks = !community?.social_links
    ? {}
    : typeof community.social_links !== "string"
      ? community.social_links
      : JSON.parse(community.social_links);

  const websiteLink = socialLinks && socialLinks["website"];

  const filtered_links = Object.entries(socialLinks)
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => {
      const config = CONFIG[key] || {};
      return (
        <Link key={key} href={value} target="_blank">
          <WrapSocial key={key} bgColor={config.bgColor} strokeColor={config.strokeColor} fill={config.fill}>
            {config.icon && <IconSvg icon={config.icon} width="16px" height="16px" />}
          </WrapSocial>
        </Link>
      );
    });

  const sections = SECTIONS.map((key, index) => {
    const data = community[key];
    const id = `collapsible-${index}`;
    const isActive = activeId === id;
    const title = key
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

    return (
      data && (
        <Collapsible
          title={title}
          id={id}
          onClick={handleCollapsibleClick}
          isActive={isActive}
          IconComponent={CustomIcon}
          className="collapsible"
        >
          <StyledHTML dangerouslySetInnerHTML={{ __html: data }} />
        </Collapsible>
      )
    );
  });

  return (
    <TabWrapper color="01Primary700">
      <WrapperInside>
        <LeftCol>
          <Title typography="heading/small" spacing="mb-6" md-spacing="mb-5">
            {community.name}
          </Title>
          <Box flex={isTablet} justifyContent="space-between">
            {!community.hide_amounts && (
              <Box flex direction="column" spacing="mb-4" gap="6px">
                <Text typography="body/small" color="01Primary700" bold>
                  Total Rewards Earned:
                </Text>
                <Text typography="body/large" extraBold>
                  {numberWithCommas(+community.contributions_stats?.rewards_distributed || 0)} $THRIVE
                </Text>
              </Box>
            )}
            <Box direction="column" flex spacing="mb-4" gap="6px">
              <Text typography="body/small" color="01Primary700" bold>
                Total Contributions:
              </Text>
              <Text typography="body/large" extraBold>
                {numberWithCommas(+community.contributions_stats?.contributions_made || 0)}
              </Text>
            </Box>

            <Box direction="column" flex spacing="mb-4+4" gap="6px">
              <Text typography="body/small" color="01Primary700" bold>
                Total Contributors:
              </Text>
              <Text typography="body/large" extraBold>
                {numberWithCommas(+community.contributions_stats?.contributors_count || 0)}
              </Text>
            </Box>
          </Box>
          {websiteLink && (
            <Box direction="column" flex spacing="mb-4" gap="6px">
              <Text typography="body/small" color="01Primary700">
                {" "}
                Website:
              </Text>
              <Link color="link" underline href={websiteLink}>
                {websiteLink}
              </Link>
            </Box>
          )}
          {filtered_links.length > 0 && (
            <Box direction="column" flex gap="24px">
              <Text typography="body/small" color="01Primary700">
                Get In Touch With Our Community:
              </Text>
              <Box flex gap="10px">
                {filtered_links}
              </Box>
            </Box>
          )}
        </LeftCol>

        <RightCol>{sections}</RightCol>
      </WrapperInside>
    </TabWrapper>
  );
};

const WrapSocial = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  svg {
    fill: ${({ theme, fill }) => (fill ? theme.colors[fill] : theme.colors["02Primary0"])};
    ${({ theme, strokeColor }) => strokeColor && `stroke: ${theme.colors[strokeColor]}`};
    path {
      fill: inherit;
    }
  }
`;

const Title = styled(Text)`
  margin-bottom: 40.81px;
`;

const WrapperInside = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 24x;
  border-radius: 12px;
  padding: 33.03px 32.94px 114.97px 32px;
  background: ${({ theme }) => theme.colors["01Primary0"]};

  @media ${breakpoints.tablet} {
    flex-direction: column;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

const LeftCol = styled(Box)`
  width: 100%;
  max-width: 338px;

  @media ${breakpoints.tablet} {
    margin-bottom: 32px;
    max-width: unset;
  }

  @media ${breakpoints.mobile} {
    margin-bottom: 24px;
  }
`;

const RightCol = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 21px;
  width: 100%;

  .collapsible > div {
    text-transform: capitalize;
  }
`;

const Link = styled.a`
  cursor: pointer;
  ${({ theme, color }) => color && `color: ${theme.colors[color]}`};
  ${({ underline }) => underline && "text-decoration: underline"};
`;

const IconWrapper = styled.div`
  min-width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors["03Primary500"]};

  ${({ isActive }) =>
    isActive &&
    ` transform: rotate(180deg);
      border: 1px solid ${({ theme }) => theme.colors["03Primary500"]};
    `}
`;

const StyledHTML = styled.div`
  & > * {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

export default AboutCommunity;
