import { Box, IconSvg, Panel, Text } from "@thrivecoin/ui-components";
import { useEffect } from "react";
import useWeb3 from "../../hooks/useWeb3";
import { Link, PaddedButton, useSkipOnboarding } from "../common";

function FeesPage() {
  const { addNetwork, isOnPolygon } = useWeb3();
  const skipOnboading = useSkipOnboarding();

  useEffect(() => {
    if (isOnPolygon) {
      skipOnboading();
    }
  }, [isOnPolygon]);

  return (
    <>
      <Text type="2.5" bold spacing="mt-2 mb-5">
        Want no transaction fees?
      </Text>

      <PolygonFees onClick={addNetwork} />

      <Link to="#" onClick={skipOnboading}>
        Skip for now
      </Link>
      <Box spacing="py-8" />
    </>
  );
}

export const PolygonFees = ({ onClick }) => (
  <>
    <PolygonFeesPanel />
    <Text spacing="mb-8">
      Take one extra step to add MetaMask support for Polygon ($MATIC), one of the world’s most traded cryptocurrencies.
    </Text>
    <PaddedButton onClick={onClick} spacing="mb-4" modal={true}>
      CONFIGURE POLYGON NETWORK
    </PaddedButton>
  </>
);

export const PolygonFeesPanel = () => (
  <Panel spacing="py-4 px-6 mb-7" bgColor="tertiary50">
    <Box flex spacing="mb-4">
      <Text align="end" width="190px" bold>
        Etherium ($ETH)
      </Text>
      <IconSvg spacing="mx-2" width="20px" icon="arrow-right" />
      <Text bold color="#E75B5B">
        USD $25.00 + per transaction
      </Text>
    </Box>
    <Box flex>
      <Text width="190px" bold>
        Polygon Network ($MATIC)
      </Text>
      <IconSvg spacing="mx-2" width="20px" icon="arrow-right" />
      <Text bold color="#23AF8E">
        Free
      </Text>
    </Box>
  </Panel>
);

export default FeesPage;
