import { Box, Button, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import SelectionBox from "./SelectionBox";

const SelectFundingWalletModal = ({ hideModal, onSubmit, selectedWallet, onClose }) => {
  const [selected, setSelected] = useState(selectedWallet || null);
  const { wallets: userWallets } = useStoreState((state) => state.user.user);
  const { getBalance } = useStoreActions((actions) => actions.wallets);

  const onWalletSelect = (event) => {
    const id = parseInt(event.currentTarget.dataset.id);
    setSelected(userWallets.filter((obj) => obj.id === id)[0]);
  };

  const onNext = () => {
    onSubmit(selected.chain_type);
  };

  const elements = userWallets.map((wallet) => (
    <SelectionBox key={wallet.id} selected={selected?.id === wallet.id} {...wallet} onClick={onWalletSelect} />
  ));

  useEffect(() => {
    userWallets.map(getBalance);
  }, [userWallets.length]);

  return (
    <Modal open leaf onClose={onClose || hideModal}>
      <Wrapper>
        <Text bold type="header" size="24px" lineHeight="30px" textAlign="center" spacing="mb-4">
          Select funding wallet
        </Text>
        <Text textAlign="center">
          The selected wallet will be used to fund rewards given out for completing the Challenge you create
        </Text>
        <Box flex direction="column" gap="1rem" spacing="mt-5 mb-6">
          {elements}
        </Box>
        <Box flex gap="1rem">
          <StyledButton
            size="lg"
            kind="outline"
            bold
            height="53px"
            borderRadius="40px"
            borderColor="#BDBDBD"
            bgColor="white"
            color="secondary400"
            onClick={hideModal}
            modal={true}
          >
            Cancel
          </StyledButton>
          <StyledButton
            disabled={!selected}
            size="lg"
            bold
            bgColor="primary700"
            height="53px"
            borderRadius="40px"
            onClick={onNext}
            modal={true}
          >
            {selectedWallet ? "Update" : "Begin Creating Challenge"}
          </StyledButton>
        </Box>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 1rem 40px 1.5rem;
  color: ${({ theme }) => theme.colors["02Primary700"]};
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

export default SelectFundingWalletModal;
