import { Box, breakpoints, Text, TokenReward } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import styled from "styled-components";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { numberWithCommas } from "../../utils/formatting";
import AccordionButton from "../Dashboard/DashboardHeader/AccordionButton";

const Stats = ({ extraElement, type, stats, rewards_token, hide_amounts }) => {
  const { isTablet, isMobile } = useStoreState((state) => state.media);
  const isSeason = type === "season";
  const rewardsKey = isSeason ? "rewards_earned" : "rewards_distributed";
  const showRewards = !hide_amounts && stats[rewardsKey];
  const showRaffleEntries = isSeason && (rewards_token === "THRIVE" || !rewards_token);
  const showSecondLine = (showRewards && !isTablet) || !showRewards;
  const showThirdLine = !showSecondLine || !isTablet;
  const [expanded, setExpanded] = useState(false);
  const toggleAccordion = () => setExpanded(!expanded);

  return (
    <StatsBox>
      {showRewards && (
        <>
          <Box sm-spacing="mb-6">
            {isMobile && (
              <Box flex justifyContent="space-between">
                <StyledText typography="body/small" bold spacing="mb-3">
                  Total Rewards Earned in This {capitalizeFirstLetter(type)}
                </StyledText>
                {isMobile && <AccordionButton expanded={expanded} toggle={toggleAccordion} />}
              </Box>
            )}
            <TokenReward
              iconSize="40px"
              token={rewards_token}
              amount={stats[rewardsKey] || 0}
              size="30px"
              lHeight="42px"
              currencySize="16px"
              currencyLHeight="24px"
              color="01Primary700"
              hideText={true}
            />
            {!isMobile && (
              <StyledText typography="body/small" bold spacing="mt-3">
                Total Rewards Earned in This {capitalizeFirstLetter(type)}
              </StyledText>
            )}
          </Box>
          <StatsSeperator />
        </>
      )}

      {(expanded || !isMobile) && (
        <>
          <DirectionalBox>
            <StyledText typography="body/small" bold>
              Total Contributions in This {capitalizeFirstLetter(type)}
            </StyledText>
            <Text typography="heading/x-large" bold color="hover" spacing="mb-3" sm-spacing="mb-6 mt-3">
              {numberWithCommas(stats.contributions_made)}
            </Text>
          </DirectionalBox>
          {showSecondLine && <StatsSeperator />}
          <DirectionalBox>
            <StyledText typography="body/small" bold>
              Total Contributors in This {capitalizeFirstLetter(type)}
            </StyledText>
            <Text typography="heading/x-large" bold color="hover" spacing="mb-3" sm-spacing="mb-0 mt-3">
              {numberWithCommas(stats.contributors_count)}
            </Text>
          </DirectionalBox>
          {showRaffleEntries && showThirdLine && <StatsSeperator />}
          {showRaffleEntries && (
            <DirectionalBox sm-spacing="mt-6">
              <StyledText typography="body/small" bold>
                Total Raffle Entries This {capitalizeFirstLetter(type)}
              </StyledText>
              <Text typography="heading/x-large" bold color="hover" spacing="mb-3" sm-spacing="mb-0 mt-3">
                {numberWithCommas(stats.raffle_entries)}
              </Text>
            </DirectionalBox>
          )}
          {extraElement}
        </>
      )}
    </StatsBox>
  );
};

const DirectionalBox = styled(Box)`
  display: flex;
  flex-direction: column-reverse;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 1rem;
  }
`;

const StatsBox = styled(Box)`
  display: flex;
  background: ${({ theme }) => theme.colors["gradientCustom"]};
  padding: 2rem;
  border-radius: 8px;
  @media ${breakpoints.tablet} {
    display: grid;
    row-gap: 2rem;
    grid-template-columns: auto auto auto;
    padding: 24px;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: unset;
    margin: 34px 0 1.5rem;
    padding: 1rem;
  }
`;

const StyledText = styled(Text)`
  white-space: pre-wrap;
`;

const StatsSeperator = styled(Box)`
  margin: 0 50px;
  background: ${({ theme }) => theme.colors["01Primary200"]};
  width: 1px;

  @media ${breakpoints.tablet} {
    margin: 0 36px 0 21px;
  }

  @media ${breakpoints.mobile} {
    display: none;
    height: 1px;
    width: 21px;
    margin: 1rem 0;
  }
`;

export default Stats;
