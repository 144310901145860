import DefaultFeatured from "@assets/dashboard/SeasonFeaturedBlockchain.svg";
import { Box, breakpoints, Button, Loader, OptionsPanel, SeasonCard, Text } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { MainContainer } from "../common/MainContainer";
import { Statuses } from "./ManageCommunityCard/common";

const FilterType = [
  { value: "active", activeColor: "03Primary500" },
  { value: "draft", activeColor: "01Secondary500" },
  { value: "completed", activeColor: "03Primary500" },
  { value: "upcoming", activeColor: "02Primary200" },
  { value: "open_to_claim", activeColor: "03Primary500" },
  { value: "upcoming_raffle", activeColor: "03Primary500" },
  { value: "claim_opens_soon", activeColor: "02Primary200" },
];

const SeasonsTab = () => {
  const { id } = useParams();
  const { filteredSeasons } = useStoreState((state) => state.season);
  const { getFilteredSeasons } = useStoreActions((actions) => actions.season);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(FilterType[0].value);

  const addSeason = () => showModal({ modalName: "SeasonEditorModal" });
  const onEditSeason = (id) => showModal({ modalName: "SeasonEditorModal", seasonID: id, edit: true });

  const handleChange = (event) => setStatus(event.target.dataset.value);

  const fetchData = () => getFilteredSeasons({ id, status: status.toLowerCase() }).finally(() => setLoading(false));

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [id, status]);

  return (
    <MainContainer>
      <Box flex alignItems="center">
        <StatusesBox overflow="auto">
          <Statuses filters={FilterType} status={status} onStatusClick={handleChange} />
        </StatusesBox>
        <Button borderRadius="40px" height="44px" spacing="ml-a" rightIcon="arrow-up-right" onClick={addSeason}>
          Add New Season
        </Button>
      </Box>
      <Box>
        {loading && (
          <Box width="100%" spacing="m-a p-9+6" flex alignItems="center" justifyContent="center">
            <Loader $size="50px" />
          </Box>
        )}
        {!loading &&
          filteredSeasons.map((item) => {
            return (
              <SeasonBox key={item.id}>
                <SeasonCard
                  season_number={item.order_number}
                  {...item}
                  manageSeason
                  onStart={() => onEditSeason(item.slug || item.id, "seasons")}
                  end_date={moment(item.end_date).format("MMM Do, YYYY")}
                  CloseSeasonPanel={CloseSeasonPanel(fetchData)}
                  refresh={fetchData}
                  ButtonComponent={item.status === "draft" ? DraftButtons : undefined}
                  featured_image_url={item.featured_image_url || DefaultFeatured}
                />
              </SeasonBox>
            );
          })}
      </Box>
    </MainContainer>
  );
};

const DraftButtons = ({ id, refresh }) => {
  const { isMobile } = useStoreState((state) => state.media);
  const { showModal } = useStoreActions((actions) => actions.modals);
  const doPublish = () => showModal({ modalName: "PublishSeasonModal", id, callBack: refresh });
  const onEditSeason = () => showModal({ modalName: "SeasonEditorModal", seasonID: id, edit: true });

  const direction = isMobile ? "column" : "row";

  return (
    <DraftButtonsContainer flex spacing="mt-5" gap="1.5rem" direction={direction}>
      <Button data-id={id} onClick={doPublish}>
        Publish Season
      </Button>

      <Button onClick={onEditSeason} data-id={id} kind="outline" borderColor="02Secondary500">
        Keep Editing
      </Button>
    </DraftButtonsContainer>
  );
};

const DraftButtonsContainer = styled(Box)`
  > * {
    flex-grow: 1;
    width: unset;
  }

  a {
    display: grid;
  }
`;

const CloseSeasonPanel =
  (fetchData) =>
  ({ id }) => {
    const { showModal } = useStoreActions((actions) => actions.modals);
    const onClick = () => showModal({ modalName: "CompleteSeasonModal", id, callback: fetchData });

    return (
      <OptionsPanel right="0" hasBackground>
        <Text onClick={onClick} pointer typography="body/medium" color="error2">
          Complete Season
        </Text>
      </OptionsPanel>
    );
  };

const SeasonBox = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const Header = styled(Box)`
  margin-bottom: 40px;
  @media ${breakpoints.tablet} {
    margin-bottom: 32px;
    flex-direction: column;
    align-items: baseline;
    gap: 24px;
  }
  @media ${breakpoints.mobile} {
    margin-bottom: 24px;
    flex-direction: column-reverse;
    align-items: baseline;
    gap: 24px;
  }
`;

const StatusesBox = styled(Box)`
  overflow-x: auto;
  margin-right: 16px;
  flex: 1;
`;

export default SeasonsTab;
