import { Box, Button, IconSvg, Input, Modal, Text } from "@thrivecoin/ui-components";
import { useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { DEFAULT_SORT_OPTIONS } from "../../SortOptions";
import SelectView from "./SelectView";

const useDelayUnmount = (isMounted, delayTime) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
};

const HeaderContent = ({ text, onClick }) => (
  <StyledHeader
    bgColor="02Surface"
    flex
    direction="row"
    alignItems="center"
    pointer
    onClick={onClick}
    color="01Primary700"
  >
    <IconSvg icon="down-long" width="14px" height="14px" color="01Primary700" spacing="mr-2 mt-0"></IconSvg>
    <Text typography="body/small" bold>
      {text}
    </Text>
  </StyledHeader>
);

const FilterModal = ({
  hideModal,
  searchValue,
  onSubmit,
  sortOptions,
  filterLabel,
  backLabel,
  hideSort,
  getTotal,
  multipleSort = [],
  setSortingType,
  sortingType,
}) => {
  const _sortOptions = multipleSort.length ? multipleSort : [{ options: sortOptions, label: sortOptions[0]?.label }];
  const filterText = getTotal && `${getTotal()} ${filterLabel}`;
  const { isMobile } = useStoreState((state) => state.media);
  const [sort, setSort] = useState(_sortOptions[0]);
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const shouldRender = useDelayUnmount(showOptions, 500);
  const [search, setSearch] = useState(searchValue);
  const [isMultiple, setIsMultiple] = useState(false);
  const _sortType = Object.keys(sortingType).length > 0 ? sortingType : sort;
  const [selected, setSelected] = useState(_sortType);

  // TODO: Refactor this
  // We should be able to set state THEN search based on the updated state

  const openSelector = (item) => {
    setShowOptions(true);
    setOptions(item.options);
    setIsMultiple(!!item.isMultiple);
  };

  const hideSelector = () => {
    setSortingType(_sortType);
    setSelected(_sortType);
    setShowOptions(false);
  };

  const onApply = (val) => {
    setSort(val);
    setShowOptions(false);
    setSortingType(selected);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    onSubmit(e.target.value, sort);
  };

  const applyFilters = () => {
    onSubmit(search, sort);
    hideModal();
  };

  const clearFilters = () => {
    setSearch("");
    setSortingType(_sortOptions[0]);
    setSelected(_sortOptions[0]);
    onSubmit("", sort);
  };

  return (
    <Modal
      open
      fullScreen
      onClose={hideModal}
      leaf={false}
      sm-spacing="px-0 pb-5 pt-0"
      borderRadius={isMobile && "0"}
      hideX
      contentHeight="100%"
      bgColor="background"
    >
      <HeaderContent text={backLabel} onClick={hideModal} />
      <Box grow={1} flex direction="column" sm-spacing="pt-6 px-4">
        <form action="/s" onSubmit={applyFilters}>
          <StyledInput
            value={search}
            placeholder="Search contributions"
            icon="search"
            minWidth="270px"
            onChange={handleChange}
            type="search"
          />
        </form>
        {!!search && (
          <Text typography="body/small" color="hover" spacing="mx-a mt-3">
            {filterText}
          </Text>
        )}
        {!hideSort && (
          <>
            {_sortOptions.map((item, index) => {
              return (
                <Select
                  key={index}
                  flex
                  pointer
                  color="01Primary700"
                  alignItems="center"
                  bgColor="01Primary0"
                  onClick={() => openSelector(item)}
                >
                  <Text bold>Sort by: {selected.label}</Text>
                  <IconSvg icon="chevron-down" width="20px" />
                </Select>
              );
            })}
          </>
        )}

        <Box flex alignItems="center" gap="8px" spacing="mt-a mb-8">
          <Box flex pointer onClick={clearFilters} grow={1} alignItems="center" justifyContent="center">
            <Text bold color="link" underline>
              Clear all
            </Text>
          </Box>
          <Button
            height="56px"
            bold
            size="lg"
            variant="button"
            color="01Primary0"
            borderRadius="40px"
            onClick={applyFilters}
            modal={true}
          >
            Show {filterText}
          </Button>
        </Box>
      </Box>
      {!!shouldRender && (
        <SelectView
          setSelected={setSelected}
          selected={selected}
          onApply={onApply}
          options={options}
          isMultiple={isMultiple}
          hideOptions={hideSelector}
          closed={!showOptions}
        />
      )}
    </Modal>
  );
};

FilterModal.defaultProps = {
  sortOptions: [{ options: DEFAULT_SORT_OPTIONS, label: DEFAULT_SORT_OPTIONS[0].label }],
  backLabelValue: "Back to Season page",
  filterLabelValue: "Show Contributions",
};

const Select = styled(Box)`
  height: 56px;
  padding: 0 1rem;
  margin-top: 20px;
  border-radius: 12px;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors["01Primary300"]};
`;

const StyledInput = styled(Input)`
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  & svg {
    left: 1rem;
    stroke: ${({ theme }) => theme.colors["01Primary700"]};
  }

  & input {
    font-weight: 600;
    background: ${({ theme }) => theme.colors["01Primary0"]};

    &::placeholder {
      color: ${({ theme }) => theme.colors["01Primary400"]};
    }
  }
`;

const StyledHeader = styled(Box)`
  padding: 17px 16px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors["01Primary200"]};

  svg {
    transform: rotate(90deg);
    margin-top: 10px;
  }
`;

export default FilterModal;
