import { getConnectorName } from "@thrivecoin/thc-auth-js";
import { useStoreActions } from "easy-peasy";
import { shouldDeepLink } from "../../utils/shouldDeepLink";
import { CHAIN_PARAMS, getInstance } from "../../utils/web3wrapper";

const ERROR_MESSAGES = {
  4001: "Accept the connection to see earned tokens in your wallet provider.",
  internal_error: "Something went wrong while communicating with your wallet provider! Please notify us!",
};

export const walletOwnershipMessage = (nonce, address) =>
  ["Verify that it is you ", address.toLowerCase(), ", ", nonce, "!"].join("");

export const isOnPolygon = (chainID = window.ethereum?.networkVersion) => chainID == parseInt(CHAIN_PARAMS.chainId, 16);

const useWeb3 = () => {
  const { dangerToast } = useStoreActions((actions) => actions.toasts);
  const instance = getInstance();
  const providerName = getConnectorName() || "metamask";
  const hasWeb3Provider = Boolean(window.ethereum);
  const _isOnPolygon = isOnPolygon();

  const {
    addNetwork: _addNetwork,
    switchNetwork,
    connectToAccounts,
    addThriveToken,
    getChainType,
    switchToPolygonNetwork,
    switchToEtheterumNetwork,
  } = instance;

  const addToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => addThriveToken().then(resolve).catch(reject), 600);
    });
  };

  const errorHandler = ({ code, message }) => {
    const content = ERROR_MESSAGES[code] || ERROR_MESSAGES["internal_error"];
    dangerToast({ content, timeout: 5000 });

    return Promise.reject(message || content);
  };

  const connect = () => {
    return connectToAccounts()
      .then((accounts) => {
        return accounts;
      })
      .catch(errorHandler);
  };

  const addNetwork = (handler) => {
    return _addNetwork()
      .then((data) => {
        return data;
      })
      .catch(errorHandler);
  };

  const addPolygonToken = () => switchToPolygonNetwork().then(addToken).catch(errorHandler);
  const addEthereumToken = () => switchToEtheterumNetwork().then(addToken).catch(errorHandler);

  const installMetamask = () => {
    let url = "https://metamask.io/";

    if (shouldDeepLink()) {
      const path = location.host + location.pathname;
      url = `https://metamask.app.link/dapp/${path}`;
    }

    window.open(url, "_blank", "noopener,noreferrer,resizable");
  };

  return {
    switchNetwork,
    addNetwork,
    switchToPolygonNetwork,
    switchToEtheterumNetwork,
    connect,
    addPolygonToken,
    addEthereumToken,
    addThriveToken,
    getChainType,
    addToken,
    installMetamask,
    providerName,
    hasWeb3Provider,
    _isOnPolygon,
  };
};

export default useWeb3;
