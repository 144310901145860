import { TabsContainer } from "@thrivecoin/ui-components";
import { useStoreActions } from "easy-peasy";
import { useParams } from "react-router";
import RaffleFAQ from "../../Raffle/Body/RaffleFAQ/RaffleFAQ";
import RafflePrizes from "../../Raffle/Body/RafflePrizes";
import useColors from "../useColors";
import AboutSeason from "./AboutSeason";
import Contributions from "./Contributions";
import Contributors from "../../Contributors/index";
import WaysToContribute from "./WaysToContribute/index";

const selectedTabIndex = (tab, headers) => {
  if (tab === "WaysToContribute") {
    if (headers.includes("WaysToContribute")) {
      return 1;
    }
    return 0;
  }
  return tab;
};

const COMPLETED_TOKEN_SEASON = {
  tabClasses: [AboutSeason, Contributors, Contributions],
  headers: ["About Season", "Contributors ", "Contributions"],
};

const ACTIVE_TOKEN_SEASON = {
  tabClasses: [WaysToContribute, AboutSeason, Contributors, Contributions],
  headers: ["Ways to contribute", "About Season", "Contributors ", "Contributions"],
};

const COMPLETED_RAFFLE_SEASON = {
  tabClasses: [RafflePrizes, RaffleFAQ, Contributors],
  headers: ["Raffle Prizes", "Rules & FAQ", "Contributors"],
};

const ACTIVE_RAFFLE_SEASON = {
  tabClasses: [WaysToContribute, Contributors, Contributions, RafflePrizes, RaffleFAQ],
  headers: ["Ways to contribute", "Contributors", "Contributions", "Raffle Prizes", "Rules & FAQ"],
};

const getConfig = (isRaffle, isActive) => {
  if (isRaffle) {
    return isActive ? ACTIVE_RAFFLE_SEASON : COMPLETED_RAFFLE_SEASON;
  }

  return isActive ? ACTIVE_TOKEN_SEASON : COMPLETED_TOKEN_SEASON;
};

const BodySeason = ({ currentTabIndex, setCurrentTabIndex, season, onRaffleDetails }) => {
  const { id } = useParams();
  const { getActiveSeasonListingsRequest } = useStoreActions((actions) => actions.season);
  const raffleSystem = season.reward_system !== "custom_token";
  const isActive = season?.status === "active";
  const { tabClasses, headers } = getConfig(raffleSystem, isActive);
  const { theme } = useColors(season);

  const onChangeTab = (index) => setCurrentTabIndex(index);
  const tabIndex = selectedTabIndex(currentTabIndex, headers);

  const tabProps = {
    id,
    onRaffleDetails,
    group: "season",
    getListings: getActiveSeasonListingsRequest,
    raffle_id: season?.raffle_id,
    seasonPage: true,
    raffleCompleted: season?.status === "raffle_completed",
    seasonId: season.id,
  };

  return (
    <TabsContainer
      tabProps={tabProps}
      selectedIndex={tabIndex}
      tabClasses={tabClasses}
      headers={headers}
      onChangeIndex={onChangeTab}
      className="tabsContainer"
      selectedTabBgColor={theme.tabBgColor}
      selectedTabColor={theme.tabColor}
    />
  );
};

export default BodySeason;
