import { Modal } from "@thrivecoin/ui-components";
import ListingEditor from "../ListingEditor";

const ListingEditorModal = ({ hideModal, listingID, communityID }) => {
  return (
    <Modal
      open
      hideX
      width="100%"
      paddingBody="0"
      transform="unset"
      onClose={hideModal}
      maxWidth="calc(100vw - 64px)"
      contentHeight="calc(100vh - 64px)"
      borderRadius="12px"
    >
      <ListingEditor communityID={communityID} listingID={listingID} />
    </Modal>
  );
};

export default ListingEditorModal;
