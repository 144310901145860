import { abis, web3Utils } from "@thrivecoin/web3-utils";
import { useStoreActions } from "easy-peasy";
import { useState } from "react";
import { getInstance, nameToChainID } from "../../utils/web3wrapper";

const FAILURE_MESSAGE =
  "Transaction failed! Please join the ThriveCoin Discord server (https://discord.gg/sBYUJqu95G), check out the #known-issues channel, and if your question isn’t answered then submit a ticket in the server.";

const sleepFN = (timeout) => new Promise((resolve) => setTimeout(() => resolve(true), timeout));
const seasonKey = (season) => `chain-claim-status-${season.id}-${season.number}`;

export const savePendingTransaction = (season, hash) => localStorage.setItem(seasonKey(season), hash);
export const removePendingTransaction = (season) => localStorage.removeItem(seasonKey(season));
export const readPendingTransaction = (season) => localStorage.getItem(seasonKey(season));

const useRewardsContract = (season, claimCallback) => {
  const configs = season.reward_claiming_config;
  const { dangerToast, infoToast } = useStoreActions((actions) => actions.toasts);
  const [processing, setProcessing] = useState(false);
  const isPending = season.earnings_status == "pending";

  const claimReward = async () => {
    try {
      setProcessing(true);
      const instance = getInstance();
      const { web3 } = instance;
      const networkName = configs.chain_id;

      if (networkName) {
        const chainID = parseInt(nameToChainID(networkName));
        if (chainID !== parseInt(instance.chainID())) {
          await instance.addAndSwitchNetwork(networkName);
          await sleepFN(1500);
        }

        if (chainID !== parseInt(instance.chainID())) {
          infoToast({ content: "Please make sure to switch to the correct network" });
          return;
        }

        const accounts = await instance.getAccounts();

        const contract = web3Utils.getContract(
          web3,
          abis.ThriveCoinRewardSeasonIERC20GasRefundable,
          configs.contract_address
        );

        const promise = contract.methods.claimReward(accounts[0]).send({
          from: accounts[0],
        });

        promise.once("receipt", (data) => {
          //Pending transaction
          const transactionHash = data.transactionHash;
          savePendingTransaction(season, transactionHash);
        });

        const res = await promise;

        return claimCallback(res.transactionHash);
      }
    } catch (error) {
      dangerToast({ content: FAILURE_MESSAGE, timeout: 3000 });
    } finally {
      setProcessing(false);
    }
  };

  return {
    isPending,
    claimReward,
    processing,
  };
};

export default useRewardsContract;
