import FutureTeam from "@assets/about-us/future-team.svg";
import Person from "@assets/about-us/investor-tokens.svg";
import TreasuryToken from "@assets/about-us/treasury-tokens.svg";
import { Box, breakpoints, Text } from "@thrivecoin/ui-components";
import styled from "styled-components";

const AboutTreasury = ({ abouttokenRef }) => {
  return (
    <AboutTreasuryWrapper ref={abouttokenRef} id="abouttoken">
      <BoxStyledColTreasury>
        <LeftPart>
          <HeaderTitle>About Treasury tokens</HeaderTitle>
          <WrapperParagraph>
            Our Treasury funds reward people and communities for creating value that creates exponential and enduring
            utility for the network. Soon, you will be able to vote on proposals from other community members for how to
            disperse treasury funds.
            <br /> <br />
            Currently, 400,000,000 (5%) $THRIVE is available in the Treasury to be shared with people and communities
            creating value for ThriveCoin. Through our first year, an additional 100,000,000 (1.25%) $THRIVE will be
            unlocked quarterly.
          </WrapperParagraph>
        </LeftPart>
        <StyledImageTreasury src={TreasuryToken} />
      </BoxStyledColTreasury>

      <BoxStyledColPerson>
        <StyledImagePerson src={Person} />
        <RightPart>
          <HeaderTitle>About early team and investor tokens</HeaderTitle>

          <WrapperParagraph>
            Early team members and investors represent 28% of the total pool. Their funds are locked for a minimum of
            one year, and then they are subject to vesting or unlocking schedules that range from 18 to 36 additional
            months.
          </WrapperParagraph>
        </RightPart>
      </BoxStyledColPerson>

      <BoxStyledColFuture>
        <LeftPart>
          <HeaderTitle>
            <br />
            About tokens reserved for future core team members, partners, and investors
          </HeaderTitle>
          <WrapperParagraph>
            Unlike other protocols, ThriveCoin will likely remain in TestNet for 9+ months to work with our communities
            to evolve and improve our protocol and infrastructure. Throughout, we will likely add additional core team
            members, partners, and investors.
          </WrapperParagraph>
        </LeftPart>
        <FutureImg src={FutureTeam} />
      </BoxStyledColFuture>
    </AboutTreasuryWrapper>
  );
};

const BoxStyledColTreasury = styled(Box)`
  display: flex;
  gap: 209px;
  justify-content: center;
  margin-bottom: 6rem;
  @media ${breakpoints.tablet} {
    gap: 108px;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 108px;
  }
`;

const BoxStyledColPerson = styled(Box)`
  display: flex;
  gap: 168px;
  justify-content: center;
  margin-bottom: 6rem;
  @media ${breakpoints.tablet} {
    gap: 70px;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column-reverse;
    align-items: center;
    gap: 66px;
    margin-bottom: 30px;
  }
`;

const BoxStyledColFuture = styled(Box)`
  display: flex;
  gap: 111px;
  justify-content: center;
  margin-bottom: 2rem;
  @media ${breakpoints.tablet} {
    gap: 70px;
  }
  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;
const FutureImg = styled.img`
  @media ${breakpoints.tablet} {
    max-width: 391px;
  }
  @media ${breakpoints.mobile} {
    max-width: 311px;
  }
`;

const StyledImageTreasury = styled.img`
  @media ${breakpoints.tablet} {
    max-width: 327px;
  }
  @media ${breakpoints.mobile} {
    max-width: 255px;
  }
`;
const StyledImagePerson = styled.img`
  @media ${breakpoints.tablet} {
    max-width: 369px;
  }
  @media ${breakpoints.mobile} {
    max-width: 286px;
  }
`;
const AboutTreasuryWrapper = styled.div`
  background: #faf9f8;
  border-radius: 25px;
  padding: 146px 100px 68px 100px;
  margin-top: 33px;
  @media ${breakpoints.tablet} {
    padding: 116px 79px 72px 65px;
  }
  @media ${breakpoints.mobile} {
    padding: 47px 20px 68px 20px;
  }
`;

const HeaderTitle = styled(Text)`
  font-family: "Outfit";
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 34px;
  line-height: 42px;
  margin: 0 0 32px 0px;
  max-width: 512px;
  color: #d8aa2b;
  @media ${breakpoints.mobile} {
    font-size: 28px;
    line-height: 36px;
    margin: 0 0 28px 0px;
  }
`;

const LeftPart = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  text-align: left;
`;

const RightPart = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  text-align: left;
  margin-top: 90px;
  @media ${breakpoints.mobile} {
    margin-top: 0;
  }
`;

const WrapperParagraph = styled.div`
  max-width: 549px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors["02Primary700"]};
  @media ${breakpoints.mobile} {
    font-size: 16px;
  }
`;

export default AboutTreasury;
