import { Modal, Text } from "@thrivecoin/ui-components";
import { Header, PaddedButton } from "./common";

const CommitToChallengeModal = ({ hideModal }) => {
  return (
    <Modal marginTop={"77px"} open onClose={hideModal} leaf={true} spacing="px-7+6 pt-7+6" maxWidth="684px">
      <Header>Are you sure you want to commit to this Challenge?</Header>
      <Text>You will have 10 days to complete this Challenge, and can only complete one Challenge at a time.</Text>
      <PaddedButton type="submit" spacing="mb-4">
        Start Challenge
      </PaddedButton>
    </Modal>
  );
};

export default CommitToChallengeModal;
