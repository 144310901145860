import { Box, breakpoints, Modal } from "@thrivecoin/ui-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AccountCreatedStep from "./AccountCreatedStep";
import CreateAccountStep from "./CreateAccountStep";
import ForgotPasswordStep from "./ForgotPasswordStep";
import MobileStartStep from "./MobileStartStep";
import ResetPasswordStep from "./ResetPasswordStep";
import SignInStep from "./SignInStep";
import VerificationStep from "./VerificationStep";

export const STEP_INDEXES = {
  CreateAccountStep: 0,
  SignInStep: 1,
  VerificationStep: 2,
  AccountCreatedStep: 3,
  ForgotPasswordStep: 4,
  ResetPasswordStep: 5,
  MobileStartStep: 6,
};

const STEP_KLASSES = [
  CreateAccountStep,
  SignInStep,
  VerificationStep,
  AccountCreatedStep,
  ForgotPasswordStep,
  ResetPasswordStep,
  MobileStartStep,
];

const makeRegex = (prefix) => new RegExp(prefix + "/[a-zA-Z0-9\\D]+$");
export const resetPasswordRegex = makeRegex("reset_password");
export const createAccountRegex = makeRegex("create");

export const useModalRoutes = () => {
  const path = location.pathname;
  const resetPassword = resetPasswordRegex.test(path);
  const createAccount = createAccountRegex.test(path);
  const { showModal } = useStoreActions((actions) => actions.modals);

  useEffect(() => {
    if (resetPassword) {
      showModal({ modalName: "SignInFlowModal", initialStep: STEP_INDEXES.ResetPasswordStep });
    }

    if (createAccount) {
      showModal({ modalName: "SignInFlowModal", initialStep: STEP_INDEXES.CreateAccountStep });
    }
  }, [resetPassword, createAccount]);
};

const SignInFlowModal = ({ hideModal, initialStep, afterClose }) => {
  const [step, goToStep] = useState(initialStep);
  const StepKlass = STEP_KLASSES[step];
  const footerContent = StepKlass.Footer ? <StepKlass.Footer goToStep={goToStep} /> : null;
  const { isDesktop, isTablet, isMobile } = useStoreState((state) => state.media);

  const fullScreen = isTablet || (isMobile && step !== 3);
  const showBackToCommunity = fullScreen && step !== 4 && step !== 5;

  const closeModal = () => {
    hideModal();
    afterClose && afterClose();
  };

  return (
    <StyledModal
      open
      bgColor={StepKlass.bgColor}
      onClose={hideModal}
      leaf={isDesktop}
      spacing={isDesktop ? "px-7+6 pt-7+6 pb-6+2" : isTablet ? "px-0 pt-6" : step === 3 ? "px-0 pt-7" : "px-0 pt-4"}
      maxWidth={"684px"}
      footerContent={footerContent}
      fullScreen={fullScreen}
      borderRadius={fullScreen ? "0 !important" : isMobile && step === 3 && "1.5rem"}
      contentHeight={fullScreen && "100vh"}
      hideX={fullScreen}
      backText={showBackToCommunity && "Back to community"}
      paddingBody={isMobile && "0"}
    >
      <StyledBox flex={!isDesktop} direction="column" textAlign="center">
        <StepKlass goToStep={goToStep} closeModal={closeModal} />
      </StyledBox>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  margin-top: 0;
`;

const StyledBox = styled(Box)`
  @media $${breakpoints.tablet} {
    height: 100%;
  }
  @media ${breakpoints.mobile} {
    height: 100%;
  }
`;

SignInFlowModal.defaultProps = {
  initialStep: 0,
};

export default SignInFlowModal;
